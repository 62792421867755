export const availableTimes: string[] = [
  '09:00-10:00',
  '09:30-10:30',
  '10:00-11:00',
  '10:30-11:30',
  '11:00-12:00',
  '11:30-12:30',
  '12:00-13:00',
  '12:30-13:30',
  '13:00-14:00',
  '13:30-14:30',
  '14:00-15:00',
  '14:30-15:30',
  '15:00-16:00',
  '15:30-16:30',
  '16:00-17:00',
  '16:30-17:30',
  '17:00-18:00',
  '17:30-18:30',
  '18:00-19:00',
  '18:30-19:30',
  '19:00-20:00',
  '19:30-20:30',
  '20:00-21:00',
  '20:30-21:30',
  '21:00-22:00',
];

export const weekDays = [
  { ja: '日', en: 'Sun' },
  { ja: '月', en: 'Mon' },
  { ja: '火', en: 'Tue' },
  { ja: '水', en: 'Wed' },
  { ja: '木', en: 'Thu' },
  { ja: '金', en: 'Fri' },
  { ja: '土', en: 'Sat' },
];
