import React, { useEffect } from 'react';
import InputCommon from '../../common/input/InputCommon';
import { translations } from '../../../locales/translations';
import { useTranslation } from 'react-i18next';
import CommonButton from '../../common/CommonButton';
import { Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getInfoCom, updateInfoCom } from '../../../store/com/comSlice';
import { TClientManagement } from '../../../types/clientManagement/clientManagement';
import { TComManagement } from '../../../types/comManagement/comManagement';
import { updateInfoManager } from '../../../store/manager/managerSlice';
import { requiredEmailIfNotEmpty } from 'helpers/funcs';
import { getUserFromCookie, saveUserCookie } from '../../../helpers/cookies';
import { updateEmployeeManagement } from '../../../store/employee/employeeSlice';
import { logOut } from '../../../store/auth/authSlice';

type Props = {
  handleClose: () => void;
  infoUser: TClientManagement | TComManagement;
};

export const AccountSettingModal: React.FC<Props> = (props: Props) => {
  const { handleClose, infoUser } = props;
  const { t } = useTranslation();
  const currentUser = getUserFromCookie();
  const dispatch = useDispatch();

  const onFinish = (values) => {
    const data = {
      ...infoUser,
      ...values,
    };

    if (currentUser.com) {
      dispatch(
        updateInfoCom({
          id: infoUser._id ?? '',
          data: data,
          handleClose,
          callback: () => {
            saveUserCookie({
              ...currentUser,
              com: { ...currentUser.com, ...values },
            });
          },
        }),
      );
    } else if (currentUser.manager) {
      dispatch(
        updateInfoManager({
          id: infoUser._id ?? '',
          data: data,
          callback: () => {
            saveUserCookie({
              ...currentUser,
              com: { ...currentUser.com, ...values },
            });
          },
        }),
      );
      saveUserCookie({
        ...currentUser,
        manager: { ...currentUser.manager, ...values },
      });
    } else if (currentUser.employee) {
      dispatch(
        updateEmployeeManagement({
          id: infoUser._id ?? '',
          data: data,
          handleClose,
          callback: (data) => {
            if (data.email !== currentUser.employee.email) {
              dispatch(logOut());
              return;
            }
            saveUserCookie({
              ...currentUser,
              employee: { ...currentUser.employee, ...values },
            });
          },
        }),
      );
    }
    handleClose();
  };

  const getInfoUser = () => {
    if (currentUser.com) {
      return {
        name: currentUser.com.name ?? '',
        nameFurinaga: currentUser.com.nameFurinaga ?? '',
        email: currentUser.com.email ?? '',
      };
    } else if (currentUser.manager) {
      return {
        name: currentUser.manager.name ?? '',
        nameFurinaga: currentUser.manager.nameFurinaga ?? '',
        email: currentUser.manager.email ?? '',
      };
    } else if (currentUser.employee) {
      return {
        name: currentUser.employee.name ?? '',
        nameFurinaga: currentUser.employee.nameFurinaga ?? '',
        email: currentUser.employee.email ?? '',
      };
    }
  };

  return (
    <>
      {Object.keys(infoUser).length > 0 && (
        <Form
          className="flex flex-col overflow-y-auto custom-scroll
         w-[474px] mt-6"
          initialValues={getInfoUser()}
          onFinish={onFinish}
        >
          <InputCommon
            requiredLabel={true}
            label={t(translations.ID_MASTER.MANAGER_ID.ACTION.NAME)}
            name="name"
            rules={{
              required: true,
              message: t(translations.ID_MASTER.MESSAGE_VALIDATE.REQUIRE),
            }}
          />
          <InputCommon
            requiredLabel={true}
            label={t(translations.ID_MASTER.MANAGER_ID.ACTION.NAME_FURINAGA)}
            name="nameFurinaga"
            rules={{
              required: true,
              message: t(translations.ID_MASTER.MESSAGE_VALIDATE.REQUIRE),
            }}
          />
          <InputCommon
            label={t(translations.ID_MASTER.MANAGER_ID.ACTION.EMAIL)}
            name="email"
            rulesValidatorRequiredIfNotEmpty={true}
            validatorRequiredIfNotEmpty={requiredEmailIfNotEmpty}
          />
          <Form.Item className="flex justify-end items-center gap-3 !mb-0">
            <div className="flex gap-2">
              <CommonButton
                textContent={t(translations.COMMON.CANCEL)}
                buttonType="secondary"
                rounded="md"
                onClick={handleClose}
              />
              <CommonButton
                type="submit"
                textContent={t(translations.COMMON.REGISTER)}
                buttonType="primary"
                rounded="md"
              />
            </div>
          </Form.Item>
        </Form>
      )}
    </>
  );
};
