import React, { ReactNode } from 'react';
import { Modal } from 'antd';

type Props = {
  open?: boolean;
  children?: ReactNode;
  title?: string | ReactNode;
  onClose?: () => void;
  disableClose?: boolean;
  blur?: boolean;
  className?: string;
};

const CommonModal: React.FC<Props> = ({
  open,
  children,
  title,
  onClose,
  disableClose = false,
  blur,
  className
}) => {
  if (!open) return null;
  return (
    <Modal
      open={open}
      footer={<></>}
      title={title}
      onCancel={onClose}
      centered={true}
      className={`!w-auto !h-auto ${className}`}
      closable={!disableClose}
    >
      <div>{children}</div>
    </Modal>
  );
};

export default CommonModal;
