import { axiosClient } from './axiosClient';

export const employeeWebApi = {
  updateInfoEmployee: (id: string, body) => {
    const url = `/employee/mobile/${id}`;
    return axiosClient.put(url, body);
  },

  employeeWebSaveAnswerApi: (id: string, eventId: string, answer) => {
    const url = `/omoteura/omoteura/result/${id}/${eventId}/${answer}`;
    return axiosClient.post(url);
  },

  employeeWebGetSuffApi: (id: string) => {
    const url = `/suffuciency?employeeId=${id}`;
    return axiosClient.get(url);
  },

  employeeWebSetSuffApi: (body) => {
    const url = `/suffuciency`;
    return axiosClient.post(url, body);
  },

  employeeWebChangePass: (body) => {
    const url = `/auth/user/change-password`;
    return axiosClient.patch(url, body);
  },

  employeeWebGetCureentEventApi: (id: string) => {
    const url = `/employee/mobile/getCurrentStep/${id}`;
    return axiosClient.get(url);
  },

  employeeWebSetCureentEventApi: (id: string, body, eventId: string) => {
    const url = `/employee/mobile/storeStep/${id}/${eventId}`;
    return axiosClient.put(url, body);
  },

  employeeWebGetUserInfoApi: (id: string) => {
    const url = `/employee/${id}/user-info`;
    return axiosClient.get(url);
  },
};
