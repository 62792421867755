import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { TFCMToken, TResponseLogin, UserDetail } from 'types/user/user';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { setShowToast } from '../toast/toastSlice';
import { ETypeToast } from '../../app/enum';
import { translations } from '../../locales/translations';
import { t } from 'i18next';
import authApi from '../../api/authApi';
import { getLabelError } from '../../helpers/funcs';
import {
  expires,
  getUserFromCookie,
  removeRefreshToken,
  removeToken,
  removeUser,
  saveClientUserCookie,
  saveConsultantCookie,
  saveEmployeeWebCookie,
  saveRefreshTokenCookie,
  saveTokenCookie,
  saveUserCookie,
} from '../../helpers/cookies';

export type AuthState = {
  loading: boolean;
  authenticated: boolean;
  currentUser: any;
  currentUserChat: any;
  emailErr: string;
};

const initialState: AuthState = {
  loading: false,
  authenticated: false,
  currentUser: {},
  currentUserChat: {},
  emailErr: '',
};

export const getCurrentUser = createAsyncThunk('auth/getCurrentUser', async () => {});

export const updateFcmTokenUser = createAsyncThunk(
  'auth/updateFcmTokenUser',
  async (data: TFCMToken) => {
    await authApi.updateFcmTokenUser(data);
  },
);

export const login = createAsyncThunk('login', async (response: any, thunkAPI) => {
  if (response.data.data.employee && !response.data.data.employee.isManager) {
    thunkAPI.dispatch(setShowToast({ label: 'Login fail', type: ETypeToast.Error }));
    return;
  }
  thunkAPI.dispatch(setCurrentUser(response.data.data));
  saveTokenCookie(response.data.data.accessToken, expires);
  saveRefreshTokenCookie(response.data.data.refreshToken, expires);
  saveUserCookie(response.data.data, expires);
  response.data.data.client && saveClientUserCookie(response.data.data.client, expires);
  response.data.data.consultant && saveConsultantCookie(response.data.data.consultant, expires);
  thunkAPI.dispatch(setLogIn(response));
  thunkAPI.dispatch(
    setShowToast({ label: t(translations.ID_MASTER.LOGIN_SUCCESS), type: ETypeToast.Success }),
  );
});

export const loginWithoutSaveEmployeeWeb = createAsyncThunk(
  'loginWithoutSaveEmployeeWeb',
  async (response: any, thunkAPI) => {
    saveTokenCookie(response.data.data.accessToken);
    saveRefreshTokenCookie(response.data.data.refreshToken);
    thunkAPI.dispatch(setCurrentUser(response.data.data));
    response.data.data.client && saveEmployeeWebCookie(response.data.data.employee);
    thunkAPI.dispatch(setLogIn(response));
    thunkAPI.dispatch(
      setShowToast({ label: t(translations.ID_MASTER.LOGIN_SUCCESS), type: ETypeToast.Success }),
    );
  },
);

export const loginWithoutSave = createAsyncThunk(
  'loginWithoutSave',
  async (response: any, thunkAPI) => {
    if (response.data.data.employee && !response.data.data.employee.isManager) {
      thunkAPI.dispatch(setShowToast({ label: 'Login fail', type: ETypeToast.Error }));
      return;
    }
    saveTokenCookie(response.data.data.accessToken);
    saveRefreshTokenCookie(response.data.data.refreshToken);
    thunkAPI.dispatch(setCurrentUser(response.data.data));
    response.data.data.client && saveClientUserCookie(response.data.data.client);
    response.data.data.consultant && saveConsultantCookie(response.data.data.consultant);
    thunkAPI.dispatch(setLogIn(response));
    thunkAPI.dispatch(
      setShowToast({ label: t(translations.ID_MASTER.LOGIN_SUCCESS), type: ETypeToast.Success }),
    );
  },
);

export const sendOneTimePassword = createAsyncThunk(
  'sendOneTimePassword',
  async (data: { email: string }, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    await authApi
      .sendOneTimePassword(data)
      .then(() => {
        thunkAPI.dispatch(
          setShowToast({
            label: t(translations.LOGIN.SEND_ONETIME_PASSWORD_SUCCESS),
            type: ETypeToast.Success,
          }),
        );
      })
      .catch((response) => {
        if (response.response.data.statusCode === 5200) {
          thunkAPI.dispatch(setEmailErr(t(translations.LOGIN.EMAIL_ADDRESS_DOES_NOT_EXIST)));
        } else {
          thunkAPI.dispatch(
            setShowToast({
              label: getLabelError(response.response.data.statusCode),
              type: ETypeToast.Error,
            }),
          );
        }
        thunkAPI.dispatch(setLoading(false));
      });
    thunkAPI.dispatch(setLoading(false));
  },
);

export const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLogIn(state, action: PayloadAction<TResponseLogin>) {
      state.authenticated = true;
      state.currentUser = action.payload.data.data;
    },
    logOut(state) {
      state.authenticated = false;
      state.currentUser = {};
      removeToken();
      removeRefreshToken();
      removeUser();
    },
    setAuth(state) {
      state.authenticated = true;
      state.currentUser = getUserFromCookie();
    },
    setCurrentUser(state, action: PayloadAction<UserDetail>) {
      state.currentUser = action.payload;
      saveUserCookie(action.payload);
    },
    setCurrentUserChat(state, action: PayloadAction<any>) {
      state.currentUserChat = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setEmailErr: (state, action: PayloadAction<string>) => {
      state.emailErr = action.payload;
    },
  },
});

export const { actions, reducer: authReducer } = slice;
export const {
  setLogIn,
  logOut,
  setAuth,
  setCurrentUser,
  setCurrentUserChat,
  setLoading,
  setEmailErr,
} = actions;
export default authReducer;
