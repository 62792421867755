import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TSetListAction } from 'types/types';
import { managerApi } from 'api/manager';
import {
  DEFAULT_PAGE,
  DEFAULT_PER_PAGE,
  TActionParams,
  TCallback,
  TOptionsQuery,
} from 'types/common';
import { setShowToast } from 'store/toast/toastSlice';
import { ETypeToast } from 'app/enum';
import { TClientManagement, TManagerManagement } from 'types/clientManagement/clientManagement';
import { t } from 'i18next';
import { translations } from '../../locales/translations';
import { convertValueToNumber, getLabelError } from '../../helpers/funcs';
import { getListComManagement } from '../com/comSlice';
import { statusErrEmail } from '../../helpers/constant';

export type ManagerState = {
  loading: boolean;
  listManagerManagement: TManagerManagement[];
  infoManager: TManagerManagement;
  total: number;
  totalPage: number;
  page: number;
  emailErr: string;
  currentLoad: boolean;
};

const initialState: ManagerState = {
  loading: false,
  listManagerManagement: [],
  infoManager: {},
  total: 0,
  totalPage: 1,
  page: 1,
  emailErr: '',
  currentLoad: false,
};

export const DEFAULT_ORDER_BY_MANAGER_MANAGEMENT = 'createdAt';
export const DEFAULT_ORDER_DIRECTION_MANAGER_MANAGEMENT = 'desc';
export const getListManagerManagement = createAsyncThunk(
  'manager/getListManagerManagement',
  async (params: TOptionsQuery<TManagerManagement>, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    const { loadMore, ...rest } = params;
    const newParams: TOptionsQuery<TManagerManagement> = {
      ...rest,
      orderBy: rest.orderBy ?? DEFAULT_ORDER_BY_MANAGER_MANAGEMENT,
      orderDirection: rest.orderDirection ?? DEFAULT_ORDER_DIRECTION_MANAGER_MANAGEMENT,
      page: rest.page ?? DEFAULT_PAGE,
      perPage: rest.perPage ?? DEFAULT_PER_PAGE,
    };
    await managerApi
      .getListManager(newParams)
      .then((response) => {
        thunkAPI.dispatch(
          setListManagerManagement({
            type: loadMore ? 'loadMore' : 'getList',
            managers: response.data.data,
            total: response.data._metadata.pagination.total,
            totalPage: response.data._metadata.pagination.totalPage,
          }),
        );
      })
      .catch((response) => {
        thunkAPI.dispatch(
          setShowToast({
            label: getLabelError(response.response.data.statusCode),
            type: ETypeToast.Error,
          }),
        );
        thunkAPI.dispatch(setLoading(false));
      });
    thunkAPI.dispatch(setLoading(false));
  },
);

export const registerManagerManagement = createAsyncThunk(
  'manager/registerManagerManagement',
  async (
    params: { actionParams: TActionParams<TManagerManagement>; handleClose: () => void },
    thunkAPI,
  ) => {
    thunkAPI.dispatch(setLoading(true));
    await managerApi
      .registerManager(params.actionParams.data)
      .then(() => {
        params.handleClose();
        thunkAPI.dispatch(resetListManagerManagement());
        thunkAPI.dispatch(setPage(1));
        thunkAPI.dispatch(setCurrentLoad(true));
        thunkAPI.dispatch(
          setShowToast({
            label: t(translations.ID_MASTER.REGISTER_SUCCESS),
            type: ETypeToast.Success,
          }),
        );
        thunkAPI.dispatch(
          getListManagerManagement({ perPage: 0, clientId: params.actionParams.data.clientId }),
        );
      })
      .catch((response) => {
        if (statusErrEmail.includes(response.response.data.statusCode)) {
          thunkAPI.dispatch(setEmailErr(getLabelError(response.response.data.statusCode)));
        } else {
          thunkAPI.dispatch(
            setShowToast({
              label: getLabelError(response.response.data.statusCode),
              type: ETypeToast.Error,
            }),
          );
        }
        thunkAPI.dispatch(setLoading(false));
      });
    thunkAPI.dispatch(setLoading(false));
  },
);

export const updateManagerManagement = createAsyncThunk(
  'manager/updateManagerManagement',
  async (
    params: { id: string; data: TActionParams<TManagerManagement>; handleClose: () => void },
    thunkAPI,
  ) => {
    thunkAPI.dispatch(setLoading(true));
    await managerApi
      .updateManager({ id: params.id, data: params.data.data })
      .then((response: any) => {
        thunkAPI.dispatch(
          setListManagerManagement({
            type: 'edit',
            manager: { ...params.data.data, _id: response.data.data._id },
          }),
        );
        thunkAPI.dispatch(
          setShowToast({
            label: t(translations.ID_MASTER.UPDATE_SUCCESS),
            type: ETypeToast.Success,
          }),
        );
        thunkAPI.dispatch(
          getListManagerManagement({ perPage: 0, clientId: params.data.data.clientId }),
        );
        params.handleClose();
      })
      .catch((response) => {
        if (statusErrEmail.includes(response.response.data.statusCode)) {
          thunkAPI.dispatch(setEmailErr(getLabelError(response.response.data.statusCode)));
        } else {
          thunkAPI.dispatch(
            setShowToast({
              label: getLabelError(response.response.data.statusCode),
              type: ETypeToast.Error,
            }),
          );
        }
        thunkAPI.dispatch(setLoading(false));
      });
    thunkAPI.dispatch(setLoading(false));
  },
);

export const deleteManagerManagement = createAsyncThunk(
  'manager/deleteManagerManagement',
  async (manager: TManagerManagement, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    await managerApi
      .deleteManager(String(manager._id))
      .then(() => {
        thunkAPI.dispatch(setListManagerManagement({ type: 'delete', manager: manager }));
        thunkAPI.dispatch(
          setShowToast({
            label: t(translations.ID_MASTER.DELETE_SUCCESS),
            type: ETypeToast.Success,
          }),
        );
      })
      .catch((response) => {
        thunkAPI.dispatch(
          setShowToast({
            label: getLabelError(response.response.data.statusCode),
            type: ETypeToast.Error,
          }),
        );
        thunkAPI.dispatch(setLoading(false));
      });
    thunkAPI.dispatch(setLoading(false));
  },
);

export const stopManagerManagement = createAsyncThunk(
  'manager/stopManagerManagement',
  async (manager: TManagerManagement, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    if (manager.isActive) {
      await managerApi
        .inActiveManager(String(manager.userId))
        .then(() => {
          thunkAPI.dispatch(setListManagerManagement({ type: 'stop', manager: manager }));
          thunkAPI.dispatch(
            setShowToast({
              label: t(translations.ID_MASTER.INACTIVE_SUCCESS),
              type: ETypeToast.Success,
            }),
          );
        })
        .catch((response) => {
          thunkAPI.dispatch(
            setShowToast({
              label: getLabelError(response.response.data.statusCode),
              type: ETypeToast.Error,
            }),
          );
          thunkAPI.dispatch(setLoading(false));
        });
    } else {
      await managerApi
        .activeManager(String(manager.userId))
        .then(() => {
          thunkAPI.dispatch(setListManagerManagement({ type: 'stop', manager: manager }));
          thunkAPI.dispatch(
            setShowToast({
              label: t(translations.ID_MASTER.INACTIVE_SUCCESS),
              type: ETypeToast.Success,
            }),
          );
        })
        .catch((response) => {
          thunkAPI.dispatch(
            setShowToast({
              label: getLabelError(response.response.data.statusCode),
              type: ETypeToast.Error,
            }),
          );
          thunkAPI.dispatch(setLoading(false));
        });
    }
    thunkAPI.dispatch(setLoading(false));
  },
);

export const inviteManagerManagement = createAsyncThunk(
  'client/inviteManagerManagement',
  async ({ id, clientId }: any, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    await managerApi
      .inviteManager(id)
      .then(() => {
        thunkAPI.dispatch(
          setShowToast({
            label: '招待メールが送信されました。',
            type: ETypeToast.Success,
          }),
        );

        thunkAPI.dispatch(getListManagerManagement({ clientId }));
      })
      .catch((response) => {
        thunkAPI.dispatch(
          setShowToast({
            label: getLabelError(response.response.data.statusCode),
            type: ETypeToast.Error,
          }),
        );
        thunkAPI.dispatch(setLoading(false));
      });
    thunkAPI.dispatch(setLoading(false));
  },
);

export const getInfoManager = createAsyncThunk('manager/getInfoClient', async (id: string) => {
  const response = await managerApi.getInfoManager(id);
  return response.data.data;
});

export const updateInfoManager = createAsyncThunk(
  'manager/updateInfoClient',
  async (params: { id: string; data: TManagerManagement } & TCallback, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    await managerApi
      .updateInfoManager(params.id, params.data)
      .then(() => {
        params.callback?.();
        thunkAPI.dispatch(getInfoManager(params.id));
        thunkAPI.dispatch(getListComManagement({ perPage: 0 }));
        thunkAPI.dispatch(
          setShowToast({
            label: t(translations.ID_MASTER.UPDATE_SUCCESS),
            type: ETypeToast.Success,
          }),
        );
      })
      .catch((response) => {
        thunkAPI.dispatch(
          setShowToast({
            label: getLabelError(response.response.data.statusCode),
            type: ETypeToast.Error,
          }),
        );
        thunkAPI.dispatch(setLoading(false));
      });
    thunkAPI.dispatch(setLoading(false));
  },
);

const slice = createSlice({
  name: 'manager',
  initialState,
  reducers: {
    setListManagerManagement: (
      state,
      action: PayloadAction<{
        type: TSetListAction;
        manager?: TManagerManagement;
        managers?: TManagerManagement[];
        totalPage?: number;
        total?: number;
      }>,
    ) => {
      const { type, manager, managers, total, totalPage } = action.payload;
      if (manager) {
        switch (type) {
          case 'create':
            state.listManagerManagement = [
              { ...manager, isActive: true },
              ...state.listManagerManagement,
            ];
            break;
          case 'edit':
            state.listManagerManagement = state.listManagerManagement.map((m: TManagerManagement) =>
              m._id === manager._id ? { ...manager, isActive: true } : m,
            );
            break;
          case 'delete':
            state.listManagerManagement = state.listManagerManagement.filter(
              (m: TManagerManagement) => m._id !== manager._id,
            );
            break;
          case 'stop':
            state.listManagerManagement = state.listManagerManagement.map((m: TManagerManagement) =>
              m._id === manager._id ? { ...manager, isActive: !manager.isActive } : m,
            );
        }
      }
      if (managers) {
        switch (type) {
          case 'getList':
            state.listManagerManagement = managers;
            break;
          case 'loadMore':
            state.listManagerManagement = [...state.listManagerManagement, ...managers];
            break;
        }
        state.totalPage = convertValueToNumber(totalPage);
        state.total = convertValueToNumber(total);
      }
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setEmailErr: (state, action: PayloadAction<string>) => {
      state.emailErr = action.payload;
    },
    resetListManagerManagement: (state) => {
      state.listManagerManagement = [];
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setCurrentLoad: (state, action: PayloadAction<boolean>) => {
      state.currentLoad = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getInfoManager.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getInfoManager.fulfilled, (state, action: PayloadAction<TClientManagement>) => {
      state.loading = false;
      state.infoManager = action.payload;
    });
  },
});

export const { actions, reducer: ManagerManagementReducer } = slice;
export const {
  setListManagerManagement,
  setLoading,
  setEmailErr,
  resetListManagerManagement,
  setPage,
  setCurrentLoad,
} = actions;
export default ManagerManagementReducer;
