import { axiosClient } from './axiosClient';
import { TClientManagement, TFacilityManagement } from '../types/clientManagement/clientManagement';
import { TOptionsQuery } from '../types/common';
import queryString from 'querystring';
import { TComManagement } from '../types/comManagement/comManagement';

export const comApi = {
  updateValueMemo: (params: any) => {
    const url = `/employee/${params.employeeId}/memo`;
    const data = params.data;
    return axiosClient.put(url, data);
  },
  getListComManagement: (params: TOptionsQuery<TClientManagement>) => {
    const url = '/com';
    const query = queryString.stringify(params);
    return axiosClient.get(`${url}?${query}`);
  },
  registerCom: (data: TFacilityManagement) => {
    const url = '/com';
    return axiosClient.post(url, data);
  },
  updateCom: (params: { id: string; data: TFacilityManagement }) => {
    const url = `/com/${params.id}`;
    return axiosClient.put(url, params.data);
  },
  inActiveCom: (id: string) => {
    const url = `/admin/user/update/${id}/inactive`;
    return axiosClient.patch(url);
  },
  activeCom: (id: string) => {
    const url = `/admin/user/update/${id}/active`;
    return axiosClient.patch(url);
  },
  deleteCom: (id: string) => {
    const url = `/com/${id}`;
    return axiosClient.delete(url);
  },
  inviteCom: (id: string) => {
    const url = `/com/${id}/invitation`;
    return axiosClient.post(url);
  },
  getListSufficiency: (params: TOptionsQuery<TClientManagement>) => {
    const url = '/suffuciency';
    const query = queryString.stringify(params);
    return axiosClient.get(`${url}?${query}`);
  },
  getInfoCom: (id: string) => {
    const url = `/com/${id}`;
    return axiosClient.get(url);
  },
  updateInfoCom: (id: string, data: TComManagement) => {
    const url = `/com/${id}`;
    return axiosClient.put(url, data);
  },
};
