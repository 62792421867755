import { useService } from './service';
import { Bounce, toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from 'react';
import { ToastOptions } from 'react-toastify/dist/types';
import { ETypeToast } from '../../../enum';

const Toast = () => {
  const { label, isShow, hide, type } = useService();
  const options: ToastOptions = {
    position: 'top-right',
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: 'light',
    transition: Bounce,
    onClose: () => {
      hide();
    },
  };

  useEffect(() => {
    if (isShow) {
      if (type === ETypeToast.Success) toast.success(label, options);
      else toast.error(label, options);
    }
  }, [isShow]);

  return (
    <ToastContainer
      autoClose={2000}
      position="top-right"
      hideProgressBar={true}
      closeOnClick={true}
    />
  );
};

export default Toast;
