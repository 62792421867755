import {
  defaultDate,
  formatDateTimeChat, formatTime,
  IndexedObject,
  isEmptyObject,
  regexEmail,
} from 'types/common';
import queryString from 'querystring';
import moment from 'moment';
import { t } from 'i18next';
import { translations } from '../locales/translations';
import Cookies from 'js-cookie';
import { score } from 'types/analysis/analysis';
import React from 'react';

export const parseFloatNum = (str: string) => {
  const trimmed = str && typeof str.trim === 'function' ? str.trim() : null;
  if (!trimmed) {
    return null;
  }
  const num = parseFloat(trimmed);
  const isNumber = !isNaN(num);
  const isFullyParsedNum = isNumber && num.toString() === trimmed;
  return isFullyParsedNum ? num : null;
};
export const parseQueryParams = (search: string) => {
  const newSearch = search && search[0] === '?' ? search.slice(1) : search;
  const params = queryString.parse(newSearch);
  return Object.keys(params).reduce((result: IndexedObject, key) => {
    const val = params[key];
    if (val === 'true') {
      result[key] = true;
    } else if (val === 'false') {
      result[key] = false;
    } else {
      const num = parseFloatNum(val ? val.toString() : '');
      result[key] = num === null ? val : num;
    }
    return result;
  }, {});
};
export const createQueryUrl = (location: IndexedObject, params: IndexedObject) => {
  const { pathname } = location;
  if (isEmptyObject(params)) return pathname;
  const query = queryString.stringify(params);
  return `${pathname}?${query}`;
};
export const omitObject = (obj: IndexedObject, keys: Array<any>): IndexedObject => {
  if (!keys.length) return obj;
  const { [keys.pop()]: omitted, ...rest } = obj;
  return omitObject(rest, keys);
};

export const replacePathParams = (path: string, params: IndexedObject<string>): string =>
  path.replace(/:([^/]+)/g, (_, p1) => encodeURIComponent(params[p1] ? params[p1] : ''));

export const convertDate = (date: Date | string | number) => {
  return moment(date).format(defaultDate);
};

export const convertDate2 = (date: Date | string | number) => {
  return (
    convertDate(Number(date)).split('/')[0] +
    convertDate(Number(date)).split('/').slice(1).join('/')
  );
};

export const convertDate3 = (date: string) => {
  const newDate = date.includes('/') ? new Date(date) : date;
  return moment(newDate).format(defaultDate);
};

export const convertTime = (date: Date | string) => {
  return moment(date).format(formatTime);
};

export const convertTimeChat = (date: Date | string | number) => {
  if (typeof date !== 'number') {
    const dateObj = new Date(date);
    date = dateObj.getTime();
  }
  return moment(date).format(formatDateTimeChat);
};

export const convertDateJP = (date: Date | string) => {
  return moment(date).format('YYYY年MM月DD日');
};

export const roundNumber = (number: number) => {
  if (number % 1 === 0 && number % 0.01 === 0) return Math.round(number);
  else if (isNaN(number)) return 0;
  else return number;
};

// get label index of radar chart
export const getLabelIndex = (x, y, pointLabels) => {
  let index = -1;
  for (let i = 0; i < pointLabels.length; i++) {
    const { top, right, bottom, left } = pointLabels[i];
    if (x >= left && x <= right && y >= top && y <= bottom) {
      index = i;
      break;
    }
  }
  return index;
};

export const trimObject = (data: object) => {
  const dataCopy = { ...data };
  for (let key in dataCopy) {
    dataCopy[key] = dataCopy[key].trim();
  }

  return dataCopy;
};

export const handleSetHasMore = (
  page: number,
  setHasMore: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  if (page === 1) setHasMore(true);
};

export const listTextDirectionCoordinates = [
  'A：協調性（警戒心欠如）',
  'B：環境順応性（妥協的）',
  'C：優柔性（決断力欠如）',
  'D：謙譲性（劣等感）',
  'E：自省心（懐疑的）',
  'F：規律性（依存的）',
  'G：固執性（心理的圧迫）',
  'H：情動性（感情的）',
  'I：感受性（神経質）',
  'J：自己信頼性（理想主義）',
  'K：革新性（衝動的）',
  'L：活動性（性急）',
  'M：積極性（攻撃的）',
  'N：指導性（自尊心）',
  'O：創造性（反発的）',
  'P：社交性（自己顕示）',
];

export const compareTime = (a, b) => {
  let timeA = new Date(a?.startTime);
  let timeB = new Date(b?.startTime);
  return timeB.getTime() - timeA.getTime();
};

export const compareTimeASC = (a, b) => {
  let timeA = new Date(a?.startTime);
  let timeB = new Date(b?.startTime);
  return timeA.getTime() - timeB.getTime();
};

const listErrorResponse = {
  18200: t(translations.ERROR_RESPONSE['18200']),
  6200: t(translations.ERROR_RESPONSE['6200']),
  6202: t(translations.ERROR_RESPONSE['6202']),
  6204: t(translations.ERROR_RESPONSE['6204']),
  6208: t(translations.ERROR_RESPONSE['6208']),
  6210: t(translations.ERROR_RESPONSE['6210']),
  1200: t(translations.ERROR_RESPONSE['1200']),
  1202: t(translations.ERROR_RESPONSE['1202']),
  1204: t(translations.ERROR_RESPONSE['1204']),
  1208: t(translations.ERROR_RESPONSE['1208']),
  3200: t(translations.ERROR_RESPONSE['3200']),
  3202: t(translations.ERROR_RESPONSE['3202']),
  14200: t(translations.ERROR_RESPONSE['14200']),
  4200: t(translations.ERROR_RESPONSE['4200']),
  4210: t(translations.ERROR_RESPONSE['4210']),
  2200: t(translations.ERROR_RESPONSE['2200']),
  11200: t(translations.ERROR_RESPONSE['11200']),
  11208: t(translations.ERROR_RESPONSE['11208']),
  9200: t(translations.ERROR_RESPONSE['9200']),
  9202: t(translations.ERROR_RESPONSE['9202']),
  14207: t(translations.ERROR_RESPONSE['14207']),
  14210: t(translations.ERROR_RESPONSE['14210']),
  7200: t(translations.ERROR_RESPONSE['7200']),
  7202: t(translations.ERROR_RESPONSE['7202']),
  12200: t(translations.ERROR_RESPONSE['12200']),
  12208: t(translations.ERROR_RESPONSE['12208']),
  5100: t(translations.ERROR_RESPONSE['5100']),
  5101: t(translations.ERROR_RESPONSE['5101']),
  5102: t(translations.ERROR_RESPONSE['5102']),
  5103: t(translations.ERROR_RESPONSE['5103']),
  5104: t(translations.ERROR_RESPONSE['5104']),
  5105: t(translations.ERROR_RESPONSE['5105']),
  13200: t(translations.ERROR_RESPONSE['13200']),
  5200: t(translations.ERROR_RESPONSE['5200']),
  5201: t(translations.ERROR_RESPONSE['5201']),
  5202: t(translations.ERROR_RESPONSE['5202']),
  5204: t(translations.ERROR_RESPONSE['5204']),
  5205: t(translations.ERROR_RESPONSE['5205']),
  5206: t(translations.ERROR_RESPONSE['5206']),
  5207: t(translations.ERROR_RESPONSE['5207']),
  5208: t(translations.ERROR_RESPONSE['5208']),
  5209: t(translations.ERROR_RESPONSE['5209']),
  5210: t(translations.ERROR_RESPONSE['5210']),
  5211: t(translations.ERROR_RESPONSE['5211']),
  112101: t(translations.ERROR_RESPONSE['112101']),
  22091: t(translations.ERROR_RESPONSE['22091']),
  42101: t(translations.ERROR_RESPONSE['42101']),
};

export const listErrorStatus = {
  EMPLOYEE_EMAIL_EXIST_ERROR: 4202,
  EMPLOYEE_INFORMATION_INVALID: 4209,
};

// eslint-disable-next-line complexity
export const getLabelError = (statusCode: number) => {
  return Object.keys(listErrorResponse).includes(statusCode.toString())
    ? listErrorResponse[statusCode]
    : t(translations.ERROR_RESPONSE.ERROR_DEFAULT);
};

export const convertValueToString = (value?: string) => {
  if (value) return value;
  else return '';
};

export const formatDateFulTime = (time?: string | Date) => {
  return time ? moment(time).utc().format('YYYY/MM/DD HH:mm') : '';
};

export const convertValueToNumber = (value?: any) => {
  if (value) return value;
  else return 0;
};

export const clearAllData = () => {
  // Clear cache and reload
  window.location.reload();

  // Clear local storage
  sessionStorage.clear();

  // Clear cookies
  const cookies = Object.keys(Cookies.get());
  cookies.forEach((cookie) => {
    Cookies.remove(cookie);
  });
};

export const clearDataEmployee = () => {
  // Clear local storage
  localStorage.clear();
  sessionStorage.clear();
  // Clear cookies
  const cookies = Object.keys(Cookies.get());
  cookies.forEach((cookie) => {
    Cookies.remove(cookie);
  });
};

export const formatNumber = (number) => {
  if (Number.isInteger(number)) return number;
  else if (Number.isNaN(number)) return 0;
  else return number.toFixed(2);
};

export const requiredEmailIfNotEmpty = (_, value) => {
  if (value.trim() === '') {
    return Promise.reject(new Error(t(translations.ID_MASTER.MESSAGE_VALIDATE.REQUIRE)));
  } else if (!regexEmail.test(value.trim())) {
    return Promise.reject(new Error(t(translations.ID_MASTER.MESSAGE_VALIDATE.EMAIL)));
  } else {
    return Promise.resolve();
  }
};

export const colorIndex2 = (params: {
  rowIndex: number;
  colIndex: number;
  isHeader?: boolean;
  scores: score[][];
  s: any;
}) => {
  const { rowIndex, colIndex, isHeader, scores, s } = params;
  if (isHeader) {
    if (scores[colIndex][rowIndex].status === 'colRed') {
      return `${s.borderLeftPrimary} ${s.borderTopPrimary} ${s.borderRightPrimary} ${s.borderBottomGray} ${s.bgPrimary}`;
    } else if (scores[colIndex][rowIndex].status === 'colBlue') {
      return `${s.borderLeftBlue} ${s.borderTopBlue} ${s.borderRightBlue} ${s.borderBottomGray} ${s.bgBlue}`;
    } else {
      return 'border border-gray-400';
    }
  } else {
    if (scores[colIndex][rowIndex].status === 'red') {
      return `border border-primary ${s.bgPrimary}`;
    } else if (scores[colIndex][rowIndex].status === 'blue') {
      return `border ${s.borderBlue} ${s.bgBlue}`;
    } else if (scores[colIndex][rowIndex].status === 'colRed') {
      return `${s.borderLeftPrimary} ${s.borderTopGray} ${s.borderRightPrimary} ${s.borderBottomGray} ${s.bgPrimary}`;
    } else if (scores[colIndex][rowIndex].status === 'colBlue') {
      return `${s.borderLeftBlue} ${s.borderTopBlue} ${s.borderRightBlue} ${s.borderBottomGray} ${s.bgBlue}`;
    } else {
      return 'border border-gray-400';
    }
  }
};

function uploadAdapter(loader: any) {
  return {
    upload: () => {
      return new Promise(() => {
        loader.file.then(async () => {});
      });
    },
  };
}

export function uploadPlugin(editor: any) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
    return uploadAdapter(loader);
  };
}

export const convertColor = (color: number) => {
  let newColor: string;
  switch (color) {
    case 0:
      newColor = '';
      break;
    case 1:
      newColor = 'red';
      break;
    case 2:
      newColor = 'blue';
      break;
    default:
      newColor = '';
      break;
  }
  return newColor;
};

export const checkColorNotHeader = (params: {
  table: score[][];
  row: number;
  col: number;
  s: any;
  isLastRowItem?: boolean;
}) => {
  const { table, row, col, s, isLastRowItem } = params;
  if (table[row][col]?.status === 'red') {
    return ` ${s.borderRed2} ${s.bgPrimary}`;
  } else if (table[row][col]?.status === 'blue') {
    return ` ${s.borderBlue2} ${s.bgBlue}`;
  } else if (table[row][col]?.status === 'colRed') {
    if (isLastRowItem) {
      return `${s.borderLeftPrimary}  ${s.borderRightPrimary} ${s.borderBottomPrimary} ${s.bgPrimary} custom-border-backup`;
    } else {
      return `${s.borderLeftPrimary} ${s.borderRightPrimary} ${s.borderBottomGray} ${s.bgPrimary} custom-border-backup`;
    }
  } else if (table[row][col]?.status === 'colBlue') {
    if (isLastRowItem) {
      return `${s.borderLeftBlue}  ${s.borderRightBlue} ${s.borderBottomBlue} ${s.bgBlue} custom-border-backup`;
    } else {
      return `${s.borderLeftBlue} ${s.borderRightBlue} ${s.borderBottomGray} ${s.bgBlue} custom-border-backup`;
    }
  } else {
    return 'custom-border-new';
  }
};

export const checkColorNotHeader4Type = (params: {
  table: score[][];
  row: number;
  col: number;
  s: any;
  isLastRowItem?: boolean;
}) => {
  const { table, row, col, s, isLastRowItem } = params;
  if (table[row][col]?.status === 'colRed') {
    if (isLastRowItem) {
      return `${s.borderLeftPrimary}  ${s.borderRightPrimary} ${s.bgPrimary}`;
    } else {
      return `${s.borderLeftPrimary} ${s.borderRightPrimary}  ${s.bgPrimary}`;
    }
  } else {
    return 'custom-border-new-4type';
  }
};

export const checkColor = (params: {
  table: score[][];
  row: number;
  col: number;
  s: any;
  isLastRowItem?: boolean;
  isHeader?: boolean;
}) => {
  const { table, isHeader, row, col, s, isLastRowItem } = params;
  if (!table.length || !table[row]?.length || !table[row][col]) return '';

  if (isHeader) {
    if (table[row][col]?.status === 'colRed') {
      return `${s.borderLeftPrimary} ${s.borderTopPrimary} ${s.borderRightPrimary} ${s.borderBottomGray}  ${s.bgPrimary} custom-border-backup`;
    } else if (table[row][col]?.status === 'colBlue') {
      return ` ${s.borderLeftBlue} ${s.borderTopBlue} ${s.borderBottomGray} ${s.borderRightBlue}  ${s.bgBlue} custom-border-backup`;
    } else {
      return 'custom-border-new';
    }
  } else {
    return checkColorNotHeader({
      row,
      col,
      isLastRowItem,
      table,
      s,
    });
  }
};

export const checkColor4type = (params: {
  table: score[][];
  row: number;
  col: number;
  s: any;
  isLastRowItem?: boolean;
  isHeader?: boolean;
}) => {
  const { table, isHeader, row, col, s, isLastRowItem } = params;
  if (!table.length || !table[row]?.length || !table[row][col]) return '';

  if (isHeader) {
    if (table[row][col]?.status === 'colRed') {
      return `${s.borderLeftPrimary} ${s.borderTopPrimary} ${s.borderRightPrimary}  ${s.bgPrimary}`;
    } else {
      return 'custom-border-new-4type';
    }
  } else {
    return checkColorNotHeader4Type({
      row,
      col,
      isLastRowItem,
      table,
      s,
    });
  }
};

export const convertData = (data: any, highlightAxis16?: any, reportId?: string) => {
  if (!data.length) return [];
  const newData = data?.map((score) => Object.values(score));

  if (!newData.length) return [];

  let dataTranspose = newData.map((item, index) => {
    if (index > newData?.length - 4) {
      return [item[0], ...item];
    } else {
      return [...item.slice(-2), ...item.slice(0, -2)];
    }
  });
  dataTranspose = dataTranspose.map((rows) => rows.map((item) => ({ value: item, status: '' })));

  getDataAxis16(dataTranspose, highlightAxis16, reportId);

  return dataTranspose as score[][];
};

const getDataAxis16 = (dataConvert: any, highlightAxis16: any, reportId?: string) => {
  if (highlightAxis16?.length && reportId && dataConvert?.length) {
    dataConvert?.forEach((data, rowIndex) => {
      highlightAxis16.forEach((item) => {
        if (item.id === data[0].value) {
          dataConvert[rowIndex][item.index]['status'] = item?.color ?? '';
        }
      });
    });
  }
};

const logicHighLightFullCol = (params: { colIndex: number; rowIndex: number; data: any }) => {
  const { colIndex, rowIndex, data } = params;
  let newState = [...data];
  newState = newState.map((rows) =>
    rows.map((item, index) => {
      if (index === colIndex) {
        let newRow;
        switch (rowIndex) {
          case 0:
            newRow = {
              ...item,
              status: 'colRed',
            };
            break;
          case 1:
            newRow = {
              ...item,
              status: 'colBlue',
            };
            break;
          default:
            newRow = {
              ...item,
              status: '',
            };
            break;
        }
        return newRow;
      } else {
        return item;
      }
    }),
  );
  return newState;
};

const highlightFullCol = (params: {
  colIndex: number;
  rowIndex: number;
  setData: any;
  data: any;
}) => {
  const { colIndex, rowIndex, data, setData } = params;
  if (!data?.length) return;
  let newState = [...data];
  if (colIndex !== 0) {
    if (
      (newState[rowIndex][colIndex].status === 'colBlue' && rowIndex === 1) ||
      (newState[rowIndex][colIndex].status === 'colRed' && rowIndex === 0)
    ) {
      newState = newState.map((rows) => {
        return rows.map((item, index) => {
          if (index === colIndex) {
            return {
              ...item,
              status: '',
            };
          } else {
            return item;
          }
        });
      });
    } else {
      newState = logicHighLightFullCol({ colIndex, rowIndex, data });
    }

    setData(newState);
  }
};

const highlightItem = (params: { colIndex: number; rowIndex: number; setData: any; data: any }) => {
  const { colIndex, rowIndex, data, setData } = params;
  let newState = [...data];

  if (colIndex !== 0) {
    if (
      newState[rowIndex][colIndex].status === 'colRed' ||
      newState[rowIndex][colIndex].status === 'colBlue'
    )
      return;

    switch (newState[rowIndex][colIndex].status) {
      case '':
        newState[rowIndex][colIndex] = { ...newState[rowIndex][colIndex], status: 'red' };
        break;
      case 'red':
        newState[rowIndex][colIndex] = { ...newState[rowIndex][colIndex], status: 'blue' };
        break;
      case 'blue':
        newState[rowIndex][colIndex] = { ...newState[rowIndex][colIndex], status: '' };
        break;
      default:
        newState[rowIndex][colIndex] = { ...newState[rowIndex][colIndex], status: '' };
        break;
    }
    setData(newState);
  }
};

export const handleHighlight = (params: {
  colIndex: number;
  rowIndex: number;
  highLightCol?: boolean;
  setData: any;
  data: any;
}) => {
  const { colIndex, rowIndex, highLightCol, setData, data } = params;
  if (!data?.length) return;
  if (highLightCol) {
    highlightFullCol({ colIndex, rowIndex, setData, data });
  } else {
    highlightItem({ colIndex, rowIndex, setData, data });
  }
};

export const getNameEmployee = (id: any, listEmployee: { name: string; id: string }[]) => {
  const employee = listEmployee.find((item) => item.id === id);
  return employee?.name ?? '';
};

export const generateKey = (key: string, index: number) => `${key}${index}`;

export const chunkArray = (array: any[], chunkSize: number) => {
  let result: any = [];
  if (!array?.length) return [];
  for (let i = 0; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize));
  }
  return result;
};

export const roundToTwoDecimalPlaces = (score: string, fixed = 2) => {
  if (score.includes('.') && score.split('.')[1].length > 2) {
    return Number(score).toFixed(fixed) ?? '';
  } else {
    return score ?? '';
  }
};

export const roundToTwoDecimalPlaces2 = (score: string, rowIndex: number) => {
  if (score === '0') return '0';
  else return Number(score).toFixed(1) ?? '';
};

export const roundDecimal = (score?: number | string, fixed = 1) => {
  return Number(score ?? 0).toFixed(fixed);
};

export const getLabelFromValue = (value: string[], options: { value: string; label: string }[]) => {
  const findItem = options.filter((item) => value.includes(item.value));
  return typeof value !== 'string' ? findItem.map((item) => item.label).join(',') : '';
};

export function generateHourlyAndHalfHourlyTimeSlots() {
  const timeSlots: string[] = [];
  const startDate = new Date(); // Assuming today's date for start
  startDate.setHours(9, 0, 0, 0); // Start at 9 AM

  const endDate = new Date(startDate);
  endDate.setHours(22, 0, 0, 0); // End at 10 PM

  for (let date = new Date(startDate); date < endDate; date.setMinutes(date.getMinutes() + 30)) {
    const startHour = date.getHours();
    const startMinute = date.getMinutes();
    // Calculate the end time by adding one hour to the start time
    const endDate = new Date(date.getTime() + 60 * 60000); // Add 60 minutes to the start date
    const endHour = endDate.getHours();
    const endMinute = endDate.getMinutes();
    // Generate the formatted time slot string
    const timeSlot = `_${padZero(startHour)}${padZero(startMinute)}_${padZero(endHour)}${padZero(
      endMinute,
    )}`;
    // Add the formatted time slot to the list
    timeSlots.push(timeSlot);
  }

  return timeSlots;
}

export const fieldsToCheckTimeToday = (availableTimesCommon: string[]) => {
  const now = new Date(new Date().toLocaleString('en', { timeZone: 'Asia/Tokyo' }));
  return generateHourlyAndHalfHourlyTimeSlots().filter((time, index) => {
    const transformTime = availableTimesCommon[index]?.split(':');
    const specificTime = moment()
      .hours(transformTime ? +transformTime[0] : 0)
      .minutes(transformTime ? +transformTime[1] : 0);
    return specificTime.isAfter(moment(now));
  });
};

function padZero(number) {
  return number < 10 ? '0' + number : number.toString();
}
