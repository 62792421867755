import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EStatusReport, ETypeToast } from 'app/enum';
import { getLabelError } from 'helpers/funcs';
import { setShowToast } from 'store/toast/toastSlice';
import analysisApi from '../../api/analysisApi';
import {
  filterCompare,
  TAnalysis,
  TCurrentRadar,
  TDataFulfill,
  TDataFulfill2,
  TDataFulfill3,
  TMemo,
  TNumberPageMemo,
  TRadarCompare,
  TReportAlert,
} from '../../types/analysis/analysis';
import { TImplement } from '../../types/clientManagement/clientManagement';
import { t } from 'i18next';
import { translations } from '../../locales/translations';
import { RootState } from 'types';
import {
  textScoreAverage,
  textScoreAverage4Category,
} from 'app/pages/AnalyzeOmoteraDiagnosisResult/service';
import { getListClientManagement, getListFacilityManagement } from '../client/clientSlice';
import {
  removeClientFacilityAndEventSelected,
  saveClientFacilityAndEventSelected,
  saveReportId,
} from '../../helpers/localStorage';
import { IndexedObject, roleUser, TOptionsQuery } from 'types/common';
import { getUserFromCookie } from 'helpers/cookies';

export type AnalysisState = {
  loading: boolean;
  status: EStatusReport;
  listAnalysis: TAnalysis[];
  listEventByClientAndFacility: TImplement[];
  isRenderCreateReport: boolean;
  currentDetailReport: any;
  detailReport: any;
  reportFourCategory: any;
  occupations: string[];
  roles: string[];
  reportName: string;
  occupationSelect: string[];
  dateSelect: string[];
  ageSelect: string[];
  roleSelect: string[];
  clientSelect: { id: string; name: string };
  facilitySelect: { id: string; name: string };
  eventSelect: { id: string; name: string };
  listEmployee: {
    id: string;
    name: string;
  }[];
  listEmployee4Category: {
    id: string;
    name: string;
  }[];
  listEmployeeFulfill: {
    id: string;
    name: string;
  }[];
  highlightAxis16: any;
  highlight4Category: any;
  memoContent: TMemo;
  report16CoordinatesCompare: {
    currentRadar: TCurrentRadar;
    radarCompare: TRadarCompare;
  };
  listNumberPageInputMemoCKEditor: TNumberPageMemo;
  reportAlert: TReportAlert[];
  dataFulfill: TDataFulfill[][];
  dataFulfill2: TDataFulfill2[];
  dataFulfill3: TDataFulfill3[];
  checkBoxPdf: {
    axis16: boolean;
    axis16Compare: boolean;
    fourCategories: boolean;
    redAlertAndYellowAlert: boolean;
    fulfillment: boolean;
  };
  filterAxis16Compare: {
    clientIdCompare: string;
    eventIdCompare: string;
    facilityIdCompare: string;
    ageCompare: string[];
    dateCompare: string[];
    occupationCompare: string;
    roleCompare: string;
    age: string[];
    date: string[];
    occupation: string;
    role: string;
  };
  filter4Category: {
    age: string[];
    date: string[];
    occupation: string[];
    role: string[];
  };
  filterAlert: {
    age: string[];
    date: string[];
    occupation: string[];
    role: string[];
  };
  filterFulfillmentState: {
    typePoint: string;
    age: string[];
    date: string[];
    occupation: string[];
    role: string[];
  };
};

const initialState: AnalysisState = {
  loading: false,
  status: EStatusReport.UnPublic,
  listAnalysis: [],
  currentDetailReport: [],
  detailReport: [],
  listEventByClientAndFacility: [],
  isRenderCreateReport: false,
  occupations: [],
  roles: [],
  dateSelect: [],
  ageSelect: [],
  roleSelect: [],
  occupationSelect: [],
  clientSelect: { id: '', name: '' },
  facilitySelect: { id: '', name: '' },
  eventSelect: { id: '', name: '' },
  listEmployee: [],
  listEmployee4Category: [],
  listEmployeeFulfill: [],
  highlightAxis16: '',
  highlight4Category: '',
  reportName: '',
  memoContent: {},
  reportFourCategory: [],
  report16CoordinatesCompare: {
    currentRadar: {} as TCurrentRadar,
    radarCompare: {} as TRadarCompare,
  },
  listNumberPageInputMemoCKEditor: {},
  reportAlert: [],
  dataFulfill: [],
  dataFulfill2: [],
  dataFulfill3: [],
  checkBoxPdf: {
    axis16: true,
    axis16Compare: true,
    fourCategories: true,
    redAlertAndYellowAlert: true,
    fulfillment: true,
  },
  filterAxis16Compare: {
    clientIdCompare: '',
    eventIdCompare: '',
    facilityIdCompare: '',
    ageCompare: [],
    dateCompare: [],
    occupationCompare: '',
    roleCompare: '',
    age: [],
    date: [],
    occupation: '',
    role: '',
  },
  filter4Category: {
    age: [],
    date: [],
    occupation: [],
    role: [],
  },
  filterAlert: {
    age: [],
    date: [],
    occupation: [],
    role: [],
  },
  filterFulfillmentState: {
    typePoint: 'work',
    age: [],
    date: [],
    occupation: [],
    role: [],
  },
};

export const getListAnalysis = createAsyncThunk(
  'analysis/getListAnalysis',
  async (params: TOptionsQuery, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    await analysisApi
      .getListAnalysis(params)
      .then((response) => {
        thunkAPI.dispatch(setListAnalysis(response.data.data));
      })
      .catch((response) => {
        thunkAPI.dispatch(
          setShowToast({
            label: getLabelError(response.data.statusCode),
            type: ETypeToast.Error,
          }),
        );
        thunkAPI.dispatch(setLoading(false));
      });
    thunkAPI.dispatch(setLoading(false));
  },
);

export const getListReportClient = createAsyncThunk(
  'analysis/getListReportClient',
  async (params: { id: string }, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    await analysisApi
      .getListReportClient(params.id)
      .then((response) => {
        thunkAPI.dispatch(setListAnalysis(response.data.data));
      })
      .catch((response) => {
        thunkAPI.dispatch(
          setShowToast({
            label: getLabelError(response.data.statusCode),
            type: ETypeToast.Error,
          }),
        );
      });
    thunkAPI.dispatch(setLoading(false));
  },
);

export const getListEventByClientAndFacility = createAsyncThunk(
  'analysis/getListEventByClientAndFacility',
  async (params: { clientId: string; facilityId: string; isEndedEvent: boolean }, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    await analysisApi
      .getListEventByClientAndFacility(params.clientId, params.facilityId, params.isEndedEvent)
      .then((response) => {
        thunkAPI.dispatch(setListEventByClientAndFacility(response.data.data));
      })
      .catch((response) => {
        thunkAPI.dispatch(
          setShowToast({
            label: getLabelError(response?.response?.data?.statusCode ?? ''),
            type: ETypeToast.Error,
          }),
        );
        thunkAPI.dispatch(setLoading(false));
      });
    thunkAPI.dispatch(setLoading(false));
  },
);

export const getListFacilityByClient = createAsyncThunk(
  'analysis/getListFacilityByClient',
  async (clientId: string, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    await thunkAPI.dispatch(getListFacilityManagement({ clientId, isFilter: true }));
    thunkAPI.dispatch(setLoading(false));
  },
);

export const getListFacilityByClientNoFilter = createAsyncThunk(
  'analysis/getListFacilityByClient',
  async (clientId: string, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    await thunkAPI.dispatch(getListFacilityManagement({ clientId, isFilter: false }));
    thunkAPI.dispatch(setLoading(false));
  },
);

export const getListClientInAnalysisReport = createAsyncThunk(
  'analysis/getListClientInAnalysisReport',
  async (_, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    await thunkAPI.dispatch(getListClientManagement({ perPage: 1000, isFilter: true }));
    thunkAPI.dispatch(setLoading(false));
  },
);

export const getAnalysisReport = createAsyncThunk(
  'analysis/getAnalysisReport',
  async (
    params: {
      clientId: { id: string; name: string };
      facilityId: { id: string; name: string };
      eventId: { id: string; name: string };
    },
    thunkAPI,
  ) => {
    thunkAPI.dispatch(setLoading(true));

    await analysisApi
      .getAnalysisReport(params.clientId.id, params.facilityId.id, params.eventId.id)
      .then((response) => {
        thunkAPI.dispatch(setCurrentReport(response.data?.report16Coordinates ?? []));
        thunkAPI.dispatch(setCurrentReportPdf(response.data?.report16Coordinates ?? []));
        thunkAPI.dispatch(setListEmployee(response.data?.report16Coordinates ?? []));
        thunkAPI.dispatch(setFourCategoryReport(response?.data?.report4Category ?? []));
        thunkAPI.dispatch(setListEmployee4Category(response?.data?.report4Category ?? []));
        thunkAPI.dispatch(setDataFulfill(response.data?.reportFulfillment ?? []));
        thunkAPI.dispatch(setListEmployeeFulfill(response.data?.reportFulfillment ?? []));
        thunkAPI.dispatch(setCoordinatesCompareReport(response.data?.report16CoordinatesCompare));
        thunkAPI.dispatch(setAlertReport(response.data?.reportAlert ?? []));
        thunkAPI.dispatch(setOccupations(response.data.occupations));
        thunkAPI.dispatch(setRoles(response.data.roles));
        thunkAPI.dispatch(setIsRenderCreateReport(true));
        thunkAPI.dispatch(setClientSelect(params.clientId));
        thunkAPI.dispatch(setFacilitySelect(params.facilityId));
        thunkAPI.dispatch(setEventSelect(params.eventId));
        saveClientFacilityAndEventSelected(params.clientId, params.facilityId, params.eventId);
      })
      .catch((err) => {
        thunkAPI.dispatch(
          setShowToast({
            label: getLabelError(err?.response?.data?.statusCode ?? ''),
            type: ETypeToast.Error,
          }),
        );
      })
      .finally(() => {
        localStorage.setItem('isReport', 'reported');
      });
    thunkAPI.dispatch(setLoading(false));
  },
);

export const getAnalysisReportOfDetail = createAsyncThunk(
  'analysis/getAnalysisReportOfDetail',
  async (
    params: {
      clientId: { id: string; name: string };
      facilityId: { id: string; name: string };
      eventId: { id: string; name: string };
    },
    thunkAPI,
  ) => {
    thunkAPI.dispatch(setLoading(true));

    await analysisApi
      .getAnalysisReport(params.clientId.id, params.facilityId.id, params.eventId.id)
      .then((response) => {
        thunkAPI.dispatch(setListEmployee(response.data?.report16Coordinates ?? []));
        thunkAPI.dispatch(setListEmployee4Category(response?.data?.report4Category ?? []));
        thunkAPI.dispatch(setListEmployeeFulfill(response.data?.reportFulfillment ?? []));
      })
      .catch((err) => {
        thunkAPI.dispatch(
          setShowToast({
            label: getLabelError(err?.response?.data?.statusCode ?? ''),
            type: ETypeToast.Error,
          }),
        );
      });

    thunkAPI.dispatch(setLoading(false));
  },
);

export const getDetailAnalysisReportFiltered = createAsyncThunk(
  'analysis/getDetailAnalysisReportFiltered',
  async (
    params: {
      date: string[];
      occupation: string[];
      age: string[];
      role: string[];
    },
    thunkAPI,
  ) => {
    thunkAPI.dispatch(setLoading(true));
    await analysisApi
      .getDetailAnalysisReportFiltered(
        (thunkAPI.getState() as RootState).analysis.clientSelect.id,
        (thunkAPI.getState() as RootState).analysis.facilitySelect.id,
        (thunkAPI.getState() as RootState).analysis.eventSelect.id,
        params,
      )
      .then((response) => {
        thunkAPI.dispatch(setCurrentReport(response.data.data));
      })
      .catch((response) => {
        thunkAPI.dispatch(
          setShowToast({
            label: getLabelError(response.data.statusCode),
            type: ETypeToast.Error,
          }),
        );
        thunkAPI.dispatch(setLoading(false));
      });
    thunkAPI.dispatch(setLoading(false));
  },
);

export const saveReport = createAsyncThunk(
  'analysis/saveReport',
  async (
    params: {
      name: string;
      highlight4Category: string;
      highlightAxis16: string;
      axis16Compare: filterCompare;
      axis16: IndexedObject;
      fourCategory: IndexedObject;
      alert: IndexedObject;
      inputMemo1: string;
      inputMemo2: string;
      inputMemo3: string;
      inputMemo4: string;
      inputMemo5: string;
      clientSelect: {
        name: string;
        id: string;
      };
      eventSelect: {
        name: string;
        id: string;
      };
      facilitySelect: {
        name: string;
        id: string;
      };
      fulfillment: {
        date: string[];
        occupation: string[];
        age: string[];
        typePoint: string;
      };
    },
    thunkAPI,
  ) => {
    const dataSave = {
      name: params.name,
      status: EStatusReport.UnPublic,
      clientName: params.clientSelect.name,
      eventName: params.eventSelect.name,
      eventId: params.eventSelect.id,
      facilityId: params.facilitySelect.id,
      facilityName: params.facilitySelect.name,
      clientId: params.clientSelect.id,
      axis16: params.axis16,
      axis16Compare: params.axis16Compare,
      highlight4Category: params.highlight4Category,
      highlightAxis16: params?.highlightAxis16 ?? '',
      inputMemo1: params?.inputMemo1 ?? '',
      inputMemo2: params?.inputMemo2 ?? '',
      inputMemo3: params?.inputMemo3 ?? '',
      inputMemo4: params?.inputMemo4 ?? '',
      inputMemo5: params?.inputMemo5 ?? '',
      fourCategory: params.fourCategory,
      alert: params.alert,
      fulfillment: params.fulfillment,
    };

    thunkAPI.dispatch(setLoading(true));
    await analysisApi
      .saveReport(dataSave)
      .then((res: any) => {
        thunkAPI.dispatch(
          setShowToast({
            label: t(translations.ID_MASTER.SAVE_SUCCESS),
            type: ETypeToast.Success,
          }),
        );
        removeClientFacilityAndEventSelected();
        thunkAPI.dispatch(saveNameReport(params.name));
        thunkAPI.dispatch(setStatusReport(EStatusReport.UnPublic));
        saveReportId(res?.data?.data?._id ?? '');
      })
      .catch((response) => {
        thunkAPI.dispatch(
          setShowToast({
            label: getLabelError(response.data.statusCode),
            type: ETypeToast.Error,
          }),
        );
        thunkAPI.dispatch(setLoading(false));
      })
      .finally(() => {
        thunkAPI.dispatch(setLoading(false));
      });
  },
);

export const editReport = createAsyncThunk(
  'analysis/editReport',
  async (
    params: {
      name: string;
      highlightAxis16: string;
      highlight4Category: string;
      axis16: IndexedObject;
      axis16Compare: filterCompare;
      alert: IndexedObject;
      fourCategory: IndexedObject;
      inputMemo1: string;
      inputMemo2: string;
      inputMemo3: string;
      inputMemo4: string;
      inputMemo5: string;
      clientSelect: {
        name: string;
        id: string;
      };
      eventSelect: {
        name: string;
        id: string;
      };
      facilitySelect: {
        name: string;
        id: string;
      };
      idReport: string | null;
      fulfillment: {
        date: string[];
        occupation: string[];
        age: string[];
        typePoint: string;
      };
    } & { callback?: () => void },
    thunkAPI,
  ) => {
    const dataEdit = {
      name: params.name,
      status: EStatusReport.UnPublic,
      clientName: params.clientSelect.name,
      eventName: params.eventSelect.name,
      eventId: params.eventSelect.id,
      facilityId: params.facilitySelect.id,
      facilityName: params.facilitySelect.name,
      clientId: params.clientSelect.id,
      axis16: params.axis16,
      axis16Compare: params.axis16Compare,
      highlightAxis16: params?.highlightAxis16 ?? '',
      inputMemo1: params?.inputMemo1 ?? '',
      inputMemo2: params?.inputMemo2 ?? '',
      inputMemo3: params?.inputMemo3 ?? '',
      inputMemo4: params?.inputMemo4 ?? '',
      inputMemo5: params?.inputMemo5 ?? '',
      fourCategory: params.fourCategory,
      alert: params.alert,
      highlight4Category: params.highlight4Category,
      fulfillment: params.fulfillment,
    };

    thunkAPI.dispatch(setLoading(true));
    await analysisApi
      .editReport(dataEdit, params.idReport ?? '')
      .then(() => {
        thunkAPI.dispatch(
          setShowToast({
            label: t(translations.ID_MASTER.SAVE_SUCCESS),
            type: ETypeToast.Success,
          }),
        );
      })
      .catch((response) => {
        thunkAPI.dispatch(
          setShowToast({
            label: getLabelError(response.data.statusCode),
            type: ETypeToast.Error,
          }),
        );
        thunkAPI.dispatch(setLoading(false));
      })
      .finally(() => {
        thunkAPI.dispatch(setLoading(false));
      });
    params.callback?.();
  },
);

export const getDetailReport = createAsyncThunk(
  'analysis/saveReport',
  async (params: { id: string; handleErr: (response: any) => void }, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    const user = getUserFromCookie();
    const isClient = user.role === roleUser.clientManager;
    await analysisApi
      .getDetailReport(params.id, isClient)
      .then((response) => {
        thunkAPI.dispatch(setCurrentReport(response.data?.axis16?.data ?? []));
        thunkAPI.dispatch(setCurrentReportPdf(response.data?.axis16?.data ?? []));
        thunkAPI.dispatch(setListEmployee(response.data?.axis16?.data ?? []));
        thunkAPI.dispatch(setFourCategoryReport(response?.data?.fourCategory?.data ?? []));
        thunkAPI.dispatch(setListEmployee4Category(response.data?.fourCategory?.data ?? []));
        thunkAPI.dispatch(setDetailReport(response.data));
        thunkAPI.dispatch(setAlertReport(response.data?.alert ?? []));
        thunkAPI.dispatch(setDataFulfill(response.data?.fulfillment ?? []));
        thunkAPI.dispatch(setListEmployeeFulfill(response.data?.fulfillment ?? []));
        thunkAPI.dispatch(setIsRenderCreateReport(true));
      })
      .catch((response) => {
        params.handleErr(response);
      });

    thunkAPI.dispatch(setLoading(false));
  },
);

export const deleteAnalysisReport = createAsyncThunk(
  'analysis/deleteAnalysisReport',
  async (params: { analysisReportId: string; facilityId: string }, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    await analysisApi
      .deleteAnalysisReport(params.analysisReportId)
      .then(() => {
        thunkAPI.dispatch(
          setShowToast({
            label: t(translations.ID_MASTER.DELETE_SUCCESS),
            type: ETypeToast.Success,
          }),
        );
        thunkAPI.dispatch(
          getListAnalysis({
            facilityId: params.facilityId,
          }),
        );
      })
      .catch((response) => {
        thunkAPI.dispatch(
          setShowToast({
            label: getLabelError(response.data.statusCode),
            type: ETypeToast.Error,
          }),
        );
        thunkAPI.dispatch(setLoading(false));
      });
    thunkAPI.dispatch(setLoading(false));
  },
);

const slice = createSlice({
  name: 'analysis',
  initialState,
  reducers: {
    setListAnalysis: (state, action: PayloadAction<TAnalysis[]>) => {
      state.listAnalysis = action.payload
        .map((item) => {
          return {
            ...item,
            isActive: true,
          };
        })
        .reverse();
    },
    setListEventByClientAndFacility: (state, action: PayloadAction<TImplement[]>) => {
      state.listEventByClientAndFacility = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setIsRenderCreateReport: (state, action: PayloadAction<boolean>) => {
      state.isRenderCreateReport = action.payload;
    },
    setClientSelect: (state, action: PayloadAction<{ id: string; name: string }>) => {
      state.clientSelect = action.payload;
    },
    setFacilitySelect: (state, action: PayloadAction<{ id: string; name: string }>) => {
      state.facilitySelect = action.payload;
    },
    setEventSelect: (state, action: PayloadAction<{ id: string; name: string }>) => {
      state.eventSelect = action.payload;
    },

    setCurrentReport: (state, action: PayloadAction<any>) => {
      state.currentDetailReport = action.payload;
    },
    setCurrentReportPdf: (state, action: PayloadAction<any>) => {
      state.detailReport = action.payload;
    },
    setFourCategoryReport: (state, action: PayloadAction<any>) => {
      state.reportFourCategory = action.payload;
    },
    setCoordinatesCompareReport: (state, action: PayloadAction<any>) => {
      state.report16CoordinatesCompare.currentRadar = action.payload?.currentRadar ?? {};
      state.report16CoordinatesCompare.radarCompare = action.payload?.radarCompare ?? {};
    },
    setListEmployee: (state, action: PayloadAction<any>) => {
      state.listEmployee = action.payload.map((rp, i) => {
        if (i >= action.payload.length - 3) {
          return {
            id: rp?.name ?? '',
            name: textScoreAverage[i - (action.payload.length - 3)],
          };
        } else {
          return {
            id: rp?.id ?? '',
            name: `従業員${i + 1}`,
          };
        }
      });
    },
    setListEmployee4Category: (state, action: PayloadAction<any>) => {
      state.listEmployee4Category = action.payload.map((rp, i) => {
        if (i >= action.payload.length - 3) {
          return {
            id: rp?.name ?? '',
            name: textScoreAverage4Category[i - (action.payload.length - 3)],
          };
        } else {
          return {
            id: rp?.id ?? '',
            name: `従業員${i + 1}`,
          };
        }
      });
    },
    setListEmployeeFulfill: (state, action: PayloadAction<any>) => {
      state.listEmployeeFulfill = action.payload?.data.map((item, i) => {
        return {
          id: item[0]?.employeeId ?? '',
          name: `従業員${i + 1}`,
        };
      });
    },
    setDataFulfill: (state, action: PayloadAction<any>) => {
      state.dataFulfill = action.payload?.data;
      state.dataFulfill2 = action.payload?.dataTable2;
      state.dataFulfill3 = action.payload?.dataTable3;
    },
    setOccupations: (state, action: PayloadAction<any>) => {
      state.occupations = action.payload;
    },
    setRoles: (state, action: PayloadAction<any>) => {
      state.roles = action.payload;
    },
    setDetailReport: (state, action: PayloadAction<any>) => {
      state.loading = true;
      if (action?.payload?.highlightAxis16) {
        const parseHighlightAxis16 = JSON.parse(action?.payload?.highlightAxis16 ?? '');
        state.highlightAxis16 = parseHighlightAxis16;
      }
      if (action?.payload?.highlight4Category) {
        const parseHighlight4Category = JSON.parse(action?.payload?.highlight4Category ?? '');
        state.highlight4Category = parseHighlight4Category;
      }
      state.isRenderCreateReport = true;
      state.reportName = action.payload.name;

      state.clientSelect = {
        id: action.payload?.clientId ?? '',
        name: action.payload?.clientName ?? '',
      };
      state.facilitySelect = {
        id: action.payload?.facilityId ?? '',
        name: action.payload?.facilityName ?? '',
      };
      state.eventSelect = {
        id: action.payload?.eventId ?? '',
        name: action.payload?.eventName ?? '',
      };
      state.ageSelect = action.payload?.filterAxis16?.age ?? '';
      state.dateSelect = action.payload?.filterAxis16?.date ?? '';
      state.occupationSelect = action.payload?.filterAxis16?.occupation ?? '';
      state.roleSelect = action.payload?.filterAxis16?.role ?? '';
      state.memoContent.axis16 = action.payload?.inputMemo1 ?? '';
      state.memoContent.axis16Compare = action.payload?.inputMemo2 ?? '';
      state.memoContent.fourType = action.payload?.inputMemo3 ?? '';
      state.memoContent.redAlertAndYellowAlert = action.payload?.inputMemo4 ?? '';
      state.memoContent.fulfillment = action.payload?.inputMemo5 ?? '';

      state.report16CoordinatesCompare.currentRadar =
        action.payload?.axis16Compare?.currentRadar ?? {};
      state.report16CoordinatesCompare.radarCompare =
        action.payload?.axis16Compare?.radarCompare ?? {};
      state.filterAxis16Compare = action.payload?.filterAxis16Compare;
      state.filter4Category = action.payload?.filter4Category;
      state.filterAlert = action.payload?.filterAlert;
      state.filterFulfillmentState = action.payload?.filterFulfillment;
      state.loading = false;
      state.status = action.payload?.status;
      state.occupations = action.payload?.occupations?.data ?? [];
      state.roles = action.payload?.roles?.data ?? [];
    },
    setDateSelect: (state, action: PayloadAction<string[]>) => {
      state.dateSelect = action.payload;
    },
    setAgeSelect: (state, action: PayloadAction<string[]>) => {
      state.ageSelect = action.payload;
    },
    setOccupationSelect: (state, action: PayloadAction<string[]>) => {
      state.occupationSelect = action.payload;
    },
    setRoleSelect: (state, action: PayloadAction<string[]>) => {
      state.roleSelect = action.payload;
    },
    resetListEventByClientAndFacility: (state) => {
      state.listEventByClientAndFacility = [];
    },
    resetNameReport: (state) => {
      state.reportName = '';
    },
    saveNameReport: (state, action: PayloadAction<string>) => {
      state.reportName = action.payload;
    },
    resetSelect: (state) => {
      state.ageSelect = [];
      state.dateSelect = [];
      state.occupationSelect = [];
      state.roleSelect = [];
    },
    setListNumberPageInputMemoCKEditor: (state, action: PayloadAction<TNumberPageMemo>) => {
      state.listNumberPageInputMemoCKEditor = action.payload;
    },
    setAlertReport: (state, action: PayloadAction<TReportAlert[]>) => {
      state.reportAlert = action.payload;
    },
    setCheckBoxPdf: (state, action: PayloadAction<keyof typeof state.checkBoxPdf>) => {
      state.checkBoxPdf[action.payload] = !state.checkBoxPdf[action.payload];
    },

    resetSelectedDetail: (state) => {
      state.clientSelect = { id: '', name: '' };
      state.facilitySelect = { id: '', name: '' };
      state.eventSelect = { id: '', name: '' };
    },

    resetData: (state) => {
      state.highlightAxis16 = '';
      state.highlight4Category = '';
      state.currentDetailReport = [];
      state.filter4Category = {
        age: [],
        date: [],
        occupation: [],
        role: [],
      };
      state.filterAlert = {
        age: [],
        date: [],
        occupation: [],
        role: [],
      };
      state.filterAxis16Compare = {
        clientIdCompare: '',
        eventIdCompare: '',
        facilityIdCompare: '',
        ageCompare: [],
        dateCompare: [],
        occupationCompare: '',
        roleCompare: '',
        age: [],
        date: [],
        occupation: '',
        role: '',
      };
      state.detailReport = [];
      state.reportName = '';
      state.reportFourCategory = [];
      state.listEmployee4Category = [];
      state.listEmployee = [];
      state.report16CoordinatesCompare = {
        currentRadar: {} as TCurrentRadar,
        radarCompare: {} as TRadarCompare,
      };
      state.reportAlert = [];
      state.status = EStatusReport.Public;
    },
    setStatusReport: (state, action: PayloadAction<EStatusReport>) => {
      state.status = action.payload;
    },
  },
});

export const { actions, reducer: AnalysisReducer } = slice;
export const {
  setLoading,
  setListAnalysis,
  setListEventByClientAndFacility,
  setIsRenderCreateReport,
  setCurrentReport,
  setEventSelect,
  setFacilitySelect,
  setClientSelect,
  setOccupations,
  setRoles,
  setDetailReport,
  setListEmployee,
  resetListEventByClientAndFacility,
  resetNameReport,
  setCoordinatesCompareReport,
  setFourCategoryReport,
  setListEmployee4Category,
  saveNameReport,
  resetSelect,
  setListNumberPageInputMemoCKEditor,
  setAlertReport,
  setCheckBoxPdf,
  setCurrentReportPdf,
  resetData,
  setDataFulfill,
  setListEmployeeFulfill,
  setStatusReport,
  resetSelectedDetail,
} = actions;
export default AnalysisReducer;
