import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { comApi } from '../../api/comApi';
import { TClientManagement } from '../../types/clientManagement/clientManagement';
import {
  DEFAULT_PAGE,
  DEFAULT_PER_PAGE,
  TActionParams,
  TCallback,
  TOptionsQuery,
} from '../../types/common';
import { setShowToast } from '../toast/toastSlice';
import { ETypeToast } from '../../app/enum';
import { TSetListAction } from 'types/types';
import { TComManagement, TSufficiency } from '../../types/comManagement/comManagement';
import { t } from 'i18next';
import { translations } from '../../locales/translations';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  deleteUser,
} from 'firebase/auth';
import { auth, db } from '../../services/firebase';
import {
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';
import { convertValueToNumber, getLabelError } from '../../helpers/funcs';
import { statusErrEmail } from '../../helpers/constant';

export type ComSlice = {
  loading: boolean;
  listComManagement: Array<TComManagement>;
  total: number;
  totalPage: number;
  page: number;
  sufficiency: Array<TSufficiency>;
  memo: any;
  infoCom: TComManagement;
  emailErr: string;
  currentLoad: boolean;
};

const initialState: ComSlice = {
  loading: false,
  listComManagement: [],
  total: 0,
  totalPage: 1,
  page: 1,
  sufficiency: [],
  memo: null,
  infoCom: {},
  emailErr: '',
  currentLoad: false,
};

export const getListSufficiency = createAsyncThunk(
  'com/getListSufficiency',
  async (employeeId: string, thunkAPI) => {
    const newParams: any = {
      employeeId: employeeId,
      perPage: DEFAULT_PER_PAGE,
      page: DEFAULT_PAGE,
    };
    thunkAPI.dispatch(setLoading(true));
    await comApi
      .getListSufficiency(newParams)
      .then((response) => {
        thunkAPI.dispatch(setSufficiency(response.data.data));
        thunkAPI.dispatch(setMemo(response.data._metadata.employee));
      })
      .catch((response) => {
        thunkAPI.dispatch(
          setShowToast({
            label: getLabelError(response.response.data.statusCode),
            type: ETypeToast.Error,
          }),
        );
        thunkAPI.dispatch(setLoading(false));
      });
    thunkAPI.dispatch(setLoading(false));
  },
);

export const updateValueMemo = createAsyncThunk(
  'com/updateValueMemo',
  async (data: any, thunkAPI) => {
    await comApi
      .updateValueMemo(data)
      .then(() => {
        thunkAPI.dispatch(getListSufficiency(data.employeeId));
        thunkAPI.dispatch(
          setShowToast({
            label: t(translations.ID_MASTER.UPDATE_SUCCESS),
            type: ETypeToast.Success,
          }),
        );
      })
      .catch((response) => {
        thunkAPI.dispatch(
          setShowToast({
            label: getLabelError(response.response.data.statusCode),
            type: ETypeToast.Error,
          }),
        );
        thunkAPI.dispatch(setLoading(false));
      });
    thunkAPI.dispatch(setLoading(false));
  },
);

export const getListComManagement = createAsyncThunk(
  'com/getListComManagement',
  async (params: TOptionsQuery<TClientManagement>, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    const { loadMore, ...rest } = params;
    const newParams: TOptionsQuery<TClientManagement> = {
      ...rest,
      orderBy: rest.orderBy ?? 'createdAt',
      orderDirection: rest.orderDirection ?? 'desc',
      page: rest.page ?? DEFAULT_PAGE,
      perPage: rest.perPage ?? DEFAULT_PER_PAGE,
    };
    await comApi
      .getListComManagement(newParams)
      .then((response) => {
        thunkAPI.dispatch(
          setListComManagement({
            type: loadMore ? 'loadMore' : 'getList',
            listCom: response.data.data.map((item) => ({
              ...item,
              key: item._id,
            })),
            total: response.data._metadata.pagination.total,
            totalPage: response.data._metadata.pagination.totalPage,
          }),
        );
        thunkAPI.dispatch(setLoading(false));
      })
      .catch((response) => {
        thunkAPI.dispatch(
          setShowToast({
            label: getLabelError(response.response.data.statusCode),
            type: ETypeToast.Error,
          }),
        );
        thunkAPI.dispatch(setLoading(false));
      });
  },
);

export const registerComManagement = createAsyncThunk(
  'com/registerComManagement',
  async (
    params: { actionParams: TActionParams<TComManagement>; handleClose: () => void },
    thunkAPI,
  ) => {
    thunkAPI.dispatch(setLoading(true));
    await comApi
      .registerCom(params.actionParams.data)
      .then(async () => {
        params.handleClose();
        thunkAPI.dispatch(resetListComManagement());
        thunkAPI.dispatch(setCurrentLoad(true));
        thunkAPI.dispatch(setPage(1));
        thunkAPI.dispatch(
          setShowToast({
            label: t(translations.ID_MASTER.REGISTER_SUCCESS),
            type: ETypeToast.Success,
          }),
        );
      })
      .catch((response) => {
        if (statusErrEmail.includes(response.response.data.statusCode)) {
          thunkAPI.dispatch(setEmailErr('このメールアドレスが使用されています。'));
        } else {
          thunkAPI.dispatch(
            setShowToast({
              label: getLabelError(response.response.data.statusCode),
              type: ETypeToast.Error,
            }),
          );
        }
        thunkAPI.dispatch(setLoading(false));
      });
  },
);
//
export const updateComManagement = createAsyncThunk(
  'com/updateComManagement',
  async (
    params: { id: string; data: TActionParams<TComManagement>; handleClose: () => void },
    thunkAPI,
  ) => {
    thunkAPI.dispatch(setLoading(true));
    await comApi
      .updateCom({ id: params.id, data: params.data.data })
      .then((response: any) => {
        thunkAPI.dispatch(
          setListComManagement({
            type: 'edit',
            com: { ...params.data.data, _id: response.data.data._id },
          }),
        );
        thunkAPI.dispatch(
          setShowToast({
            label: t(translations.ID_MASTER.UPDATE_SUCCESS),
            type: ETypeToast.Success,
          }),
        );
        const docRef = doc(db, 'user', localStorage.getItem('currentUid') ?? '');
        updateDoc(docRef, {
          displayName: params?.data?.data?.name,
        })
          .then()
          .catch((e) => {});
        params.handleClose();
      })
      .catch((response) => {
        if (statusErrEmail.includes(response.response.data.statusCode)) {
          thunkAPI.dispatch(setEmailErr('このメールアドレスが使用されています。'));
        } else {
          thunkAPI.dispatch(
            setShowToast({
              label: getLabelError(response.response.data.statusCode),
              type: ETypeToast.Error,
            }),
          );
        }
        thunkAPI.dispatch(setLoading(false));
      });
    thunkAPI.dispatch(setLoading(false));
  },
);
//
export const stopComManagement = createAsyncThunk(
  'com/stopComManagement',
  async (com: TComManagement, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    if (com.isActive) {
      await comApi
        .inActiveCom(String(com.userId))
        .then(() => {
          thunkAPI.dispatch(setListComManagement({ type: 'stop', com: com }));
          thunkAPI.dispatch(
            setShowToast({
              label: t(translations.ID_MASTER.INACTIVE_SUCCESS),
              type: ETypeToast.Success,
            }),
          );
        })
        .catch((response) => {
          thunkAPI.dispatch(
            setShowToast({
              label: getLabelError(response.response.data.statusCode),
              type: ETypeToast.Error,
            }),
          );
          thunkAPI.dispatch(setLoading(false));
        });
    } else {
      await comApi
        .activeCom(String(com.userId))
        .then(() => {
          thunkAPI.dispatch(setListComManagement({ type: 'stop', com: com }));
          thunkAPI.dispatch(
            setShowToast({
              label: t(translations.ID_MASTER.ACTIVE_SUCCESS),
              type: ETypeToast.Success,
            }),
          );
        })
        .catch((response) => {
          thunkAPI.dispatch(
            setShowToast({
              label: getLabelError(response.response.data.statusCode),
              type: ETypeToast.Error,
            }),
          );
          thunkAPI.dispatch(setLoading(false));
        });
    }
    thunkAPI.dispatch(setLoading(false));
  },
);

export const deleteComManagement = createAsyncThunk(
  'com/deleteComManagement',
  async (com: TComManagement, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    await comApi
      .deleteCom(String(com._id))
      .then(() => {
        thunkAPI.dispatch(setListComManagement({ type: 'delete', com: com }));
        thunkAPI.dispatch(
          setShowToast({
            label: t(translations.ID_MASTER.DELETE_SUCCESS),
            type: ETypeToast.Success,
          }),
        );
      })
      .catch((response) => {
        thunkAPI.dispatch(
          setShowToast({
            label: getLabelError(response.response.data.statusCode),
            type: ETypeToast.Error,
          }),
        );
        thunkAPI.dispatch(setLoading(false));
      });

    try {
      if (com.email) {
        const userCredential = await signInWithEmailAndPassword(auth, com.email, '123456');
        const user = userCredential.user;
        await deleteUser(user);
      }
    } catch (error) {}

    thunkAPI.dispatch(setLoading(false));
  },
);

export const inviteComManagement = createAsyncThunk(
  'com/inviteComManagement',
  async (id: string, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    await comApi
      .inviteCom(id)
      .then(() => {
        thunkAPI.dispatch(
          setShowToast({
            label: '招待メールが送信されました。',
            type: ETypeToast.Success,
          }),
        );
        thunkAPI.dispatch(getListComManagement({}));
      })
      .catch((response) => {
        thunkAPI.dispatch(
          setShowToast({
            label: getLabelError(response.response.data.statusCode),
            type: ETypeToast.Error,
          }),
        );
        thunkAPI.dispatch(setLoading(false));
      });
    thunkAPI.dispatch(setLoading(false));
  },
);

export const getInfoCom = createAsyncThunk('client/getInfoCom', async (id: string) => {
  const response = await comApi.getInfoCom(id);
  return response.data.data;
});

export const updateInfoCom = createAsyncThunk(
  'com/updateInfoCom',
  async (
    params: { id: string; data: TComManagement; handleClose: () => void } & TCallback,
    thunkAPI,
  ) => {
    thunkAPI.dispatch(setLoading(true));
    await comApi
      .updateInfoCom(params.id, params.data)
      .then(() => {
        params.callback?.();
        thunkAPI.dispatch(getInfoCom(params.id));
        thunkAPI.dispatch(
          updateComManagement({
            id: params.id,
            data: { data: { ...params.data, isActive: params.data.isActive } },
            handleClose: params.handleClose,
          }),
        );
        thunkAPI.dispatch(
          setShowToast({
            label: t(translations.ID_MASTER.UPDATE_SUCCESS),
            type: ETypeToast.Success,
          }),
        );

        const docRef = doc(db, 'user', localStorage.getItem('currentUid') ?? '');
        updateDoc(docRef, {
          displayName: params?.data?.name,
        })
          .then()
          .catch((e) => {});
      })
      .catch((response) => {
        if (statusErrEmail.includes(response.response.data.statusCode)) {
          thunkAPI.dispatch(setEmailErr('このメールアドレスが使用されています。'));
        } else {
          thunkAPI.dispatch(
            setShowToast({
              label: getLabelError(response.response.data.statusCode),
              type: ETypeToast.Error,
            }),
          );
        }
        thunkAPI.dispatch(setLoading(false));
      });
    thunkAPI.dispatch(setLoading(false));
  },
);

const slice = createSlice({
  name: 'com',
  initialState,
  reducers: {
    setMemo: (state, action: PayloadAction<Array<TSufficiency>>) => {
      state.memo = action.payload;
    },
    setSufficiency: (state, action: PayloadAction<Array<TSufficiency>>) => {
      state.sufficiency = action.payload;
    },
    setListComManagement: (
      state,
      action: PayloadAction<{
        type: TSetListAction;
        com?: TComManagement;
        listCom?: TComManagement[];
        totalPage?: number;
        total?: number;
      }>,
    ) => {
      const { type, com, listCom, total, totalPage } = action.payload;

      if (com) {
        switch (type) {
          case 'create':
            state.listComManagement = [{ ...com, isActive: true }, ...state.listComManagement];
            break;
          case 'edit':
            state.listComManagement = state.listComManagement.map((c: TComManagement) =>
              c._id === com._id ? com : c,
            );
            break;
          case 'delete':
            state.listComManagement = state.listComManagement.filter(
              (c: TComManagement) => c._id !== com._id,
            );
            state.total = state.total - 1;
            break;
          case 'stop':
            state.listComManagement = state.listComManagement.map((c: TComManagement) =>
              c._id === com._id ? { ...com, isActive: !com.isActive } : c,
            );
        }
      }
      if (listCom) {
        switch (type) {
          case 'getList':
            state.listComManagement = listCom;
            break;
          case 'loadMore':
            state.listComManagement = [...state.listComManagement, ...listCom];
            break;
        }

        state.totalPage = convertValueToNumber(totalPage);
        state.total = convertValueToNumber(total);
      }
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setEmailErr: (state, action: PayloadAction<string>) => {
      state.emailErr = action.payload;
    },
    resetListComManagement: (state) => {
      state.listComManagement = [];
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setCurrentLoad: (state, action: PayloadAction<boolean>) => {
      state.currentLoad = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getInfoCom.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getInfoCom.fulfilled, (state, action: PayloadAction<TComManagement>) => {
      state.loading = false;
      state.infoCom = action.payload;
    });
  },
});

export const { actions, reducer: ComManagementReducer } = slice;
export const {
  setListComManagement,
  setLoading,
  setSufficiency,
  setMemo,
  setEmailErr,
  resetListComManagement,
  setPage,
  setCurrentLoad,
} = actions;
export default ComManagementReducer;
