import React, { ReactNode } from 'react';
import { Dropdown, MenuProps } from 'antd';

type Props = {
  children: ReactNode;
  items: MenuProps['items'];
  minWidth?: number;
  open?: boolean;
  overlayClassName?: string;
  disabled?: boolean;
};

const CommonDropdown: React.FC<Props> = (props: Props) => {
  const { children, items, minWidth = 200, open, overlayClassName, disabled } = props;
  return (
    <Dropdown
      menu={{ items }}
      trigger={['click']}
      overlayStyle={{ minWidth: minWidth ? `${minWidth}px` : 'auto' }}
      open={open}
      overlayClassName={overlayClassName}
      disabled={disabled ?? false}
    >
      {children}
    </Dropdown>
  );
};

export default CommonDropdown;
