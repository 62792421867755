import React, { ReactNode } from 'react';
import { Select } from 'antd';
import { arrowDropDownIcon } from '../components/Icons/icons';
import { translations } from '../../locales/translations';
import { useTranslation } from 'react-i18next';

interface Props {
  options: Array<{ value: string; label: string }>;
  className?: string;
  defaultValue?: string;
  arrowIcon?: ReactNode;
  onChangeValue?: (value: any) => void;
  onClear?: () => void;
  onDeselect?: (value: any) => void;
  onBlur?: () => void;
  placeholder?: string;
  disabled?: boolean;
  value?: string | string[];
  label?: string;
  isSelected?: boolean;
  titleSelected?: string;
  valueSelected?: string;
  multiple?: boolean;
  allowClear?: boolean;
}

const CommonSelect: React.FC<Props> = (props: Props) => {
  const {
    options,
    className,
    value,
    defaultValue,
    arrowIcon,
    onChangeValue,
    placeholder,
    disabled,
    label,
    isSelected,
    valueSelected,
    titleSelected,
    multiple,
    onBlur,
    allowClear,
    onClear,
    onDeselect,
  } = props;
  const { t } = useTranslation();

  const Search = (
    <Select
      mode={multiple ? 'multiple' : undefined}
      allowClear={allowClear}
      options={options}
      defaultValue={defaultValue}
      value={value}
      onDeselect={onDeselect}
      onClear={onClear}
      onBlur={onBlur}
      onChange={(value) => {
        onChangeValue?.(value);
      }}
      className={`h-10 w-full border-slate-gray rounded leading-tight ${className}`}
      suffixIcon={<div>{arrowIcon ?? arrowDropDownIcon}</div>}
      placeholder={placeholder}
      disabled={disabled ?? false}
      notFoundContent={t(translations.COMMON.NO_DATA)}
    />
  );

  return (
    <>
      {isSelected ? (
        <div className="flex border border-[#B9B9B9] rounded-lg  px-3 py-1 bg-[#F5F5F5] items-center text-base">
          <p className="px-2 py-1 border-r border-[#B9B9B9]">{titleSelected}</p>
          <p className="px-2 py-1">{valueSelected}</p>
        </div>
      ) : (
        <>
          {label ? (
            <div>
              {label && (
                <label className="flex gap-2 items-center font-normal mb-1">
                  <span className="text-black text-sm/normal font-normal">{label}</span>
                </label>
              )}
              {Search}
            </div>
          ) : (
            <>{Search}</>
          )}
        </>
      )}
    </>
  );
};

export default CommonSelect;
