import { ReactNode } from 'react';
import { TFunction } from 'react-i18next';
import { NavigateFunction } from 'react-router-dom';

export type IndexedObject<V = any> = { [k: string]: V };

export type PropsLayout = {
  children?: ReactNode;
  EPath?: string;
  isScroll?: boolean;
};

export type TAction<T> = {
  type: string;
  payload: IndexedObject<T>;
};

export type Locale = { code: string; name: string; bidi: boolean };

export type TOptionSelect = {
  label: string;
  value: string;
};

export type TOption<T = string | number> = {
  label: string;
  value?: T;
};

export type Role = 'ROLE_USER' | 'ROLE_ADMIN';

export type TokenObject = {
  token: string;
  expires: Date;
};

export type TPagination = {
  perPage: number;
  page: number;
};

export type TResponsePagination<T = IndexedObject[]> = TPagination & {
  results: T;
};

export type Size = 's' | 'm' | 'l' | 'xl' | 'auto';

export type TActionParams<T> = {
  navigate?: NavigateFunction;
  t?: TFunction<'translation', undefined>;
  funct?: () => void;
  data: T;
};

export type TOptionsQuery<T = IndexedObject> = {
  page?: number;
  search?: string;
  orderBy?: string;
  orderDirection?: string;
  perPage?: number;
  loadMore?: boolean;
  clientId?: string;
  facilityId?: string;
  eventId?: string;
  totalPage?: number;
} & T;

export const isEmptyObject = (obj: IndexedObject) => {
  if (obj.constructor === Object && Object.keys(obj).length === 0) {
    return true;
  }
  return JSON.stringify(obj) === JSON.stringify({});
};
export const DEFAULT_PER_PAGE = 30;
export const DEFAULT_PER_PAGE_EMPLOYEE = 100;
export const DEFAULT_PER_PAGE_ALL = 9999;
export const DEFAULT_PAGE = 1;

export const roleUser = {
  com: 'com',
  clientManager: 'client_manager',
  consultant: 'consultant',
  employee: 'employee',
};

export const defaultDate = 'YYYY/MM/DD';

export const formatTime = 'HH:mm';

export const formatDateTimeChat = 'YYYY/MM/DD HH:mm';

export const regexPhoneNumber = /^\d{10,11}$/;
export const regexEmail = /^[a-zA-Z0-9._%+-]+[^ぁ-んァ-ン一-龯\s]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const regexPassword = /^\d{8,}$/;

export const replaceHTML = {
  searchValue: /<a\s+(?!.*\btarget\b).*?(href=".*?").*?>/gi,
  replaceValue: '<a target="_blank" $1 class="custom-a">',
};

export type TCallback = { callback?: (data?: any) => void };
