import { axiosClient } from './axiosClient';
import { TFCMToken, TLogin } from '../types/user/user';

const authApi = {
  sendOneTimePassword: (data: { email: string }) => {
    const url = '/public/user/send-onetime-password';
    return axiosClient.post(url, data);
  },

  login: (data: TLogin) => {
    const url = '/public/user/login';
    return axiosClient.post(url, data);
  },

  updateFcmTokenUser: (data: TFCMToken) => {
    const url = '/public/user/update-fcm-token-user';
    return axiosClient.post(url, data);
  },
};

export default authApi;
