import * as React from 'react';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ConfigProvider } from 'antd';
import './globals.scss';
import './scss/override.antds.scss';
import { QueryClient, QueryClientProvider } from 'react-query';
import moment from 'moment';
import RenderRoutes, { routes } from './routes/routes';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'types/RootState';
import { getCurrentUser, setAuth, updateFcmTokenUser } from 'store/auth/authSlice';
import Toast from './common/UIParts/Toast';
import {
  getRefreshTokenFromCookie,
  getTokenFromCookie,
  getUserFromCookie,
  getLanguage,
  saveLanguage,
} from 'helpers/cookies';
import { generateToken, messaging } from '../services/firebase';
import { onMessage } from 'firebase/messaging';

// Set language + moment
const language = getLanguage();
saveLanguage(language);
moment.locale(language);

const ConfigProviderCustom = ConfigProvider as any;

export function App() {
  const { i18n } = useTranslation();
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: true,
        refetchOnReconnect: false,
        staleTime: 10 * 60 * 1000,
      },
    },
  });

  const dispatch = useDispatch();
  const { authenticated, currentUser } = useSelector((state: RootState) => state.auth);
  let checkActiveTab = true;

  const localUser = getUserFromCookie();
  const localToken = getTokenFromCookie();
  const localRefreshToken = getRefreshTokenFromCookie();
  const checkAuthLocal = !!(localToken && localRefreshToken && localUser);

  useEffect(() => {
    if (checkAuthLocal) {
      dispatch(setAuth());
    }
  }, [checkAuthLocal]);

  useEffect(() => {
    authenticated && checkAuthLocal && dispatch(getCurrentUser());
  }, [authenticated, checkAuthLocal]);

  const updateFcmToken = async () => {
    const token = await generateToken();
    if (token && (currentUser.com || currentUser.consultant)) {
      dispatch(
          updateFcmTokenUser({
            fcmToken: token,
            email: currentUser.com?.email ?? currentUser.consultant?.email,
          }),
      );
    }
  };

  const callback = () => {
    checkActiveTab = document.visibilityState === 'visible';
  };

  useEffect(() => {
    Object.keys(currentUser).length > 0 && updateFcmToken();
  }, [currentUser]);

  useEffect(() => {
    document.addEventListener('visibilitychange', callback);

    return () => {
      document.removeEventListener('visibilitychange', callback);
    };
  }, []);

  useEffect(() => {
    if (messaging) {
      onMessage(messaging, (payload) => {
        if (checkActiveTab) {
          new Notification(payload.notification?.title ?? '', {
            body: payload.notification?.body,
            icon: payload.notification?.image,
          });
        }
      });
    }
  }, []);

  const isAuthenticated = !!(authenticated && currentUser && Object.keys(currentUser).length);

  return (
      <ConfigProviderCustom
          theme={{
            token: {
              colorPrimary: '#ff4218',
            },
          }}
      >
        <BrowserRouter>
          <Helmet
              titleTemplate="%s - きゃりこん"
              defaultTitle="きゃりこん"
              htmlAttributes={{ lang: i18n.language }}
          >
            <meta name="description" content="きゃりこん (.com)" />
          </Helmet>

          <QueryClientProvider client={queryClient}>
            <Toast />
            <RenderRoutes
                routes={routes}
                isAuthenticated={isAuthenticated || checkAuthLocal}
                currentUser={localUser}
            />
          </QueryClientProvider>
        </BrowserRouter>
      </ConfigProviderCustom>
  );
}
