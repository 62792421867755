import { Form, Input, Typography } from 'antd';
import type { ChangeEvent } from 'react';
import React from 'react';
import { translations } from '../../../locales/translations';
import { useTranslation } from 'react-i18next';

interface Props {
  className?: string;
  placeholder?: string;
  id?: string;
  type?: string;
  value?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  label?: string;
  name?: string;
  requiredLabel?: boolean;
  rules?: { required: boolean; message: string };
  listRules?: Array<any>;
  regex?: any;
  email?: boolean;
  readOnly?: boolean;
  disable?: boolean;
  rulesValidatorRequiredIfNotEmpty?: boolean;
  validatorRequiredIfNotEmpty?: any;
  autoFocus?: boolean;
  customObjValidation?: {}[];
}

const InputCommon: React.FC<Props> = (props) => {
  const {
    className,
    type = 'string',
    placeholder,
    value,
    onChange,
    required,
    label,
    name,
    requiredLabel,
    rules,
    regex,
    email,
    disable,
    readOnly,
    rulesValidatorRequiredIfNotEmpty,
    validatorRequiredIfNotEmpty,
    autoFocus,
    customObjValidation = [{}],
  } = props;

  const { t } = useTranslation();

  return (
    <div className={`flex-1 ${className}`}>
      <label className="flex gap-3 items-center font-bold mb-3">
        <span className="text-dark-charcoal text-base font-bold leading-tight">{label}</span>
        {requiredLabel && (
          <Typography.Text type="danger" className="!text-maroon">
            {t(translations.COMMON.REQUIRED)}
          </Typography.Text>
        )}
      </label>
      <Form.Item
        name={name ?? ''}
        rules={
          rulesValidatorRequiredIfNotEmpty
            ? [{ validator: validatorRequiredIfNotEmpty }]
            : [
                email
                  ? { type: 'email', message: t(translations.ID_MASTER.MESSAGE_VALIDATE.EMAIL) }
                  : {},
                { required: rules?.required, message: regex ? '' : rules?.message },
                rules?.required
                  ? {
                      pattern: /^(?!\s+$).+/,
                      message: t(translations.ID_MASTER.MESSAGE_VALIDATE.REQUIRE),
                    }
                  : {},
                {
                  validator: async (_, value) => {
                    if (regex && !regex.test(value)) {
                      return Promise.reject(new Error(rules?.message));
                    }
                  },
                },
                ...customObjValidation,
              ]
        }
      >
        <Input
          type={type}
          placeholder={placeholder}
          defaultValue={value}
          className={`border h-10 rounded w-full py-2 px-3 leading-tight focus:!border-slate-gray hover:!border-slate-gray hover:!shadow-none focus:!shadow-none border-solid border-1 appearance-none border-slate-gray !moz-appearance !webkit-appearance`}
          onChange={(e) => {
            onChange?.(e);
          }}
          required={required}
          disabled={disable}
          readOnly={readOnly}
          autoFocus={autoFocus ?? false}
        />
      </Form.Item>
    </div>
  );
};
export default InputCommon;
