import React, { ReactNode, useEffect, useState } from 'react';
import SideBar from './SideBar';
import MyHeader from './Header';
import { useLocation } from 'react-router-dom';
import { EPath } from '../../routes/routesConfig';
import { getUserFromCookie } from '../../../helpers/cookies';
import { Layout } from 'antd';

const { Content, Footer, Sider, Header } = Layout;

type Props = {
  children?: ReactNode;
};
export const MyLayout: React.FC<Props> = (props: Props) => {
  const location = useLocation();
  const { children } = props;
  const [isEmployee, setIsEmployee] = useState(false);
  const pathNameScroll = [
    EPath.Account_setting,
    EPath.interviews,
    EPath.Interview_Management,
    EPath.CareerMemoRegister,
    EPath.comparisonAxis,
  ];

  const scroll = () => {
    if (
      pathNameScroll.includes(location.pathname) ||
      location.pathname.includes('/interview-management/')
    )
      return 'overflow-y-scroll';
    return '';
  };

  useEffect(() => {
    const user = getUserFromCookie();

    if (user.role === 'employee' && !user?.employee?.isManager) {
      setIsEmployee(true);
    } else {
      setIsEmployee(false);
    }
  }, []);

  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setCollapsed(window.innerWidth < 1280);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    // Clean up
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (location.pathname === '/implements') {
      localStorage.removeItem('my-infinite-scroll-position');
    }
    if (!location.pathname.includes('implements')) {
      localStorage.removeItem('my-infinite-scroll-position');
    }
  }, [location]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem('my-infinite-scroll-position');
    };

    const uuidRegex =
      /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

    const pathParts = location.pathname.split('/');

    if (pathParts[1] === 'implements' && uuidRegex.test(pathParts[2])) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }

    // Clean up
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return (
    <>
      {isEmployee ? (
        <>{children}</>
      ) : (
        <Layout>
          <MyHeader />
          <Layout>
            <Sider
              width={198}
              collapsible={window.innerWidth < 1280}
              onCollapse={(e) => {
                setCollapsed(!collapsed);
              }}
              collapsed={collapsed}
              collapsedWidth="0"
              className={'bg-transperent'}
            >
              <SideBar />
            </Sider>
            <Layout style={{ background: '#fffbf8' }}>
              <Content style={{ margin: '24px 16px 0' }}>{children}</Content>
            </Layout>
          </Layout>
        </Layout>
      )}
    </>
  );
};
