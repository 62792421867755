/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from '@reduxjs/toolkit';

import { InjectedReducersType } from 'utils/types/injector-typings';
import toastReducer from './toast/toastSlice';
import authReducer from './auth/authSlice';
import ClientManagementReducer from './client/clientSlice';
import ManagerManagementReducer from './manager/managerSlice';
import EmployeeManagementReducer from './employee/employeeSlice';
import ComManagementReducer from './com/comSlice';
import InterviewerManagementReducer from './interviewer/interviewerSlice';
import ImplementReducer from './implements/implementsSlice';
import { OmoteuraReducer } from './omoteura/omoteuraSlice';
import { ConsultantInterviewReducer } from './consultantInterview/consultantInterview';
import CareerMemoManagementReducer from './careerMemo/careerMemoSlice';
import AnalysisReducer from './analysis/analysisSlice';
import EmployeeWebReducer from './employeeWeb/employeeWebSlice';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */

export const rootReducers = {
  auth: authReducer,
  toast: toastReducer,
  client: ClientManagementReducer,
  manager: ManagerManagementReducer,
  interviewer: InterviewerManagementReducer,
  employee: EmployeeManagementReducer,
  com: ComManagementReducer,
  implement: ImplementReducer,
  omoteura: OmoteuraReducer,
  consultantInterview: ConsultantInterviewReducer,
  careerMemo: CareerMemoManagementReducer,
  analysis: AnalysisReducer,
  employeeWeb: EmployeeWebReducer,
};
export function createReducer(injectedReducers: InjectedReducersType = {}) {
  // Initially we don't have any injectedReducers, so returning identity function to avoid the error
  if (Object.keys(injectedReducers).length === 0) {
    return (state: any) => state;
  } else {
    return combineReducers({
      ...injectedReducers,
    });
  }
}
