import IconCommonStyle from 'app/components/Icons/IconCommonStyle';
import { EMenu } from 'app/enum';
import { EPath } from 'app/routes/routesConfig';
import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import UnreadIcon from '../../../assets/icon/unread.svg';
import useService from '../Chat/service';
import { getUserFromCookie } from '../../../helpers/cookies';
import packageJson from '../../../../package.json';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from 'services/firebase';
import { axiosClient } from 'api/axiosClient';

export const sendEmailForConsultant = async (msg: string, email: string) => {
  await axiosClient.post('public/user/send-msg-email', {
    msg,
    email,
  });
};

const SideBar: React.FC = () => {
  const currentUser = getUserFromCookie();
  const { checkUnread, listDataGroup, listDirectChat, currentUserChat, checkGroupWithCom } =
    useService();

  useEffect(() => {
    checkGroupWithCom();
  }, [currentUserChat]);

  useEffect(() => {
    if (Object.keys(currentUserChat).length && !currentUserChat?.role) {
      const docRef = doc(db, 'user', currentUserChat?.uid);
      updateDoc(docRef, {
        role: getUserFromCookie()?.role ?? 'unknown',
      });
    }
    localStorage.setItem('currentUid', currentUserChat?.uid);
  }, [currentUserChat]);

  return (
    <div
      className={`flex flex-col !min-w-[198px] w-[198px] bg-white shadow-16x-right h-screen relative`}
    >
      <div className="flex flex-col bg-white">
        {!currentUser.consultant && (
          <NavLink
            to={EPath.implements}
            onClick={() => localStorage.removeItem('my-infinite-scroll-position')}
          >
            <div className="text-lg/[22px] font-normal text-text p-4 border-b border-light-gray">
              {EMenu.Implementation_times_list}
            </div>
          </NavLink>
        )}
        {currentUser.consultant && (
          <NavLink to={EPath.interviews}>
            <div className="text-lg/[22px] font-normal text-text p-4 border-b border-light-gray">
              {EMenu.myPage}
            </div>
          </NavLink>
        )}
        {(currentUser.consultant || currentUser.com) && (
          <NavLink to={EPath.message}>
            <div className="text-lg/[22px] font-normal text-text p-4 border-b border-light-gray flex justify-between">
              <span>{EMenu.message}</span>
              {(listDataGroup.filter((group: any) => checkUnread(group.uid)).length > 0 ||
                listDirectChat.filter((direct: any) => checkUnread(direct.uid)).length > 0 ||
                listDataGroup
                  .map((x) => {
                    return x?.membersUnread?.includes(currentUserChat.uid);
                  })
                  .filter((x) => x)
                  .includes(true)) && (
                <img src={UnreadIcon} alt="" className="w-3 h-3 my-auto animate-bounce" />
              )}
            </div>
          </NavLink>
        )}
        {currentUser.consultant && (
          <>
            <NavLink to={EPath.Interview_Management}>
              <div className="text-lg/[22px] font-normal text-text p-4 border-b border-light-gray">
                {EMenu.interview_management}
              </div>
            </NavLink>
            <NavLink
              to={'https://www.notion.so/1403293d56b849b3aba0260dd69f9e07?pvs=4'}
              target="_blank"
            >
              <div className="flex gap-2 text-lg/[22px] font-normaltext-text p-4 border-b border-light-gray">
                <p>{EMenu.interview_manual}</p>
                <IconCommonStyle name="link-out" size={20} className="my-auto" />
              </div>
            </NavLink>
          </>
        )}
      </div>
      {!currentUser.consultant && (
        <>
          <div className="flex flex-col bg-white  border-light-gray">
            <NavLink to={EPath.analyzeOmoteraDiagnosisResult}>
              <div className="text-[17px] font-normal text-text p-4 border-b border-light-gray">
                {EMenu['analyze_omoteura-result']}
              </div>
            </NavLink>
          </div>
          <div className="flex flex-col bg-white  border-light-gray">
            {!currentUser.employee && !currentUser.manager && (
              <NavLink to={EPath.comparisonAxis}>
                <div className="text-lg/[22px] font-normal text-text p-4 border-b border-light-gray ">
                  {EMenu['face-back_diagnostic_analysis']}
                </div>
              </NavLink>
            )}
          </div>
          <div className="flex flex-col bg-white  border-light-gray">
            <NavLink to={EPath.Client_ID_Management}>
              <div className="text-lg/[22px] font-normal text-text p-4  border-b border-light-gray ">
                {EMenu.client_ID_management}
              </div>
            </NavLink>
            {!currentUser.manager && !currentUser.employee && (
              <>
                <NavLink to={EPath.Interviewer_ID_Management}>
                  <div className="text-lg/[22px] font-normal text-text p-4 border-b border-light-gray ">
                    {EMenu.interviewer_ID_Management}
                  </div>
                </NavLink>
                <NavLink to={EPath.Com_ID_Management}>
                  <div className="text-lg/[22px] font-normal text-text p-4 border-b border-light-gray ">
                    {EMenu['.com_ID_Management']}
                  </div>
                </NavLink>
                <NavLink to={EPath.CareerMemoRegister}>
                  <div className="text-lg/[22px] font-normal text-text p-4 border-b border-light-gray ">
                    {EMenu.career_memo_management}
                  </div>
                </NavLink>
              </>
            )}
          </div>
        </>
      )}
      {currentUser.com && (
        <NavLink to={EPath.Data_download}>
          <div className="text-lg/[22px] font-normal text-text p-4 border-b border-light-gray ">
            {EMenu.Data_download}
          </div>
        </NavLink>
      )}
      <div className="absolute text-center bottom-16 w-full">
        <span>v{packageJson.version}</span>
      </div>
    </div>
  );
};

export default SideBar;
