import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { omoteuraApi } from '../../api/omoteuraApi';
import { TListAlert, TListCategory, TOmoteura, TOmoteuraDetail } from '../../types/omoteura/omoteura';
import { TEmployeeManagement } from '../../types/clientManagement/clientManagement';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE, IndexedObject, TOptionsQuery } from '../../types/common';
import { TParams } from '../../types/consultantInterview/consultantInterview';
import { AxiosResponse } from 'axios';
import { setEmployeeName } from '../implements/implementsSlice';
import { TInterview } from '../../types/consultant/consultant';
import { setShowToast } from '../toast/toastSlice';
import { ETypeToast } from '../../app/enum';
import { TSetListAction } from 'types/types';
import { translations } from '../../locales/translations';
import { t } from 'i18next';
import { getLabelError } from '../../helpers/funcs';

export type OmoteuraState = {
  loading: boolean;
  listOmoteura: Array<TOmoteura>;
  omoteureEnergyDetail: IndexedObject[];
  omoteura: TOmoteuraDetail;
  questions: string[];
  infoEmployee: TEmployeeManagement;
  infoInterview: TInterview;
  listCategory: TListCategory | null;
  listFeedbackCom: TInterview[];
  alert: Array<TListAlert>;
  total: number;
  page: number;
  perPage: number;
  totalFeedbackCom: number;
  pageFeedbackCom: number;
};

const initialState: OmoteuraState = {
  loading: false,
  listOmoteura: [],
  omoteureEnergyDetail: [],
  omoteura: {},
  questions: [],
  infoInterview: {},
  infoEmployee: {},
  listCategory: null,
  listFeedbackCom: [],
  alert: [],
  total: 0,
  page: 1,
  perPage: 30,
  totalFeedbackCom: 0,
  pageFeedbackCom: 1,
};

export const getListCategory = createAsyncThunk(
  'omoteura/getListCategory',
  async (params: any, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoading(true));
      const response = await omoteuraApi.getListCategory(params.eventId, params.employeeId);
      thunkAPI.dispatch(setListCategory(response.data.data));
      thunkAPI.dispatch(setLoading(false));
      return response.data;
    } catch (e) {
      thunkAPI.dispatch(setListCategory(null));
    }
  },
);

export const getCategory = createAsyncThunk(
  'omoteura/getCategory',
  async (params: any, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoading(true));
      const response = await omoteuraApi.getOmoteura(params.eventId, params.employeeId);
      thunkAPI.dispatch(setCategory(response.data.data));
      thunkAPI.dispatch(setLoading(false));
      return response.data;
    } catch (e) {
      thunkAPI.dispatch(setCategory({}));
    }
  },
);

export const getQuestion = createAsyncThunk(
  'omoteura/getQuestion',
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoading(true));
      const response = await omoteuraApi.getListQuestion();
      thunkAPI.dispatch(setQuestion(response.data.data.question));
      thunkAPI.dispatch(setLoading(false));

      return response.data;
    } catch (e) {
      thunkAPI.dispatch(setQuestion([]));
    }
  },
);

export const getListOmoteura = createAsyncThunk(
  'omoteura/getListOmoteura',
  async (params: TOptionsQuery<TParams>, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    const { loadMore, ...rest } = params;
    const newParams: TOptionsQuery<TParams> = {
      ...rest,
      orderBy: rest.orderBy ?? 'createdAt',
      orderDirection: rest.orderDirection ?? 'asc',
      employeeId: rest.employeeId ?? '',
      eventId: rest.eventId ?? '',
      consultantId: rest.consultantId ?? '',
      page: rest.page ?? DEFAULT_PAGE,
      perPage: rest.perPage ?? DEFAULT_PER_PAGE,
    };
    await omoteuraApi
      .getListOmoteura(newParams)
      .then((response) => {
        thunkAPI.dispatch(setEmployeeName(response.data._metadata.employee.name ?? ''));
        thunkAPI.dispatch(
          setListOmoteura({
            type: loadMore ? 'loadMore' : 'getList',
            omoteuras: response.data.data,
            total: response.data._metadata.pagination.total,
            page: response.data._metadata.pagination.page,
            perPage: response.data._metadata.pagination.perPage,
            infoEmployee: response.data._metadata.employee,
            infoInterview: response.data._metadata.interview,
          }),
        );
        thunkAPI.dispatch(setOmoteureEnergyDetail(response.data.data));
        return response.data;
      })
      .catch((response) => {
        thunkAPI.dispatch(
          setShowToast({
            label: getLabelError(response.response.data.statusCode),
            type: ETypeToast.Error,
          }),
        );
        thunkAPI.dispatch(setLoading(false));
      });
    thunkAPI.dispatch(setLoading(false));
  },
);

export const getAlert = createAsyncThunk('omoteura/getAlert', async (params: any, thunkAPI) => {
  thunkAPI.dispatch(setLoading(true));
  const response = await omoteuraApi.getAlert(params.eventId, params.employeeId);
  thunkAPI.dispatch(setAlert(response.data.data.alerts));
  thunkAPI.dispatch(setLoading(false));
  return response.data;
});

export const getListFeedbacksCom = createAsyncThunk(
  'omoteura/getListFeedbacksCom',
  async (params: TOptionsQuery<TParams>, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    const { loadMore, ...rest } = params;
    const newParams: TOptionsQuery<TParams> = {
      ...rest,
      employeeId: rest.employeeId ?? '',
    };
    const response: AxiosResponse<any, any> = await omoteuraApi.getListFeedbackCom(newParams);
    thunkAPI.dispatch(setListFeedbackCom(response.data.data));
    thunkAPI.dispatch(setLoading(false));
  },
);

export const sendFeedbackFromCom = createAsyncThunk(
  'omoteura/sendFeedbackFromCom',
  async (params: { type: string; data: { data: TInterview[] } }, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    await omoteuraApi
      .sendFeedbackFromCom(params.type, params.data)
      .then(() => {
        thunkAPI.dispatch(
          setShowToast({
            label: t(translations.ID_MASTER.SEND_FEEDBACK_SUCCESS),
            type: ETypeToast.Success,
          }),
        );
        setTimeout(() => {
          thunkAPI.dispatch(
            getListFeedbacksCom({
              employeeId: params.data.data[0].employeeId,
              eventId: params.data.data[0].eventId,
            }),
          );
          thunkAPI.dispatch(setLoading(false));
        }, 1000);
      })
      .catch((response) => {
        thunkAPI.dispatch(
          setShowToast({
            label: getLabelError(response.response.data.statusCode),
            type: ETypeToast.Error,
          }),
        );
        thunkAPI.dispatch(setLoading(false));
      });
    thunkAPI.dispatch(setLoading(false));
  },
);

export const updateOmoteura = createAsyncThunk(
  'omoteura/updateOmoteura',
  async (params: any, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    await omoteuraApi
      .updateOmoteura(params)
      .then(() => {
        thunkAPI.dispatch(
          setShowToast({
            label: t(translations.ID_MASTER.UPLOAD_SUCCESS),
            type: ETypeToast.Success,
          }),
        );
        setTimeout(() => {
          thunkAPI.dispatch(
            getCategory({
              employeeId: params.employeeId,
              eventId: params.eventId,
            }),
          );
          thunkAPI.dispatch(setLoading(false));
        }, 1000);
      })
      .catch((response) => {
        thunkAPI.dispatch(
          setShowToast({
            label: getLabelError(response.response.data.statusCode),
            type: ETypeToast.Error,
          }),
        );
        thunkAPI.dispatch(setLoading(false));
      });
    thunkAPI.dispatch(setLoading(false));
  },
);


export const clearAllDataOmoteura = createAsyncThunk(
  'omoteura/clearAllDataOmoteura',
  async (_, thunkAPI) => {
    thunkAPI.dispatch(clearAllData);
  },
);

const slice = createSlice({
  name: 'omoteura',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setListOmoteura: (
      state,
      action: PayloadAction<{
        type: TSetListAction;
        omoteuras: TOmoteura[];
        page?: number;
        total?: number;
        perPage?: number;
        infoEmployee?: TEmployeeManagement;
        infoInterview?: TInterview;
      }>,
    ) => {
      const { type, omoteuras, total, page, infoEmployee, perPage, infoInterview } = action.payload;
      if (omoteuras) {
        switch (type) {
          case 'getList':
            state.listOmoteura = omoteuras;
            break;
          case 'loadMore':
            state.listOmoteura = [...state.listOmoteura, ...omoteuras];
            break;
        }

        state.page = page ?? DEFAULT_PAGE;
        state.total = total ?? 0;
        state.perPage = perPage ?? DEFAULT_PER_PAGE;

        if (infoEmployee) {
          state.infoEmployee = infoEmployee;
        }
        if (infoInterview) {
          state.infoInterview = infoInterview;
        }
      }
    },

    setListCategory: (state, action: PayloadAction<TListCategory | null>) => {
      state.listCategory = action.payload;
    },

    reSetListCategory: (state) => {
      state.listCategory = null;
    },

    setCategory: (state, action: PayloadAction<TOmoteuraDetail>) => {
      state.omoteura = action.payload;
    },

    setQuestion: (state, action: PayloadAction<string[]>) => {
      state.questions = action.payload;
    },

    setOmoteureEnergyDetail: (state, action: PayloadAction<IndexedObject[]>) => {
      state.omoteureEnergyDetail = action.payload;
    },

    clearOmoteureEnergyDetail: (state, action: PayloadAction<[]>) => {
      state.omoteureEnergyDetail = action.payload;
    },

    setAlert: (state, action: PayloadAction<Array<TListAlert>>) => {
      state.alert = action.payload;
    },
    setListFeedbackCom: (state, action: PayloadAction<TInterview[]>) => {
      state.listFeedbackCom = action.payload;
    },
    clearAllData: (state) => {
      state.listOmoteura = [];
      state.omoteureEnergyDetail = [];
      state.infoEmployee = {};
      state.listFeedbackCom = [];
      state.alert = [];
    },
  },
});

export const { actions, reducer: OmoteuraReducer } = slice;
export const {
  setLoading,
  setListOmoteura,
  setOmoteureEnergyDetail,
  setListCategory,
  setQuestion,
  setCategory,
  setAlert,
  setListFeedbackCom,
  clearAllData,
  reSetListCategory,
} = actions;
