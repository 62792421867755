import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TCareerMemo } from '../../types/careerMemoManagement/careerMemoManagement';
import { careerMemoApi } from '../../api/careerMemoApi';
import { setShowToast } from '../toast/toastSlice';
import { ETypeToast } from '../../app/enum';
import { t } from 'i18next';
import { translations } from '../../locales/translations';
import { getLabelError } from '../../helpers/funcs';

export type CareerMemoState = {
  loading: boolean;
  careerMemoManagement: TCareerMemo;
};

const initialState: CareerMemoState = {
  loading: false,
  careerMemoManagement: {},
};

export const getCareerMemoManagement = createAsyncThunk(
  'careerMemo/getCareerMemoManagement',
  async (comId: string) => {
    const response = await careerMemoApi.getCareerMemo(comId);
    return response.data.data._doc;
  },
);

export const updateCareerMemoManagement = createAsyncThunk(
  'careerMemo/updateCareerMemoManagement',
  async (data: TCareerMemo, thunkAPI) => {
    await careerMemoApi
      .updateCareerMemo(data)
      .then(() => {
        thunkAPI.dispatch(
          setShowToast({
            label: t(translations.ID_MASTER.UPDATE_SUCCESS),
            type: ETypeToast.Success,
          }),
        );
      })
      .catch((response) => {
        thunkAPI.dispatch(
          setShowToast({
            label: getLabelError(response.response.data.statusCode),
            type: ETypeToast.Error,
          }),
        );
        thunkAPI.dispatch(setLoading(false));
      });
    thunkAPI.dispatch(getCareerMemoManagement(String(data.comId)));
  },
);

const slice = createSlice({
  name: 'careerMemo',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCareerMemoManagement.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getCareerMemoManagement.fulfilled,
      (state, action: PayloadAction<TCareerMemo>) => {
        state.careerMemoManagement = action.payload;
        state.loading = false;
      },
    );
    builder.addCase(updateCareerMemoManagement.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateCareerMemoManagement.fulfilled, (state) => {
      state.loading = false;
    });
  },
});

export const { actions, reducer: CareerMemoManagementReducer } = slice;
export const { setLoading } = actions;
export default CareerMemoManagementReducer;
