import { axiosClient } from './axiosClient';
import {
  TCertificate,
  TFeedbackConsultant,
  TParams,
  TScheduleParams,
} from '../types/consultantInterview/consultantInterview';
import queryString from 'querystring';
import { TInterview } from '../types/consultant/consultant';

export const consultantInterviewApi = {
  getListEmployees: (params: TParams) => {
    const url = `/interview/consultant`;
    const query = queryString.stringify(params);
    return axiosClient.get(`${url}?${query}`);
  },

  getListFeedback: (params: TFeedbackConsultant) => {
    const url = `/interview/consultant/feedbacks`;
    const query = queryString.stringify(params);
    return axiosClient.get(`${url}?${query}`);
  },

  getScheduleConsultant: (params: TScheduleParams) => {
    const url = `/consultant-management`;
    const query = queryString.stringify(params);
    return axiosClient.get(`${url}?${query}`);
  },
  getListReservationStatus: (consultantId: string, pageNumberValue: number) => {
    const url = `consultant-management/${consultantId}/statistics/${pageNumberValue}`;
    return axiosClient.get(url);
  },
  updateScheduleConsultant: (body: any, id: string) => {
    const url = `/consultant-management/${id}`;
    return axiosClient.put(url, body);
  },
  getInterviewDetail: (id: string) => {
    const url = `/interview/${id}`;
    return axiosClient.get(url);
  },
  updateInterviewDetail: (params: { data: TInterview; id: string }) => {
    const url = `/interview/consultant/feedback/${params.id}`;
    return axiosClient.patch(url, params.data);
  },
  uploadFeedBackFile: (feedBackId: string, file: File) => {
    const url = `/interview/consultant/uploadFeedBackFile/${feedBackId}/${file.name}`;
    const formData = new FormData();
    formData.append('file', file);
    return axiosClient.patch(url, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  },
  getInfoConsultant: (consultantId: string) => {
    const url = `/consultant/${consultantId}`;
    return axiosClient.get(url);
  },
  // certificate - account setting
  getListCertificate: (id: string) => {
    const url = `/certificate/${id}`;
    return axiosClient.get(url);
  },
  createCertificate: (params: { id: string; data: TCertificate[] }) => {
    const url = `/certificate/${params.id}`;
    return axiosClient.post(url, { data: params.data });
  },
  updateListCertificate: (params: { consultantId: string; data: TCertificate[] }) => {
    const url = `certificate/${params.consultantId}`;
    return axiosClient.put(url, { data: params.data });
  },
  uploadFileCertificate: (file: File) => {
    const url = `/certificate/uploadFile/${file.name}`;
    const formData = new FormData();
    formData.append('file', file);
    return axiosClient.post(url, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  },
  deleteFileCertificate: (filePath: string) => {
    const url = `/certificate/deleteFile?filePath=${filePath}`;
    return axiosClient.put(url);
  },
  downloadFileCertificate: (params: { certificateId: string; fileName: string }) => {
    const url = `/certificate/getFiles/${params.certificateId}/${params.fileName}`;
    return axiosClient.get(url, { responseType: 'blob' });
  },
  updateStartTime: (params: {
    interviewId: string;
    consultantId: string;
    eventId: string;
    employeeId: string;
  }) => {
    const url = `/interview/consultant/updateStartTime/${params.interviewId}?consultantId=${params.consultantId}&eventId=${params.eventId}&employeeId=${params.employeeId}`;
    return axiosClient.patch(url);
  },
  updateEndTime: (interviewId: string) => {
    const url = `/interview/consultant/updateEndTime/${interviewId}`;
    return axiosClient.patch(url);
  },
  activeRowSchedule: (consultantId: string, dataBody: any) => {
    const url = `/consultant-management/hours-pick/${consultantId}`;
    return axiosClient.put(url, dataBody);
  },
  cancelInterview: (interviewId: string, data: { cancelReason: string; isCom: boolean }) => {
    const url = `/interview/${interviewId}`;
    return axiosClient.delete(url, { data: data });
  },
};
