import React from 'react';

type Props = {
  name:
    | 'menu-bar'
    | 'link-out'
    | 'edit'
    | 'more'
    | 'plus'
    | 'pencil'
    | 'search'
    | 'block'
    | 'trash'
    | 'description'
    | 'account-circle'
    | 'folder'
    | 'file-download'
    | 'calendar'
    | 'content_copy';
  color?: string;
  size?: number;
  className?: string;
};

const IconCommonStyle = (props: Props) => {
  const { size = 24, name, color = '', className } = props;
  if (name === 'menu-bar') {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        className={`text-${color} fill-current ${className}`}
      >
        <g clipPath="url(#clip0_21_5544)">
          <path d="M3 18H21V16H3V18ZM3 13H21V11H3V13ZM3 6V8H21V6H3Z" />
        </g>
        <defs>
          <clipPath id="clip0_21_5544">
            <rect width={size} height={size} fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }
  if (name === 'edit') {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        className={`text-${color} fill-current ${className}`}
      >
        <path d="M4.5 23.7001C4.1 23.7001 3.75 23.5501 3.45 23.2501C3.15 22.9501 3 22.6001 3 22.2001V7.2001C3 6.8001 3.15 6.4501 3.45 6.1501C3.75 5.8501 4.1 5.7001 4.5 5.7001H14.625L13.125 7.2001H4.5V22.2001H19.5V13.5001L21 12.0001V22.2001C21 22.6001 20.85 22.9501 20.55 23.2501C20.25 23.5501 19.9 23.7001 19.5 23.7001H4.5ZM16.55 5.9001L17.625 6.9501L10.5 14.0501V16.2001H12.625L19.775 9.0501L20.825 10.1001L13.25 17.7001H9V13.4501L16.55 5.9001ZM20.825 10.1001L16.55 5.9001L19.05 3.4001C19.3333 3.11676 19.6875 2.9751 20.1125 2.9751C20.5375 2.9751 20.8917 3.1251 21.175 3.4251L23.275 5.5501C23.5583 5.8501 23.7 6.20426 23.7 6.6126C23.7 7.02093 23.55 7.36676 23.25 7.6501L20.825 10.1001Z" />
      </svg>
    );
  }
  if (name === 'more') {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        className={`text-${color} fill-current ${className}`}
      >
        <path d="M6 10C4.9 10 4 10.9 4 12C4 13.1 4.9 14 6 14C7.1 14 8 13.1 8 12C8 10.9 7.1 10 6 10ZM18 10C16.9 10 16 10.9 16 12C16 13.1 16.9 14 18 14C19.1 14 20 13.1 20 12C20 10.9 19.1 10 18 10ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z" />
      </svg>
    );
  }
  if (name === 'plus') {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 16 16"
        className={`text-${color} fill-current ${className}`}
      >
        <path d="M12.6667 8.66665H8.66671V12.6666H7.33337V8.66665H3.33337V7.33331H7.33337V3.33331H8.66671V7.33331H12.6667V8.66665Z" />
      </svg>
    );
  }
  if (name === 'search') {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        className={`text-${color} fill-current ${className}`}
      >
        <path d="M13.1291 11.8796H12.4707L12.2374 11.6546C13.0541 10.7046 13.5457 9.47122 13.5457 8.12956C13.5457 5.13789 11.1207 2.71289 8.12907 2.71289C5.1374 2.71289 2.7124 5.13789 2.7124 8.12956C2.7124 11.1212 5.1374 13.5462 8.12907 13.5462C9.47074 13.5462 10.7041 13.0546 11.6541 12.2379L11.8791 12.4712V13.1296L16.0457 17.2879L17.2874 16.0462L13.1291 11.8796ZM8.12907 11.8796C6.05407 11.8796 4.37907 10.2046 4.37907 8.12956C4.37907 6.05456 6.05407 4.37956 8.12907 4.37956C10.2041 4.37956 11.8791 6.05456 11.8791 8.12956C11.8791 10.2046 10.2041 11.8796 8.12907 11.8796Z" />
      </svg>
    );
  }
  if (name === 'pencil') {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        className={`text-${color} fill-current ${className}`}
      >
        <path d="M14.0588 9.02L14.9788 9.94L5.91878 19H4.99878V18.08L14.0588 9.02ZM17.6588 3C17.4088 3 17.1488 3.1 16.9588 3.29L15.1288 5.12L18.8788 8.87L20.7088 7.04C21.0988 6.65 21.0988 6.02 20.7088 5.63L18.3688 3.29C18.1688 3.09 17.9188 3 17.6588 3ZM14.0588 6.19L2.99878 17.25V21H6.74878L17.8088 9.94L14.0588 6.19Z" />
      </svg>
    );
  }
  if (name === 'trash') {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        className={`text-${color} fill-current ${className}`}
      >
        <path d="M16 9V19H8V9H16ZM14.5 3H9.5L8.5 4H5V6H19V4H15.5L14.5 3ZM18 7H6V19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7Z" />
      </svg>
    );
  }
  if (name === 'account-circle') {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        className={`text-${color} fill-current ${className}`}
      >
        <path d="M4.62533 14.6875C5.50033 14.1319 6.36491 13.7118 7.21908 13.427C8.07324 13.1423 9.00033 13 10.0003 13C11.0003 13 11.9309 13.1423 12.792 13.427C13.6531 13.7118 14.5212 14.1319 15.3962 14.6875C16.0073 13.9375 16.4413 13.1805 16.6982 12.4166C16.9552 11.6527 17.0837 10.8472 17.0837 9.99996C17.0837 7.98607 16.4066 6.30204 15.0524 4.94788C13.6982 3.59371 12.0142 2.91663 10.0003 2.91663C7.98644 2.91663 6.30241 3.59371 4.94824 4.94788C3.59408 6.30204 2.91699 7.98607 2.91699 9.99996C2.91699 10.8472 3.04894 11.6527 3.31283 12.4166C3.57671 13.1805 4.01421 13.9375 4.62533 14.6875ZM10.0003 10.625C9.19477 10.625 8.51769 10.3507 7.96908 9.80204C7.42046 9.25343 7.14616 8.57635 7.14616 7.77079C7.14616 6.96524 7.42046 6.28815 7.96908 5.73954C8.51769 5.19093 9.19477 4.91663 10.0003 4.91663C10.8059 4.91663 11.483 5.19093 12.0316 5.73954C12.5802 6.28815 12.8545 6.96524 12.8545 7.77079C12.8545 8.57635 12.5802 9.25343 12.0316 9.80204C11.483 10.3507 10.8059 10.625 10.0003 10.625ZM10.0003 18.3333C8.83366 18.3333 7.74338 18.1145 6.72949 17.677C5.7156 17.2395 4.83366 16.6423 4.08366 15.8854C3.33366 15.1284 2.74338 14.243 2.31283 13.2291C1.88227 12.2152 1.66699 11.1319 1.66699 9.97913C1.66699 8.84024 1.88574 7.76385 2.32324 6.74996C2.76074 5.73607 3.35796 4.85413 4.11491 4.10413C4.87185 3.35413 5.75727 2.76038 6.77116 2.32288C7.78505 1.88538 8.86838 1.66663 10.0212 1.66663C11.16 1.66663 12.2364 1.88538 13.2503 2.32288C14.2642 2.76038 15.1462 3.35413 15.8962 4.10413C16.6462 4.85413 17.2399 5.73607 17.6774 6.74996C18.1149 7.76385 18.3337 8.84718 18.3337 9.99996C18.3337 11.1388 18.1149 12.2152 17.6774 13.2291C17.2399 14.243 16.6462 15.1284 15.8962 15.8854C15.1462 16.6423 14.2642 17.2395 13.2503 17.677C12.2364 18.1145 11.1531 18.3333 10.0003 18.3333Z" />
      </svg>
    );
  }
  if (name === 'block') {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        className={`text-${color} fill-current ${className}`}
      >
        <path d="M6.85972 12.8851H17.1402V10.9525H6.85972V12.8851ZM11.9893 22.4302C10.5651 22.4302 9.218 22.1559 7.94807 21.6072C6.67814 21.0585 5.57199 20.313 4.62962 19.3707C3.68725 18.4283 2.94174 17.3213 2.39307 16.0498C1.8444 14.7782 1.57007 13.4287 1.57007 12.0012C1.57007 10.5588 1.84489 9.20328 2.39454 7.93473C2.94419 6.66616 3.6926 5.55838 4.63977 4.61138C5.58694 3.66436 6.69311 2.91979 7.95829 2.37765C9.22348 1.83552 10.5666 1.56445 11.9875 1.56445C13.4312 1.56445 14.7902 1.83534 16.0645 2.3771C17.3388 2.91885 18.4475 3.66302 19.3904 4.6096C20.3334 5.55619 21.0767 6.6653 21.6203 7.93695C22.164 9.20859 22.4358 10.5676 22.4358 12.0139C22.4358 13.4433 22.1648 14.7884 21.6226 16.0493C21.0805 17.3101 20.3359 18.414 19.3889 19.3609C18.4419 20.3078 17.3332 21.0561 16.0627 21.6057C14.7923 22.1554 13.4345 22.4302 11.9893 22.4302ZM11.9986 20.4976C14.3508 20.4976 16.3553 19.6668 18.012 18.0051C19.6688 16.3434 20.4972 14.3423 20.4972 12.0017C20.4972 9.64949 19.6693 7.64499 18.0134 5.98823C16.3576 4.33144 14.3525 3.50305 11.9983 3.50305C9.66079 3.50305 7.66046 4.33099 5.99734 5.98685C4.33423 7.64272 3.50267 9.64775 3.50267 12.002C3.50267 14.3395 4.33352 16.3398 5.99522 18.0029C7.6569 19.666 9.65802 20.4976 11.9986 20.4976Z" />
      </svg>
    );
  }
  if (name === 'description') {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        className={`text-${color} fill-current ${className}`}
      >
        <path d="M7.975 17.75H16.025V16.25H7.975V17.75ZM7.975 13.5H16.025V12H7.975V13.5ZM5.5 22C5.1 22 4.75 21.85 4.45 21.55C4.15 21.25 4 20.9 4 20.5V3.5C4 3.1 4.15 2.75 4.45 2.45C4.75 2.15 5.1 2 5.5 2H14.525L20 7.475V20.5C20 20.9 19.85 21.25 19.55 21.55C19.25 21.85 18.9 22 18.5 22H5.5ZM13.775 8.15V3.5H5.5V20.5H18.5V8.15H13.775Z" />
      </svg>
    );
  }
  if (name === 'link-out') {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        className={`text-${color} fill-current ${className}`}
      >
        <path d="M5.1749 20.9749C4.5749 20.9749 4.06657 20.7666 3.6499 20.3499C3.23324 19.9332 3.0249 19.4249 3.0249 18.8249V5.1749C3.0249 4.5749 3.23324 4.06657 3.6499 3.6499C4.06657 3.23324 4.5749 3.0249 5.1749 3.0249H11.9249V5.1749H5.1749V18.8249H18.8249V12.0749H20.9749V18.8249C20.9749 19.4249 20.7666 19.9332 20.3499 20.3499C19.9332 20.7666 19.4249 20.9749 18.8249 20.9749H5.1749ZM9.9249 15.5749L8.4249 14.0749L17.3249 5.1749H13.7999V3.0249H20.9749V10.1999H18.8249V6.6749L9.9249 15.5749Z" />
      </svg>
    );
  }
  if (name === 'folder') {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        className={`text-${color} fill-current ${className}`}
      >
        <path d="M9.17 6L11.17 8H20V18H4V6H9.17ZM10 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V8C22 6.9 21.1 6 20 6H12L10 4Z" />
      </svg>
    );
  }
  if (name === 'file-download') {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 16 16"
        className={`text-${color} fill-current ${className}`}
      >
        <path d="M12.0003 9.99935V11.9993H4.00033V9.99935H2.66699V11.9993C2.66699 12.7327 3.26699 13.3327 4.00033 13.3327H12.0003C12.7337 13.3327 13.3337 12.7327 13.3337 11.9993V9.99935H12.0003ZM11.3337 7.33268L10.3937 6.39268L8.66699 8.11268V2.66602H7.33366V8.11268L5.60699 6.39268L4.66699 7.33268L8.00033 10.666L11.3337 7.33268Z" />
      </svg>
    );
  }
  if (name === 'calendar') {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        className={`text-${color} fill-current ${className}`}
      >
        <path d="M15.8333 3.33268H15V1.66602H13.3333V3.33268H6.66667V1.66602H5V3.33268H4.16667C3.24167 3.33268 2.50833 4.08268 2.50833 4.99935L2.5 16.666C2.5 17.5827 3.24167 18.3327 4.16667 18.3327H15.8333C16.75 18.3327 17.5 17.5827 17.5 16.666V4.99935C17.5 4.08268 16.75 3.33268 15.8333 3.33268ZM15.8333 16.666H4.16667V8.33268H15.8333V16.666ZM15.8333 6.66602H4.16667V4.99935H15.8333V6.66602ZM7.5 11.666H5.83333V9.99935H7.5V11.666ZM10.8333 11.666H9.16667V9.99935H10.8333V11.666ZM14.1667 11.666H12.5V9.99935H14.1667V11.666ZM7.5 14.9993H5.83333V13.3327H7.5V14.9993ZM10.8333 14.9993H9.16667V13.3327H10.8333V14.9993ZM14.1667 14.9993H12.5V13.3327H14.1667V14.9993Z" />
      </svg>
    );
  } else {
    return <></>;
  }
};

export default IconCommonStyle;
