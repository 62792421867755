import { apartmentIcon, interviewerIcon, logoIcon } from 'app/components/Icons/icons';
import React, { useEffect, useMemo, useState } from 'react';
import IconCommonStyle from 'app/components/Icons/IconCommonStyle';
import CommonDropdown from '../../common/CommonDropdown';
import { MenuProps, Tooltip } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../types';
import {
  clearAllData,
  convertDate,
  convertTime,
  convertValueToString,
  replacePathParams,
} from '../../../helpers/funcs';
import CommonSelect from '../../common/CommonSelect';
import PlaceIcon from '../../../assets/icon/place.svg';
import DocumentIcon from '../../../assets/icon/document.svg';
import UserIcon from '../../../assets/icon/user.svg';
import { setFacilityName } from '../../../store/implements/implementsSlice';
import CommonModal from '../../common/modal/CommonModal';
import { AccountSettingModal } from './AccountSettingModal';
import { getInfoCom } from '../../../store/com/comSlice';
import { getInfoManager } from '../../../store/manager/managerSlice';
import { getListOmoteura } from '../../../store/omoteura/omoteuraSlice';
import { useServices } from '../OmoteraDiagnosisResultPage/service';
import { expires, getUserFromCookie, saveUserCookie } from '../../../helpers/cookies';
import { axiosClient } from '../../../api/axiosClient';
import { EPath } from '../../routes/routesConfig';
import logo from '../../assets/images/logo.png';
import { getInfoEmployee } from '../../../store/employee/employeeSlice';

export default function MyHeader() {
  const dispatch = useDispatch();
  const { setEventSelectedConsultant, eventSelectedConsultant } = useServices();
  const navigate = useNavigate();
  const location = useLocation();

  const { interviewDetail } = useSelector((state: RootState) => state.interviewer);
  const { listImplementByConsultant, isShowListEvent, facilityNameSelected, employeeNameSelected } =
    useSelector((state: RootState) => state.implement);
  const [currentUser, setCurrentUser] = useState(getUserFromCookie());
  const [isOpenModalAccountSetting, setIsOpenModalAccountSetting] = useState<boolean>(false);
  const { infoCom } = useSelector((state: RootState) => state.com);
  const { infoManager } = useSelector((state: RootState) => state.manager);
  const { infoEmployee } = useSelector((state: RootState) => state.employee);
  const handleSetEventSelected = (id: string) => {
    setEventSelectedConsultant(id);
  };
  const { currentUserChat: currentUserChatRedux } = useSelector((state: RootState) => state.auth);

  const handleClose = () => setIsOpenModalAccountSetting(false);

  useEffect(() => {
    setEventSelectedConsultant(location.pathname.split('/')[4]);
    listImplementByConsultant[0]?.facilityName &&
      dispatch(setFacilityName(listImplementByConsultant[0]?.facilityName));
  }, [listImplementByConsultant, location.pathname]);

  useEffect(() => {
    if (
      location.pathname.includes('interviews/omoteura-diagnosis-result') &&
      eventSelectedConsultant
    ) {
      dispatch(
        getListOmoteura({
          employeeId: location.pathname.split('/')[3],
          eventId: convertValueToString(eventSelectedConsultant),
        }),
      );
      navigate(
        replacePathParams(EPath.omoteuraDiagnosiConsultant, {
          employeeId: convertValueToString(location.pathname.split('/')[3]),
          eventId: convertValueToString(eventSelectedConsultant),
        }),
        { replace: true },
      );
    }
  }, [eventSelectedConsultant, location.pathname.split('/')[3]]);

  const items: MenuProps['items'] = [
    {
      label: (
        <div
          className="flex gap-2 p-2 items-center h-10"
          onClick={() => {
            if (currentUser.consultant) {
              navigate('/account-setting');
            } else {
              setIsOpenModalAccountSetting(true);
            }
          }}
        >
          <span className="text-base font-normal leading-[24px] tracking-wide">アカウント設定</span>
        </div>
      ),
      key: '0',
    },
    {
      label: (
        <div className="flex gap-2 p-2 items-center h-10" onClick={clearAllData}>
          <span className="text-base font-normal leading-[24px] tracking-wide">ログアウト</span>
        </div>
      ),
      key: '1',
    },
  ];

  useEffect(() => {
    setCurrentUser(getUserFromCookie());
  }, [JSON.stringify(getUserFromCookie())]);

  const getNameUser = useMemo(() => {
    if (Object.keys(currentUser).length > 0) {
      return (
        currentUser?.com?.name ??
        currentUser?.employee?.name ??
        currentUser?.consultant?.name ??
        currentUser?.manager?.name
      );
    }
    return '';
  }, [JSON.stringify(getUserFromCookie()), currentUser]);

  const getInfo = async () => {
    axiosClient.get(`/consultant/${getUserFromCookie()?.consultant?._id}`).then((e) => {
      const newUser = getUserFromCookie();
      const modifyUser = {
        ...newUser,
        name: e.data.data.name,
      };
      setCurrentUser(modifyUser);
      saveUserCookie(modifyUser, expires);
    });
  };

  useEffect(() => {
    const user = getUserFromCookie();
    if (user.role === 'consultant') {
      getInfo();
    }
  }, [JSON.stringify(getUserFromCookie())]);

  useEffect(() => {
    if (currentUser.com) {
      dispatch(getInfoCom(currentUser.com._id));
    } else if (currentUser.manager) {
      dispatch(getInfoManager(currentUser.manager._id));
    } else if (currentUser.employee) {
      dispatch(getInfoEmployee(currentUser.employee._id));
    }
  }, []);

  const getContentHeader = (pathName: string) => {
    switch (true) {
      case pathName.includes('/interviews/omoteura'):
        return (
          <div className="flex items-center min-w-[1400px] gap-6 py-2 px-6 bg-gainsboro shadow-[0px_4px_4px_0px_#00000040]">
            <div className="text-lg w-52 text-jet-black whitespace-nowrap">
              現在のコンディション
            </div>
            {isShowListEvent && (
              <div className="flex justify-between w-full">
                <div className="flex items-center justify-start w-[294px]">
                  {listImplementByConsultant.length > 0 && (
                    <CommonSelect
                      options={listImplementByConsultant.map((implement) => {
                        return {
                          value: convertValueToString(implement?._id),
                          label: convertValueToString(implement?.eventName),
                        };
                      })}
                      value={eventSelectedConsultant}
                      defaultValue={location.pathname.split('/')[4]}
                      onChangeValue={handleSetEventSelected}
                    />
                  )}
                </div>
                <div>
                  あくまでも、受信時点のコンディションを示したものです。
                  <br />
                  コンディションは、時と共に変化することを前提にご利用ください。
                </div>
                <div className="flex items-center gap-2">
                  <Tooltip title={facilityNameSelected}>
                    <div className="flex gap-2 h-[23px]">
                      <img src={PlaceIcon} alt="" />
                      <span className="max-w-[200px] text-base whitespace-nowrap text-ellipsis overflow-hidden">
                        {facilityNameSelected}
                      </span>
                    </div>
                  </Tooltip>
                  <Tooltip title={employeeNameSelected}>
                    <div className="flex gap-2 h-[23px]">
                      <img src={UserIcon} alt="" />
                      <span className="max-w-[200px] text-base whitespace-nowrap text-ellipsis overflow-hidden">
                        {employeeNameSelected}
                      </span>
                      <img src={DocumentIcon} alt="" />
                    </div>
                  </Tooltip>
                </div>
              </div>
            )}
          </div>
        );
      case pathName.includes('/interviews/'):
        return (
          <div className="flex gap-[30px] bg-gainsboro px-6 py-2 text-lg text-black h-14 leading-10">
            <div className="flex gap-10">
              <p className="my-auto">面談詳細</p>
              {Object.keys(interviewDetail).length > 0 && (
                <div className="flex gap-2 font-bold">
                  <span>実施日時：</span>
                  <span>{convertDate(String(interviewDetail.startTime?.replaceAll('Z', '')))}</span>
                  <span>
                    {convertTime(String(interviewDetail.startTime?.replaceAll('Z', '')))}-
                    {convertTime(String(interviewDetail.endTime?.replaceAll('Z', '')))}
                  </span>
                </div>
              )}
            </div>
            <div className="flex gap-4">
              <div className="flex gap-2">
                <span className="my-auto">{apartmentIcon}</span>
                <span>{facilityNameSelected}</span>
              </div>
              <div className="flex gap-2">
                <IconCommonStyle name="account-circle" className="my-auto" />
                <span>{employeeNameSelected}</span>
              </div>
            </div>
          </div>
        );
    }
  };

  return (
    <>
      <div className={`!h-auto z-[1] relative`}>
        <div className="flex-col">
          <div className="w-full flex justify-between px-10 bg-white md:pl-4 md:pr-6">
            <div className="flex items-center justify-self-end p-2 cursor-pointer hover:bg-slate-200 mobile:block md:hidden my-auto">
              <IconCommonStyle name="menu-bar" />
            </div>
            <img alt="logo" src={logo} className="h-[56px] object-fill" />
            {!currentUser.consultant ? (
              <div className="flex gap-2 mobile:hidden cursor-pointer">
                <p className="my-auto">{interviewerIcon}</p>
                <CommonDropdown items={items}>
                  <p className="text-jet-black text-lg my-auto">
                    氏名：<span>{getNameUser}</span>
                  </p>
                </CommonDropdown>
              </div>
            ) : (
              <CommonDropdown items={items}>
                <div className="flex gap-2 mobile:hidden cursor-pointer">
                  <p className="my-auto">{interviewerIcon}</p>
                  <p className="text-jet-black text-lg my-auto">{currentUser?.name}</p>
                </div>
              </CommonDropdown>
            )}
          </div>
          {currentUser.consultant && <>{getContentHeader(location.pathname)}</>}
        </div>
      </div>

      {(currentUser.com || currentUser.employee) && (
        <CommonModal
          open={isOpenModalAccountSetting}
          title={'アカウント設定'}
          onClose={handleClose}
        >
          <AccountSettingModal
            handleClose={handleClose}
            infoUser={Object.keys(infoCom).length !== 0 ? infoCom : infoEmployee}
          />
        </CommonModal>
      )}

      {currentUser.manager && (
        <CommonModal
          open={isOpenModalAccountSetting}
          title={'アカウント設定'}
          onClose={handleClose}
        >
          <AccountSettingModal handleClose={handleClose} infoUser={infoManager} />
        </CommonModal>
      )}
    </>
  );
}
