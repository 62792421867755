import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getMessaging, getToken } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY_FIREBASE,
  authDomain: process.env.REACT_APP_BASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_BASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_BASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_BASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_BASE_APP_ID,
  measurementId: process.env.REACT_APP_BASE_MEASUREMENT_ID,
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const db = getFirestore(app);

const isSupportedBrowser = () => {
  return 'serviceWorker' in navigator && 'PushManager' in window;
};

export const messaging =
  isSupportedBrowser() && !process.env.REACT_APP_DEVELOP ? getMessaging(app) : null;

const registerServiceWorker = async () => {
  if ('serviceWorker' in navigator) {
    try {
      const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');
      console.log('Service Worker registered with scope:', registration.scope);
    } catch (error) {
      console.error('ServiceWorker registration failed: ', error);
    }
  }
};

export const generateToken = async () => {
  if (!isSupportedBrowser() || process.env.REACT_APP_DEVELOP) return;
  await registerServiceWorker();
  const permission = await Notification.requestPermission();
  if (permission === 'granted') {
    try {
      return await getToken(messaging, {
        vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
      });
    } catch (e) {
      console.error('ServiceWorker registration failed: ', e);
    }
  }
};
