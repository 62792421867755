import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { TModal } from '../../../types/types';
import {
  deleteAnalysisReport,
  setClientSelect,
  setEventSelect,
  setFacilitySelect,
  setIsRenderCreateReport,
} from '../../../store/analysis/analysisSlice';
import { RootState } from '../../../types';
import { EPath } from '../../routes/routesConfig';
import { useNavigate } from 'react-router-dom';
import { TAnalysis } from 'types/analysis/analysis';
import { convertValueToString, replacePathParams } from 'helpers/funcs';
import { removeClientFacilityAndEventSelected } from '../../../helpers/localStorage';

export const textScoreAverage = ['平均値', '標準偏差', '中央値'];
export const textScoreAverage4Category = ['平均', '標準偏差', '中央値'];

export const eraOptions = [
  { value: '10', label: '10代' },
  { value: '20', label: '20代' },
  { value: '30', label: '30代' },
  { value: '40', label: '40代' },
  { value: '50', label: '50代' },
  { value: '60', label: '60代' },
  { value: '70', label: '70代以上' },
];

export const hireDateOptions = [
  { value: '0,1', label: '1ヶ月未満' },
  { value: '1,2', label: '1ヶ月以上2ヶ月未満' },
  { value: '2,3', label: '2ヶ月以上3ヶ月未満' },
  { value: '3,12', label: '3ヶ月以上1年未満' },
  { value: '12,36', label: '1年以上3年未満' },
  { value: '36,0', label: '3年以上' },
];

export const workOptions = [
  { value: 'work', label: '仕事' },
  { value: 'private', label: '仕事以外' },
];

const useServices = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState<TModal>('');
  const { currentUser } = useSelector((state: RootState) => state.auth);
  const hasMore = true;
  const {
    listAnalysis,
    loading,
    currentDetailReport,
    occupations,
    isRenderCreateReport,
    listEmployee,
    highlightAxis16,
    ageSelect,
    dateSelect,
    roleSelect,
    occupationSelect,
    memoContent,
    checkBoxPdf,
    listNumberPageInputMemoCKEditor,
    detailReport,
    status,
    roles,
  } = useSelector((state: RootState) => state.analysis);
  const { listClientManagement } = useSelector((state: RootState) => state.client);
  const [itemReport, setItemReport] = useState<TAnalysis>({});
  const titleTableAnalyzeRp = [
    'ハイライト(赤)',
    'ハイライト(青)',
    '座標',
    '従業員1',
    '従業員2',
    '従業員3',
    '従業員4',
    '従業員5',
    '従業員6',
    '従業員7',
    '従業員8',
    '従業員9',
    '従業員10',
    '平均値',
    '標準偏差',
    '中央値',
  ];

  const titleCoordinateAnalyzeRp = [
    {
      title: 'A',
      content: '協力性',
    },
    {
      title: 'B',
      content: '環境順応性 ',
    },
    {
      title: 'C',
      content: '優柔性',
    },
    {
      title: 'D',
      content: '謙譲性',
    },
    {
      title: 'E',
      content: '自省心',
    },
    {
      title: 'F',
      content: '規律性',
    },
    {
      title: 'G',
      content: '固執性',
    },
    {
      title: 'H',
      content: '情動性',
    },
    {
      title: 'I',
      content: '感受性',
    },
    {
      title: 'J',
      content: '自己信頼性',
    },
    {
      title: 'K',
      content: '革新性',
    },
    {
      title: 'L',
      content: '活動性',
    },
    {
      title: 'M',
      content: '積極性',
    },
    {
      title: 'N',
      content: '指導性',
    },
    {
      title: 'O',
      content: '活動性',
    },
    {
      title: 'P',
      content: '社交性',
    },
  ];

  const listAnalyzeReport = [
    {
      id: '1',
      name: '',
      title: '',
      status: '非公開',
      companyName: '関東シニア介護サービス株式会社',
      facilityName: 'ジャパンケアサービス西東京 居宅介護支援事業所',
      target_episode: '令和5年度 上半期 3/15-3/20実施回_Aグループ',
      created_date: '2023/03/18',
      isActive: true,
    },
    {
      id: '1',
      name: '無題おもてうら分析レポート',
      status: '非公開',
      title: '',
      companyName: '関東シニア介護サービス株式会社',
      facilityName: 'ジャパンケアサービス西東京 居宅介護支援事業所',
      target_episode: '令和5年度 上半期 3/15-3/20実施回_Aグループ',
      created_date: '2023/03/18',
      isActive: true,
    },
    {
      id: '1',
      name: '無題おもてうら分析レポート',
      status: '非公開',
      title: '',
      companyName: '関東シニア介護サービス株式会社',
      facilityName: 'ジャパンケアサービス西東京 居宅介護支援事業所',
      target_episode: '令和5年度 上半期 3/15-3/20実施回_Aグループ',
      created_date: '2023/03/18',
      isActive: true,
    },
    {
      id: '1',
      name: '無題おもてうら分析レポート',
      title: '',
      status: '非公開',
      companyName: '関東シニア介護サービス株式会社',
      facilityName: 'ジャパンケアサービス西東京 居宅介護支援事業所',
      target_episode: '令和5年度 上半期 3/15-3/20実施回_Aグループ',
      created_date: '2023/03/18',
      isActive: true,
    },
    {
      id: '1',
      name: '無題おもてうら分析レポート',
      title: '',
      status: '非公開',
      companyName: '関東シニア介護サービス株式会社',
      facilityName: 'ジャパンケアサービス西東京 居宅介護支援事業所',
      target_episode: '令和5年度 上半期 3/15-3/20実施回_Aグループ',
      created_date: '2023/03/18',
      isActive: true,
    },
    {
      id: '1',
      name: '無題おもてうら分析レポート',
      title: '',
      status: '非公開',
      companyName: '関東シニア介護サービス株式会社',
      facilityName: 'ジャパンケアサービス西東京 居宅介護支援事業所',
      target_episode: '令和5年度 上半期 3/15-3/20実施回_Aグループ',
      created_date: '2023/03/18',
      isActive: true,
    },
    {
      id: '1',
      name: '無題おもてうら分析レポート',
      title: '',
      status: '非公開',
      companyName: '関東シニア介護サービス株式会社',
      facilityName: 'ジャパンケアサービス西東京 居宅介護支援事業所',
      target_episode: '令和5年度 上半期 3/15-3/20実施回_Aグループ',
      created_date: '2023/03/18',
      isActive: true,
    },
    {
      id: '1',
      name: '無題おもてうら分析レポート',
      title: '',
      status: '非公開',
      companyName: '関東シニア介護サービス株式会社',
      facilityName: 'ジャパンケアサービス西東京 居宅介護支援事業所',
      target_episode: '令和5年度 上半期 3/15-3/20実施回_Aグループ',
      created_date: '2023/03/18',
      isActive: true,
    },
    {
      id: '2',
      name: '無題おもてうら分析レポート',
      title: '',
      status: '公開',
      companyName: '関東シニア介護サービス株式会社',
      facilityName: 'ジャパンケアサービス西東京 居宅介護支援事業所',
      target_episode: '令和5年度 上半期 3/15-3/20実施回_Aグループ',
      created_date: '2023/03/18',
      isActive: true,
    },
    {
      id: '3',
      title: '',
      name: '無題おもてうら分析レポート',
      status: '非公開',
      companyName: '関東シニア介護サービス株式会社',
      facilityName: 'ジャパンケアサービス西東京 居宅介護支援事業所',
      target_episode: '令和5年度 上半期 3/15-3/20実施回_Aグループ',
      created_date: '2023/03/18',
      isActive: true,
    },
    {
      id: '4',
      name: '無題おもてうら分析レポート',
      title: '',
      status: '非公開',
      companyName: '関東シニア介護サービス株式会社',
      facilityName: 'ジャパンケアサービス西東京 居宅介護支援事業所',
      target_episode: '令和5年度 上半期 3/15-3/20実施回_Aグループ',
      created_date: '2023/03/18',
      isActive: true,
    },
    {
      id: '5',
      name: '無題おもてうら分析レポート',
      title: '',
      status: '非公開',
      companyName: '関東シニア介護サービス株式会社',
      facilityName: 'ジャパンケアサービス西東京 居宅介護支援事業所',
      target_episode: '令和5年度 上半期 3/15-3/20実施回_Aグループ',
      created_date: '2023/03/18',
      isActive: true,
    },
  ];

  const handleClose = () => {
    setShow('');
  };

  const handleDeleteReport = () => {
    dispatch(
      deleteAnalysisReport({
        analysisReportId: itemReport?._id ?? '',
        facilityId: currentUser?.employee?.facilityId ?? '',
      }),
    );
    handleClose();
  };

  const handleCreateReport = () => {
    removeClientFacilityAndEventSelected();
    dispatch(setIsRenderCreateReport(false));
    dispatch(setClientSelect({ id: '', name: '' }));
    dispatch(setFacilitySelect({ id: '', name: '' }));
    dispatch(setEventSelect({ id: '', name: '' }));
    navigate(EPath.createAnalyzeOmoteraDiagnosisResult);
  };

  const handleGetDetailReport = (id: string) => {
    navigate(
      replacePathParams(EPath.analyzeReportDetail, {
        reportId: convertValueToString(id),
      }),
    );
  };

  return {
    t,
    dispatch,
    show,
    setShow,
    hasMore,
    loading,
    listAnalysis,
    listAnalyzeReport,
    titleTableAnalyzeRp,
    titleCoordinateAnalyzeRp,
    handleClose,
    itemReport,
    setItemReport,
    handleDeleteReport,
    handleCreateReport,
    currentDetailReport,
    occupations,
    isRenderCreateReport,
    listEmployee,
    handleGetDetailReport,
    highlightAxis16,
    ageSelect,
    dateSelect,
    roleSelect,
    occupationSelect,
    memoContent,
    checkBoxPdf,
    listNumberPageInputMemoCKEditor,
    listClientManagement,
    detailReport,
    status,
    roles,
    currentUser,
  };
};

export default useServices;
