import moment from 'moment';
import { weekDays } from './const';

export const compareDate = (date1: Date | string, date2: Date | string) => {
  const dateA = moment.utc(date1);
  const dateB = moment(date2);

  return dateA.isSame(dateB, 'day');
};

export const sortGroupChat = (listGroupChat: any[]) => {
  return listGroupChat.sort((a, b) => b.updateAt ?? 0 - a.updateAt ?? 0);
};

export const sortListEmployeeProgressByInterviewDate = (listEmployeeProgress: any[]) => {
  const listEmployeeProgressCopy = [...listEmployeeProgress];
  return listEmployeeProgressCopy.sort((a, b) => {
    const timeA = a.interviewStartTime
      ? new Date(a.interviewStartTime).getTime()
      : Number.MAX_SAFE_INTEGER;
    const timeB = b.interviewStartTime
      ? new Date(b.interviewStartTime).getTime()
      : Number.MAX_SAFE_INTEGER;
    return timeA - timeB;
  });
};

export const sortListEmployeeProgressByRegistrationDate = (listEmployeeProgress: any[]) => {
  const listEmployeeProgressCopy = [...listEmployeeProgress];
  return listEmployeeProgressCopy.sort((a, b) => {
    const timeA = a.createdAt ? new Date(a.createdAt).getTime() : 0;
    const timeB = b.createdAt ? new Date(b.createdAt).getTime() : 0;
    return timeB - timeA;
  });
};

export const getDay = (date: string) => {
  const dateTarget = new Date(date);
  return weekDays[dateTarget.getDay()].ja;
};

export const checkTimeForMark = (date: string | undefined): boolean => {  
  if (!date) return false;
  const lastActivityDate = new Date(date);
  lastActivityDate.setDate(lastActivityDate.getDate() + 90);
  return new Date() <= lastActivityDate;
};

export const getSex = (sex: string) => {
  switch (sex) {
    case '0':
      return '女性';
    case '1':
      return '男性';
    default:
      return 'その他 ';
  }
};

export const getAge = (date?: string): string => {
  return date ? moment().diff(moment(date, 'DD-MM-YYYY'), 'years').toString() : '';
};

export const convertArrayEmptyToString = (inputObject: any): any => {
  const resultObject = {};

  for (const key in inputObject) {
    if (Object.hasOwnProperty.call(inputObject, key)) {
      resultObject[key] =
        Array.isArray(inputObject[key]) && inputObject[key].length === 0 ? '' : inputObject[key];
    }
  }

  return resultObject;
};

export const convertArray = (inputObject: any): any => {
  const resultObject = {};

  for (const key in inputObject) {
    if (Object.hasOwnProperty.call(inputObject, key)) {
      resultObject[key] =
        Array.isArray(inputObject[key]) && inputObject[key].length === 0 ? [] : inputObject[key];
    }
  }

  return resultObject;
};
