import queryString from 'querystring';
import { axiosClient } from './axiosClient';
import { TOptionsQuery } from '../types/common';
import { TManagerManagement } from 'types/clientManagement/clientManagement';

export const managerApi = {
  getListManager: (params: TOptionsQuery<TManagerManagement>) => {
    const url = '/manager';
    const query = queryString.stringify(params);
    return axiosClient.get(`${url}?${query}`);
  },
  registerManager: (data: TManagerManagement) => {
    const url = '/manager';
    return axiosClient.post(url, data);
  },
  updateManager: (params: { id: string; data: TManagerManagement }) => {
    const url = `/manager/${params.id}`;
    return axiosClient.put(url, params.data);
  },
  deleteManager: (id: string) => {
    const url = `/manager/${id}`;
    return axiosClient.delete(url);
  },
  inActiveManager: (id: string) => {
    const url = `/admin/user/update/${id}/inactive`;
    return axiosClient.patch(url);
  },
  activeManager: (id: string) => {
    const url = `/admin/user/update/${id}/active`;
    return axiosClient.patch(url);
  },
  inviteManager: (id: string) => {
    const url = `/manager/${id}/invitation`;
    return axiosClient.post(url);
  },
  getInfoManager: (id: string) => {
    const url = `/manager/${id}`;
    return axiosClient.get(url);
  },
  updateInfoManager: (id: string, data: TManagerManagement) => {
    const url = `/manager/${id}/user-info`;
    return axiosClient.put(url, data);
  },
};
