import { axiosClient } from './axiosClient';
import queryString from 'querystring';
import { TOptionsQuery } from 'types/common';
import { TParams } from '../types/consultantInterview/consultantInterview';
import { TInterview } from '../types/consultant/consultant';
import { TOmoteuraDetail } from 'types/omoteura/omoteura';

export const omoteuraApi = {
  getListCategory: (eventId: string, employeeId: string) => {
    const url = `/omoteura/category/${eventId}/${employeeId}`;
    return axiosClient.get(url);
  },
  getListOmoteura: (params: TOptionsQuery<TParams>) => {
    const url = `/omoteura`;
    const query = queryString.stringify(params);
    return axiosClient.get(`${url}?${query}`);
  },
  getOmoteura: (eventId: string, employeeId: string) => {
    const url = `/omoteura/${employeeId}/${eventId}`;
    return axiosClient.get(url);
  },
  getListQuestion: () => {
    const url = `/omoteura/omoteura/question`;
    return axiosClient.get(url);
  },
  getAlert: (eventId: string, employeeId: string) => {
    const url = `/omoteura/alert/${eventId}/${employeeId}`;
    return axiosClient.get(url);
  },
  getListFeedbackCom: (params: TOptionsQuery<TParams>) => {
    const url = '/interview/com/feedbacks';
    const query = queryString.stringify(params);
    return axiosClient.get(`${url}?${query}`);
  },
  sendFeedbackFromCom: (send: string, data: { data: TInterview[] }) => {
    const url = `/interview/com/feedbacks?send=${send}`;
    return axiosClient.put(url, data);
  },
  updateOmoteura: (data: TOmoteuraDetail ) => {
    const url = `/omoteura/${data.employeeId}/${data.eventId}`;
    return axiosClient.put(url, data);
  },
  downloadFile: (params: { feedbackId: string; fileName: string }) => {
    const url = `/interview/getFiles/${params.feedbackId}/${params.fileName}`;
    return axiosClient.get(url, { responseType: 'blob' });
  },
};
