import React, { ReactNode } from 'react';

type TButtonType =
  | 'primary'
  | 'secondary'
  | 'third'
  | 'reservable'
  | 'underReservation'
  | 'notFbFinished'
  | 'completion'
  | 'cancel'
  | 'disabled'
  | 'mobile'
  | '';
type TButtonSize = 'medium' | 'small';

interface Props {
  textContent: string;
  className?: string;
  type?: 'button' | 'submit';
  iconBefore?: ReactNode;
  iconAfter?: ReactNode;
  size?: 'medium' | 'small';
  rounded?: 'md' | 'full';
  buttonType: TButtonType;
  onClick?: () => void;
  disabled?: boolean;
  quantity?: number;
}

const genClassType = (type: TButtonType, disabled?: boolean) => {
  switch (type) {
    case 'primary':
      return `${disabled ? 'bg-[rgba(255,66,24,0.2)]' : 'bg-primary'} text-white`;
    case 'third':
      return `${
        disabled ? 'opacity-[0.6] cursor-context-menu' : ''
      } bg-white text-text !font-bold border border-slate-gray`;
    case 'disabled':
      return `bg-light-gray rounded-lg text-medium-gray font-normal`;
    case 'reservable':
      return `border border-primary rounded-lg text-primary font-normal`;
    case 'underReservation':
      return `bg-primary text-white rounded-lg font-normal`;
    case 'notFbFinished':
      return `bg-darkBrown text-white rounded-lg font-normal`;
    case 'completion':
      return `bg-chart-border text-white rounded-lg font-normal`;
    case 'cancel':
      return `bg-error text-white rounded-lg font-normal`;
    case 'mobile':
      return `bg-[#AA8F00] text-white rounded-full font-normal`;
    default:
      return '';
  }
};

const genClassSize = (type: TButtonSize) => {
  let classGen = '';
  switch (type) {
    case 'medium':
      classGen = 'font-medium text-base py-2 px-4 gap-2';
      break;
    case 'small':
      classGen = 'text-sm py-1 px-2 gap-1';
      break;
    default:
      classGen = 'text-base py-2 px-4 gap-2';
  }
  return classGen;
};

const CommonButton: React.FC<Props> = (props: Props) => {
  const {
    textContent,
    className,
    type,
    iconBefore,
    iconAfter,
    buttonType,
    rounded,
    size = 'medium',
    onClick,
    disabled,
    quantity,
  } = props;

  const classType = genClassType(buttonType, disabled);
  const classSize = genClassSize(size);

  return (
    <button
      type={type ?? 'button'}
      className={`${className} ${classType} ${classSize} rounded-${rounded} flex items-center hover:scale-[0.97] transition-all ease-linear hover:transition-all ${
        disabled ? 'cursor-not-allowed' : ''
      }`}
      onClick={onClick}
      disabled={disabled ?? false}
    >
      {iconBefore}
      <div>
        <span
          className={`whitespace-nowrap ${
            textContent.split('\\n').length >= 2 && 'text-xs'
          } float-left ${buttonType === 'cancel' && '!whitespace-normal'}`}
        >
          {textContent.split('\\n')[0]}
        </span>
        <span className={`whitespace-nowrap ${textContent.split('\\n').length >= 2 && 'text-xs'}`}>
          {textContent.split('\\n')[1]}
        </span>
      </div>
      {iconAfter}
      {quantity !== undefined && (
        <span className="p-2 bg-disabled rounded-[56px] text-text">{quantity}</span>
      )}
    </button>
  );
};

export default CommonButton;
