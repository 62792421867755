import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { TSetListAction } from 'types/types';
import { employeeApi } from 'api/employee';
import {
  DEFAULT_PAGE,
  DEFAULT_PER_PAGE,
  DEFAULT_PER_PAGE_EMPLOYEE,
  TActionParams,
  TCallback,
  TOptionsQuery,
} from 'types/common';
import { setShowToast } from 'store/toast/toastSlice';
import { ETypeToast } from 'app/enum';
import { TEmployeeManagement } from 'types/clientManagement/clientManagement';
import { t } from 'i18next';
import { translations } from '../../locales/translations';
import { convertValueToNumber, getLabelError, listErrorStatus } from '../../helpers/funcs';
import { statusErrEmail } from '../../helpers/constant';
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  onSnapshot,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';
import { auth, db } from '../../services/firebase';
import { TComManagement } from '../../types/comManagement/comManagement';
import {
  createUserWithEmailAndPassword,
  deleteUser,
  signInWithEmailAndPassword,
} from 'firebase/auth';

export type EmployeeState = {
  loading: boolean;
  listEmployeeManagement: TEmployeeManagement[];
  total: number;
  page: number;
  emailErr: string;
  pathFileCsv: string;
  infoEmployee: TEmployeeManagement;
};

const initialState: EmployeeState = {
  loading: false,
  listEmployeeManagement: [],
  total: 0,
  page: 1,
  emailErr: '',
  pathFileCsv: '',
  infoEmployee: {},
};

export const DEFAULT_ORDER_BY_EMPLOYEE_MANAGEMENT = 'createdAt';
export const DEFAULT_ORDER_DIRECTION_EMPLOYEE_MANAGEMENT = 'desc';
export const generateEmployeeId = createAsyncThunk(
  'employee/getListEmployeeManagement',
  async (_) => {
    const response: AxiosResponse<any, any> = await employeeApi.generateEmployeeId();
    return response;
  },
);
export const getListEmployeeManagement = createAsyncThunk(
  'employee/getListEmployeeManagement',
  async (params: TOptionsQuery<TEmployeeManagement>, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    const { loadMore, ...rest } = params;
    const newParams: TOptionsQuery<TEmployeeManagement> = {
      ...rest,
      search: rest.search ?? '',
      orderBy: rest.orderBy ?? DEFAULT_ORDER_BY_EMPLOYEE_MANAGEMENT,
      orderDirection: rest.orderDirection ?? DEFAULT_ORDER_DIRECTION_EMPLOYEE_MANAGEMENT,
      page: rest.page ?? DEFAULT_PAGE,
      perPage: rest.perPage ?? DEFAULT_PER_PAGE_EMPLOYEE,
    };
    await employeeApi
      .getListEmployee(newParams)
      .then((response) => {
        thunkAPI.dispatch(
          setListEmployeeManagement({
            type: loadMore ? 'loadMore' : 'getList',
            employees: response.data.data,
            total: response.data._metadata.pagination.total,
            page: response.data._metadata.pagination.page,
          }),
        );
      })
      .catch((response) => {
        thunkAPI.dispatch(
          setShowToast({
            label: getLabelError(response.response.data.statusCode),
            type: ETypeToast.Error,
          }),
        );
        thunkAPI.dispatch(setLoading(false));
      });
    thunkAPI.dispatch(setLoading(false));
  },
);

export const registerEmployeeManagement = createAsyncThunk(
  'employee/registerEmployeeManagement',
  async (
    params: { actionParams: TActionParams<TEmployeeManagement>; handleClose: () => void },
    thunkAPI,
  ) => {
    thunkAPI.dispatch(setLoading(true));
    await employeeApi
      .registerEmployee(params.actionParams.data)
      .then(() => {
        thunkAPI.dispatch(
          getListEmployeeManagement({
            perPage: DEFAULT_PER_PAGE,
            clientId: params.actionParams.data.clientId,
          }),
        );
        thunkAPI.dispatch(
          setShowToast({
            label: t(translations.ID_MASTER.REGISTER_SUCCESS),
            type: ETypeToast.Success,
          }),
        );
        params.handleClose();
      })
      .catch((response) => {
        thunkAPI.dispatch(setEmailErr('そのメールアドレスは使用されています'));
        if (response.response.data.statusCode !== listErrorStatus.EMPLOYEE_INFORMATION_INVALID) {
          thunkAPI.dispatch(
            setShowToast({
              label: getLabelError(response.response.data.statusCode),
              type: ETypeToast.Error,
            }),
          );
        }
        thunkAPI.dispatch(setLoading(false));
      });

    const result = await createUserWithEmailAndPassword(
      auth,
      `${params.actionParams.data.employeeId}@employee.careercon` ?? '',
      '123456',
    );

    if (result) {
      const q = query(
        collection(db, 'user'),
        where('email', '==', `${params.actionParams.data.employeeId}@employee.careercon`),
      );
      const querySnapshot = await getDocs(q);
      if (querySnapshot.docs.length === 0) {
        await setDoc(doc(db, 'user', result.user.uid), {
          uid: result.user.uid,
          displayName: params.actionParams.data.name,
          email: `${params.actionParams.data.employeeId}@employee.careercon`,
          avatar: 'https://ps.w.org/user-avatar-reloaded/assets/icon-128x128.png?rev=2540745',
          role: 'employee',
        });
      }
    }
    await signInWithEmailAndPassword(
      auth,
      `${params.actionParams.data.employeeId}@employee.careercon`,
      '123456',
    );

    thunkAPI.dispatch(setLoading(false));
  },
);

export const getInfoEmployee = createAsyncThunk('client/getInfoEmployee', async (id: string) => {
  const response = await employeeApi.getInfoEmployee(id);
  return response.data.data;
});

export const updateEmployeeManagement = createAsyncThunk(
  'employee/updateEmployeeManagement',
  async (
    params: {
      id: string;
      data: TActionParams<TEmployeeManagement>;
      handleClose: () => void;
    } & TCallback,
    thunkAPI,
  ) => {
    thunkAPI.dispatch(setLoading(true));
    await employeeApi
      .updateEmployee({ id: params.id, data: params.data.data ?? params.data })
      .then((response: any) => {
        params.callback?.(response.data.data);
        const dataParam = params.data.data ?? params.data;
        thunkAPI.dispatch(
          setListEmployeeManagement({
            type: 'edit',
            employee: { ...params.data.data, _id: response.data.data._id },
          }),
        );
        thunkAPI.dispatch(
          setShowToast({
            label: t(translations.ID_MASTER.UPDATE_SUCCESS),
            type: ETypeToast.Success,
          }),
        );

        params.handleClose();
      })
      .catch((response) => {
        if (statusErrEmail.includes(response.response.data.statusCode)) {
          thunkAPI.dispatch(setEmailErr('このメールアドレスが使用されています。'));
        } else if (
          response.response.data.statusCode !== listErrorStatus.EMPLOYEE_EMAIL_EXIST_ERROR
        ) {
          thunkAPI.dispatch(
            setShowToast({
              label: getLabelError(response.response.data.statusCode),
              type: ETypeToast.Error,
            }),
          );
        }
        thunkAPI.dispatch(setLoading(false));
      });
    thunkAPI.dispatch(setLoading(false));
  },
);

export const deleteEmployeeManagement = createAsyncThunk(
  'employee/deleteEmployeeManagement',
  async (employee: TEmployeeManagement, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    await employeeApi
      .deleteEmployee(String(employee._id))
      .then(() => {
        thunkAPI.dispatch(setListEmployeeManagement({ type: 'delete', employee: employee }));
        thunkAPI.dispatch(
          setShowToast({
            label: t(translations.ID_MASTER.DELETE_SUCCESS),
            type: ETypeToast.Success,
          }),
        );
      })
      .catch((response) => {
        thunkAPI.dispatch(
          setShowToast({
            label: getLabelError(response.response.data.statusCode),
            type: ETypeToast.Error,
          }),
        );
        thunkAPI.dispatch(setLoading(false));
      });

    const q = query(
      collection(db, 'user'),
      where('email', '==', `${employee.employeeId}@employee.careercon`),
    );
    await getDocs(q).then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        const docRef = doc.ref;
        deleteDoc(docRef);
      });
    });

    try {
      if (employee.employeeId) {
        const userCredential = await signInWithEmailAndPassword(
          auth,
          `${employee.employeeId}@employee.careercon`,
          '123456',
        );
        const user = userCredential.user;
        await deleteUser(user);
      }
    } catch (error) {}

    thunkAPI.dispatch(setLoading(false));
  },
);

export const stopEmployeeManagement = createAsyncThunk(
  'employee/stopEmployeeManagement',
  async (employee: TEmployeeManagement, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    if (employee.isActive) {
      await employeeApi
        .inActiveEmployee(String(employee.userId))
        .then(() => {
          thunkAPI.dispatch(setListEmployeeManagement({ type: 'stop', employee: employee }));
          thunkAPI.dispatch(
            setShowToast({
              label: t(translations.ID_MASTER.INACTIVE_SUCCESS),
              type: ETypeToast.Success,
            }),
          );
        })
        .catch((response) => {
          thunkAPI.dispatch(
            setShowToast({
              label: getLabelError(response.response.data.statusCode),
              type: ETypeToast.Error,
            }),
          );
          thunkAPI.dispatch(setLoading(false));
        });
    } else {
      await employeeApi
        .activeEmployee(String(employee.userId))
        .then(() => {
          thunkAPI.dispatch(setListEmployeeManagement({ type: 'stop', employee: employee }));
          thunkAPI.dispatch(
            setShowToast({
              label: t(translations.ID_MASTER.ACTIVE_SUCCESS),
              type: ETypeToast.Success,
            }),
          );
        })
        .catch((response) => {
          thunkAPI.dispatch(
            setShowToast({
              label: getLabelError(response.response.data.statusCode),
              type: ETypeToast.Error,
            }),
          );
          thunkAPI.dispatch(setLoading(false));
        });
    }
    thunkAPI.dispatch(setLoading(false));
  },
);

export const updateListEmployeeFromFileCsv = createAsyncThunk(
  'employee/updateListEmployeeFromFileCsv',
  async (params: { file: File; clientId: string }, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    await employeeApi
      .updateListEmployeeFromFileCsv({
        file: params.file,
        clientId: params.clientId,
      })
      .then((response: any) => {
        thunkAPI.dispatch(
          setShowToast({
            label: `${t(translations.ID_MASTER.UPLOAD_SUCCESS)}${
              response.data.errorLine !== '' ? `.エラー行: ${response.data.errorLine}` : ''
            }`,
            type: ETypeToast.Success,
          }),
        );
        thunkAPI.dispatch(
          getListEmployeeManagement({
            perPage: DEFAULT_PER_PAGE_EMPLOYEE,
            clientId: params.clientId,
          }),
        );
      })
      .catch((response) => {
        thunkAPI.dispatch(
          setShowToast({
            label: response.response.data.message,
            type: ETypeToast.Error,
          }),
        );
        thunkAPI.dispatch(setLoading(false));
      });
    thunkAPI.dispatch(setLoading(false));
  },
);

export const resetPassword = createAsyncThunk(
  'employee/resetPassword',
  async (employeeId: string, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    await employeeApi
      .resetPW(employeeId)
      .then(() => {
        thunkAPI.dispatch(
          setShowToast({
            label: t(translations.ID_MASTER.PASSWORD_INITIALIZED),
            type: ETypeToast.Success,
          }),
        );
      })
      .catch((response) => {
        thunkAPI.dispatch(
          setShowToast({
            label: getLabelError(response.response.data.statusCode),
            type: ETypeToast.Error,
          }),
        );
        thunkAPI.dispatch(setLoading(false));
      });
    thunkAPI.dispatch(setLoading(false));
  },
);

const slice = createSlice({
  name: 'employee',
  initialState,
  reducers: {
    setListEmployeeManagement: (
      state,
      action: PayloadAction<{
        type: TSetListAction;
        employee?: TEmployeeManagement;
        employees?: TEmployeeManagement[];
        page?: number;
        total?: number;
      }>,
    ) => {
      const { type, employee, employees, total, page } = action.payload;
      if (employee) {
        switch (type) {
          case 'create':
            state.listEmployeeManagement = [
              { ...employee, isActive: true },
              ...state.listEmployeeManagement,
            ];
            break;
          case 'edit':
            state.listEmployeeManagement = state.listEmployeeManagement.map(
              (m: TEmployeeManagement) =>
                m._id === employee._id ? { ...employee, isActive: true } : m,
            );
            break;
          case 'delete':
            state.listEmployeeManagement = state.listEmployeeManagement.filter(
              (m: TEmployeeManagement) => m._id !== employee._id,
            );
            state.total = state.total - 1;
            break;
          case 'stop':
            state.listEmployeeManagement = state.listEmployeeManagement.map(
              (m: TEmployeeManagement) =>
                m._id === employee._id ? { ...employee, isActive: !employee.isActive } : m,
            );
        }
      }
      if (employees) {
        switch (type) {
          case 'getList':
            state.listEmployeeManagement = employees;
            break;
          case 'loadMore':
            state.listEmployeeManagement = [...state.listEmployeeManagement, ...employees];
            break;
        }

        state.page = page ?? DEFAULT_PAGE;
        state.total = convertValueToNumber(total);
      }
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setEmailErr: (state, action: PayloadAction<string>) => {
      state.emailErr = action.payload;
    },
    setPathFileCsv: (state, action: PayloadAction<string>) => {
      state.pathFileCsv = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getInfoEmployee.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getInfoEmployee.fulfilled,
      (state, action: PayloadAction<TEmployeeManagement>) => {
        state.loading = false;
        state.infoEmployee = action.payload;
      },
    );
  },
});

export const { actions, reducer: EmployeeManagementReducer } = slice;
export const { setListEmployeeManagement, setLoading, setEmailErr } = actions;
export default EmployeeManagementReducer;
