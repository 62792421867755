export const saveClientFacilityAndEventSelected = (
  clientSelect: { name: string; id: string },
  facilitySelect: { name: string; id: string },
  eventSelect: { name: string; id: string },
) => {
  localStorage.setItem('clientSelect', JSON.stringify(clientSelect));
  localStorage.setItem('facilitySelect', JSON.stringify(facilitySelect));
  localStorage.setItem('eventSelect', JSON.stringify(eventSelect));
};

export const removeClientFacilityAndEventSelected = () => {
  localStorage.removeItem('clientSelect');
  localStorage.removeItem('facilitySelect');
  localStorage.removeItem('eventSelect');
};

export const getClientSelect = () => {
  return localStorage.getItem('clientSelect');
};

export const getFacilitySelect = () => {
  return localStorage.getItem('facilitySelect');
};
export const getEventSelect = () => {
  return localStorage.getItem('eventSelect');
};

export const getReportId = () => {
  return localStorage.getItem('reportId');
};

export const saveReportId = (id: string) => {
  localStorage.setItem('reportId', id);
};

export const removeReportId = () => {
  localStorage.removeItem('reportId');
};
