import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { interviewerApi } from 'api/interviewerApi';
import { ETypeToast } from 'app/enum';
import { setShowToast } from 'store/toast/toastSlice';
import { TClientManagement } from 'types/clientManagement/clientManagement';
import {
  DEFAULT_PAGE,
  DEFAULT_PER_PAGE,
  TActionParams,
  TCallback,
  TOptionsQuery,
} from 'types/common';
import { TInterview } from 'types/consultant/consultant';
import { TInterviewerManagement } from 'types/interviewerManagement/interviewerManagement';
import { TSetListAction } from 'types/types';
import { t } from 'i18next';
import { translations } from '../../locales/translations';
import { consultantInterviewApi } from '../../api/consultantInterview';
import {
  createUserWithEmailAndPassword,
  deleteUser,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import { auth, db } from '../../services/firebase';
import {
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
  deleteDoc,
} from 'firebase/firestore';
import { getInfoConsultant } from '../consultantInterview/consultantInterview';
import { convertValueToNumber, getLabelError } from '../../helpers/funcs';
import { statusErrEmail } from '../../helpers/constant';
import { setEmployeeName, setFacilityName } from '../implements/implementsSlice';

export type InterviewerState = {
  loading: boolean;
  listInterviewerManagement: Array<TInterviewerManagement>;
  interviewDetail: TInterview;
  pathFeedBackFile: string;
  total: number;
  totalPage: number;
  page: number;
  emailErr: string;
  currentLoad: boolean;
};

const initialState: InterviewerState = {
  loading: false,
  listInterviewerManagement: [],
  interviewDetail: {},
  pathFeedBackFile: '',
  total: 0,
  totalPage: 1,
  page: 1,
  emailErr: '',
  currentLoad: false,
};

export const DEFAULT_ORDER_BY_CLIENT_MANAGEMENT = 'createdAt';
export const DEFAULT_ORDER_DIRECTION_CLIENT_MANAGEMENT = 'desc';
export const getListInterviewerManagement = createAsyncThunk(
  'interviewer/getListInterviewerManagement',
  async (params: TOptionsQuery<TInterviewerManagement>, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    const { loadMore, ...rest } = params;
    const newParams: TOptionsQuery<TClientManagement> = {
      ...rest,
      search: rest.search ?? '',
      orderBy: rest.orderBy ?? DEFAULT_ORDER_BY_CLIENT_MANAGEMENT,
      orderDirection: rest.orderDirection ?? DEFAULT_ORDER_DIRECTION_CLIENT_MANAGEMENT,
      page: params.page ?? DEFAULT_PAGE,
      perPage: 1000,
    };
    await interviewerApi
      .getListInterviewerManagement(newParams)
      .then((response) => {
        thunkAPI.dispatch(
          setListInterviewerManagement({
            type: loadMore ? 'loadMore' : 'getList',
            interviewers: response.data.data,
            total: response.data._pagination.total,
            totalPage: response.data._pagination.totalPage,
          }),
        );
        thunkAPI.dispatch(setLoading(false));
      })
      .catch((response) => {
        thunkAPI.dispatch(
          setShowToast({
            label: getLabelError(response.response.data.statusCode),
            type: ETypeToast.Error,
          }),
        );
        thunkAPI.dispatch(setLoading(false));
      });

    thunkAPI.dispatch(setLoading(false));
  },
);

export const registerInterviewerManagement = createAsyncThunk(
  'interviewer/registerClientManagement',
  async (
    params: { actionParams: TActionParams<TInterviewerManagement>; handleClose: () => void },
    thunkAPI,
  ) => {
    thunkAPI.dispatch(setLoading(true));
    await interviewerApi
      .registerInterviewer(params.actionParams.data)
      .then(async () => {
        params.handleClose();
        thunkAPI.dispatch(resetListInterviewer());
        thunkAPI.dispatch(setCurrentLoad(true));
        thunkAPI.dispatch(setPage(1));
        thunkAPI.dispatch(
          setShowToast({
            label: t(translations.ID_MASTER.REGISTER_SUCCESS),
            type: ETypeToast.Success,
          }),
        );
        thunkAPI.dispatch(setLoading(false));
        const result = await createUserWithEmailAndPassword(
          auth,
          params.actionParams.data.email ?? '',
          '123456',
        );

        if (result) {
          const q = query(
            collection(db, 'user'),
            where('email', '==', params.actionParams.data.email),
          );
          const querySnapshot = await getDocs(q);
          if (querySnapshot.docs.length === 0) {
            await setDoc(doc(db, 'user', result.user.uid), {
              uid: result.user.uid,
              displayName: params.actionParams.data.name,
              email: params.actionParams.data.email,
              avatar: 'https://ps.w.org/user-avatar-reloaded/assets/icon-128x128.png?rev=2540745',
              role: 'consultant',
            });
          }
        }
        await signInWithEmailAndPassword(auth, params.actionParams.data.email ?? '', '123456');
      })
      .catch((response) => {
        if (statusErrEmail.includes(response.response.data.statusCode)) {
          thunkAPI.dispatch(setEmailErr('このメールアドレスが使用されています。'));
        } else {
          thunkAPI.dispatch(
            setShowToast({
              label: getLabelError(response.response.data.statusCode),
              type: ETypeToast.Error,
            }),
          );
        }
        thunkAPI.dispatch(setLoading(false));
      });
    thunkAPI.dispatch(setLoading(false));
  },
);

export const updateInterviewerManagement = createAsyncThunk(
  'interviewer/updateClientManagement',
  async (
    params: { id: string; data: TActionParams<TInterviewerManagement>; handleClose: () => void },
    thunkAPI,
  ) => {
    thunkAPI.dispatch(setLoading(true));
    await interviewerApi
      .updateInterviewer({ id: params.id, data: params.data.data })
      .then(async (response: any) => {
        thunkAPI.dispatch(
          setListInterviewerManagement({
            type: 'edit',
            interviewer: { ...params.data.data, _id: response.data.data._id },
          }),
        );
        thunkAPI.dispatch(getInfoConsultant(params.id));
        thunkAPI.dispatch(
          setShowToast({
            label: t(translations.ID_MASTER.UPDATE_SUCCESS),
            type: ETypeToast.Success,
          }),
        );
        params.handleClose();
      })
      .catch((response) => {
        thunkAPI.dispatch(setLoading(false));
        if (statusErrEmail.includes(response.response.data.statusCode)) {
          thunkAPI.dispatch(setEmailErr('このメールアドレスが使用されています。'));
        } else {
          thunkAPI.dispatch(
            setShowToast({
              label: getLabelError(response.response.data.statusCode),
              type: ETypeToast.Error,
            }),
          );
        }
      });
    thunkAPI.dispatch(setLoading(false));
  },
);

export const deleteInterviewerManagement = createAsyncThunk(
  'interviewer/deleteClientManagement',
  async (interviewer: TInterviewerManagement, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    await interviewerApi
      .deleteInterviewer(String(interviewer._id))
      .then(() => {
        thunkAPI.dispatch(
          setListInterviewerManagement({ type: 'delete', interviewer: interviewer }),
        );
        thunkAPI.dispatch(
          setShowToast({
            label: t(translations.ID_MASTER.DELETE_SUCCESS),
            type: ETypeToast.Success,
          }),
        );
        const q = query(collection(db, 'user'), where('email', '==', interviewer.email));
        getDocs(q).then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const docRef = doc.ref;
            deleteDoc(docRef);
          });
        });
      })
      .catch((response) => {
        thunkAPI.dispatch(setLoading(false));
        thunkAPI.dispatch(
          setShowToast({
            label: getLabelError(response.response.data.statusCode),
            type: ETypeToast.Error,
          }),
        );
      });

    try {
      if (interviewer.email) {
        const userCredential = await signInWithEmailAndPassword(auth, interviewer.email, '123456');
        const user = userCredential.user;
        await deleteUser(user);
      }
    } catch (error) {}

    thunkAPI.dispatch(setLoading(false));
  },
);

export const stopInterviewerManagement = createAsyncThunk(
  'interviewer/stopClientManagement',
  async (interviewer: TInterviewerManagement, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    if (interviewer.isActive) {
      await interviewerApi
        .inActiveInterviewer(String(interviewer.userId))
        .then(() => {
          thunkAPI.dispatch(
            setListInterviewerManagement({ type: 'stop', interviewer: interviewer }),
          );
          thunkAPI.dispatch(
            setShowToast({
              label: t(translations.ID_MASTER.INACTIVE_SUCCESS),
              type: ETypeToast.Success,
            }),
          );
        })
        .catch((response) => {
          thunkAPI.dispatch(setLoading(false));
          thunkAPI.dispatch(
            setShowToast({
              label: getLabelError(response.response.data.statusCode),
              type: ETypeToast.Error,
            }),
          );
        });
    } else {
      await interviewerApi
        .activeInterviewer(String(interviewer.userId))
        .then(() => {
          thunkAPI.dispatch(
            setListInterviewerManagement({ type: 'stop', interviewer: interviewer }),
          );
          thunkAPI.dispatch(
            setShowToast({
              label: t(translations.ID_MASTER.ACTIVE_SUCCESS),
              type: ETypeToast.Success,
            }),
          );
        })
        .catch((response) => {
          thunkAPI.dispatch(setLoading(false));
          thunkAPI.dispatch(
            setShowToast({
              label: getLabelError(response.response.data.statusCode),
              type: ETypeToast.Error,
            }),
          );
        });
    }
    thunkAPI.dispatch(setLoading(false));
  },
);

export const getInterviewDetail = createAsyncThunk(
  'interviewer/getInterviewDetail',
  async (id: string, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    await consultantInterviewApi.getInterviewDetail(String(id)).then((res) => {
      thunkAPI.dispatch(setInterviewDetail(res.data.data));
      thunkAPI.dispatch(setFacilityName(res.data.data.facilityName));
      thunkAPI.dispatch(setEmployeeName(res.data.data.employeeName));
    });
    thunkAPI.dispatch(setLoading(false));
  },
);

export const updateInterviewDetail = createAsyncThunk(
  'interviewer/updateInterviewDetail',
  async (params: { id: string; data: TInterview }, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    await consultantInterviewApi
      .updateInterviewDetail({ id: params.id, data: params.data })
      .then(() => {
        thunkAPI.dispatch(getInterviewDetail(params.id));
        thunkAPI.dispatch(
          setShowToast({
            label: params.data.isSave
              ? 'フィードバックが保存されました'
              : t(translations.ID_MASTER.SEND_FEEDBACK_SUCCESS),
            type: ETypeToast.Success,
          }),
        );
      })
      .catch((response) => {
        thunkAPI.dispatch(setLoading(false));
        thunkAPI.dispatch(
          setShowToast({
            label: getLabelError(response.response.data.statusCode),
            type: ETypeToast.Error,
          }),
        );
      });
    thunkAPI.dispatch(setLoading(false));
  },
);

export const uploadFeedBackFile = createAsyncThunk(
  'interviewer/uploadFeedBackFile',
  async (params: { file: File; id: string } & TCallback, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    await consultantInterviewApi
      .uploadFeedBackFile(params.id, params.file)
      .then((response: any) => {
        thunkAPI.dispatch(setPathFeedBackFile(response.data.data.uploadedPath));
        thunkAPI.dispatch(
          setShowToast({
            label: t(translations.ID_MASTER.UPLOAD_FILE_FEEDBACK),
            type: ETypeToast.Success,
          }),
        );
        params.callback?.();
      })
      .catch((response) => {
        thunkAPI.dispatch(setLoading(false));
        if (response?.response?.data?.statusCode) {
          thunkAPI.dispatch(
            setShowToast({
              label: getLabelError(response.response.data.statusCode),
              type: ETypeToast.Error,
            }),
          );
        } else {
          thunkAPI.dispatch(
            setShowToast({
              label: 'ファイルが容量を超えています。',
              type: ETypeToast.Error,
            }),
          );
        }
      });
    thunkAPI.dispatch(setLoading(false));
  },
);

export const inviteInterviewerManagement = createAsyncThunk(
  'interviewer/inviteInterviewerManagement',
  async ({ id, param }: any, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    await interviewerApi
      .inviteInterviewer(id)
      .then(() => {
        thunkAPI.dispatch(
          setShowToast({
            label: '招待メールが送信されました。',
            type: ETypeToast.Success,
          }),
        );
        thunkAPI.dispatch(getListInterviewerManagement({}));
      })
      .catch((response) => {
        thunkAPI.dispatch(setLoading(false));
        thunkAPI.dispatch(
          setShowToast({
            label: getLabelError(response.response.data.statusCode),
            type: ETypeToast.Error,
          }),
        );
      });
    thunkAPI.dispatch(setLoading(false));
  },
);

const slice = createSlice({
  name: 'interviewer',
  initialState,
  reducers: {
    setListInterviewerManagement: (
      state,
      action: PayloadAction<{
        type: TSetListAction;
        interviewer?: TInterviewerManagement;
        interviewers?: TInterviewerManagement[];
        totalPage?: number;
        total?: number;
      }>,
    ) => {
      const { type, interviewer, interviewers, total, totalPage } = action.payload;

      if (interviewer) {
        switch (type) {
          case 'create':
            state.listInterviewerManagement = [
              { ...interviewer, isActive: true },
              ...state.listInterviewerManagement,
            ];
            break;
          case 'edit':
            state.listInterviewerManagement = state.listInterviewerManagement.map(
              (c: TClientManagement) =>
                c._id === interviewer._id ? { ...interviewer, isActive: true } : c,
            );
            break;
          case 'delete':
            state.listInterviewerManagement = state.listInterviewerManagement.filter(
              (c: TClientManagement) => c._id !== interviewer._id,
            );
            state.total = state.total - 1;
            break;
          case 'stop':
            state.listInterviewerManagement = state.listInterviewerManagement.map(
              (c: TClientManagement) =>
                c._id === interviewer._id ? { ...interviewer, isActive: !interviewer.isActive } : c,
            );
        }
      }
      if (interviewers) {
        switch (type) {
          case 'getList':
            state.listInterviewerManagement = interviewers;
            break;
          case 'loadMore':
            state.listInterviewerManagement = [...state.listInterviewerManagement, ...interviewers];
            break;
        }

        state.totalPage = convertValueToNumber(totalPage);
        state.total = convertValueToNumber(total);
      }
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    resetListInterviewer: (state) => {
      state.listInterviewerManagement = [];
    },
    setInterviewDetail: (state, action: PayloadAction<TInterview>) => {
      state.interviewDetail = action.payload;
    },
    setPathFeedBackFile: (state, action: PayloadAction<string>) => {
      state.pathFeedBackFile = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setEmailErr: (state, action: PayloadAction<string>) => {
      state.emailErr = action.payload;
    },
    setCurrentLoad: (state, action: PayloadAction<boolean>) => {
      state.currentLoad = action.payload;
    },
  },
});

export const { actions, reducer: InterviewerManagementReducer } = slice;
export const {
  setListInterviewerManagement,
  setLoading,
  resetListInterviewer,
  setInterviewDetail,
  setPathFeedBackFile,
  setPage,
  setEmailErr,
  setCurrentLoad,
} = actions;
export default InterviewerManagementReducer;
