import { TFilterCompareBetween, filterCompare, reportData } from 'types/analysis/analysis';
import { axiosClient } from './axiosClient';
import queryString from 'querystring';
import { TOptionsQuery } from '../types/common';

const analysisApi = {
  getListAnalysis: (params: TOptionsQuery) => {
    const url = `/analysis-report/list-analysis-reports?facilityId=${params.facilityId}`;
    return axiosClient.get(url);
  },
  getListEventByClientAndFacility: (
    clientId: string,
    facilityId: string,
    isEndedEvent: boolean,
  ) => {
    const url = `/analysis-report/${clientId}/${facilityId}/events?isEndedEvent=${isEndedEvent}`;
    return axiosClient.get(url);
  },
  getAnalysisReport: (clientId: string, facilityId: string, eventId: string) => {
    const url = `/analysis-report/report/${clientId}/${facilityId}/${eventId}`;
    return axiosClient.get(url);
  },
  getDetailAnalysisReportFiltered: (
    clientId: string,
    facilityId: string,
    eventId: string,
    params: {
      date: string[];
      occupation: string[];
      age: string[];
    },
  ) => {
    const url = `/analysis-report/report/${clientId}/${facilityId}/${eventId}/employees/16-coordinates`;
    return axiosClient.get(url, { params });
  },
  deleteAnalysisReport: (reportId: string) => {
    const url = `/analysis-report/${reportId}`;
    return axiosClient.delete(url);
  },
  uploadImage: (file: File) => {
    const url = `/analysis-report/uploadFile`;

    const formData = new FormData();
    formData.append('file', file);

    return axiosClient.post(url, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  },
  getUrlImage: (path: any) => {
    const url = `/analysis-report/getFile?fileName=${path}`;
    return axiosClient.get(url);
  },
  saveReport: (data: reportData) => {
    const url = `/analysis-report/report`;
    return axiosClient.post(url, data);
  },
  editReport: (data: reportData, idReport: string) => {
    const url = `/analysis-report/${idReport}`;
    return axiosClient.put(url, data);
  },
  getDetailReport: (id: string, isClient: boolean) => {
    const url = `/analysis-report/report/${id}?isClient=${isClient}`;
    return axiosClient.get(url);
  },
  filterAxisCompare: (data: {
    filterCompare: filterCompare;
    params: {
      clientId: string;
      facilityId: string;
      eventId: string;
    };
  }) => {
    const query = queryString.stringify(data.filterCompare);
    const url = `/analysis-report/report/${data.params.clientId}/${data.params.facilityId}/${data.params.eventId}/employees/16-coordinates-compare`;
    return axiosClient.get(`${url}?${query}`);
  },
  filterAxisCompareCustom: (filterCompare: TFilterCompareBetween) => {
    const query = queryString.stringify(filterCompare);
    const url = `/analysis-report/report/employees/16-coordinates-compare-custom`;
    return axiosClient.get(`${url}?${query}`);
  },
  getOccupationCompare: (params: { clientId: string; facilityId: string; eventId: string }) => {
    const url = `analysis-report/occupation?clientId=${params.clientId}&facilityId=${params.facilityId}&eventId=${params.eventId}`;
    return axiosClient.get(url);
  },
  getRoleCompare: (params: { clientId: string; facilityId: string; eventId: string }) => {
    const url = `analysis-report/roles?clientId=${params.clientId}&facilityId=${params.facilityId}&eventId=${params.eventId}`;
    return axiosClient.get(url);
  },
  filterReportFourCategory: (
    clientId: string,
    facilityId: string,
    eventId: string,
    params: {
      date: string[];
      occupation: string[];
      age: string[];
    },
  ) => {
    const url = `/analysis-report/report/${clientId}/${facilityId}/${eventId}/employees/4-category`;
    return axiosClient.get(url, { params });
  },
  filterAlert: (
    clientId: string,
    facilityId: string,
    eventId: string,
    params: {
      date: string[];
      occupation: string[];
      age: string[];
    },
  ) => {
    const url = `/analysis-report/report/${clientId}/${facilityId}/${eventId}/employees/alert`;
    return axiosClient.get(url, { params });
  },
  filterFulfillment: (
    clientId: string,
    facilityId: string,
    eventId: string,
    params: {
      date: string;
      occupation: string;
      age: string;
      typePoint: string;
    },
  ) => {
    const url = `/analysis-report/report/${clientId}/${facilityId}/${eventId}/employees/fulfillment`;
    return axiosClient.get(url, { params });
  },
  publicReport: (id: string, type: string) => {
    const url = `/analysis-report/status-report/${id}?type=${type}`;
    return axiosClient.get(url);
  },
  getListReportClient: (id: string) => {
    const url = `/analysis-report/list-report/client/${id}`;
    return axiosClient.get(url);
  },
  getDataCsv: (clientId: string, facilityId: string, eventId: string) => {
    const url = `/analysis-report/exportCsv/${clientId}/${facilityId}/${eventId}`;
    return axiosClient.get(url);
  },
};

export default analysisApi;
