import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

import { getUserFromCookie, getUserToChat } from 'helpers/cookies';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import { auth, db } from '../../../services/firebase';
import {
  arrayUnion,
  collection,
  doc,
  getDocs,
  onSnapshot,
  query,
  setDoc,
  updateDoc,
  where,
  Timestamp,
} from 'firebase/firestore';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentUserChat } from '../../../store/auth/authSlice';
import { RootState } from '../../../types';
import { v4 as uuid } from 'uuid';
import { TChat } from '../../../types/types';
import { CGroup, CUser } from '../../../types/consultant/consultant';
import { axiosClient } from '../../../api/axiosClient';
import { sendEmailForConsultant } from '../Layout/SideBar';
import { sortGroupChat } from '../../../utils/helper';
import { messDefaultConsultant, nameToHanas } from '../../../helpers/constant';
import { GroupedItem, Item } from './chatType';

const useServices = () => {
  const currentUserLocal = getUserFromCookie();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const currentUser = getUserToChat();
  const [listUsers, setListUsers] = useState<Array<CUser>>([]);
  const [listDataGroup, setListDataGroup] = useState<Array<CGroup>>([]);
  const [listDataGroupSorted, setListDataGroupSorted] = useState<Array<any>>([]);
  const [listDirectChat, setListDirectChat] = useState<Array<CGroup>>([]);
  const [listUnread, setListUnread] = useState<Array<any>>([]);
  const [listNotifications, setListNotifications] = useState<Array<any>>([]);
  const [listNotificationsGroups, setListNotificationsGroups] = useState<Array<any>>([]);
  const [listMessage, setListMessage] = useState([]);
  const [userChat, setUserChat] = useState<any>();
  const [groupChat, setGroupChat] = useState<any>();
  const [textInput, setTextInput] = useState('');
  const { currentUserChat } = useSelector((state: RootState) => state.auth);
  const [isShowCreateChat, setIsShowCreateChat] = useState(false);
  const [isShowBtn, setIsShowBtn] = useState(false);
  const [showCreateChat, setShowCreateChat] = useState<TChat>('');
  const [isEdit, setIsEdit] = useState(false);
  const [idEdit, setIdEdit] = useState('');
  const [listUserNew, setListUserNew] = useState<Array<CUser>>([]);
  const [listGroupChatFromBE, setListGroupChatFromBE] = useState<any>([]);
  const [listGroupNameSorted, setListGroupNameSorted] = useState<any>([]);

  function processArray(array: any): (Item | GroupedItem)[] {
    let groupMap: { [key: string]: Item[] } = {};
    let result: (Item | GroupedItem)[] = [];
    let consultantGroup: Item[] = [];

    array.forEach((item) => {
      if (!item.updateAt) {
        item.updateAt = item.createAt;
      }
      if (item.nameGroupChat) {
        if (!groupMap[item.nameGroupChat]) {
          groupMap[item.nameGroupChat] = [];
        }
        groupMap[item.nameGroupChat].push(item);
      } else if (
        item.membersInfo.find((member) => member.role === 'consultant') ||
        item.type === 'consultant'
      ) {
        consultantGroup.push(item);
      } else {
        result.push(item);
      }
    });

    for (let groupName in groupMap) {
      let groupItems = groupMap[groupName];

      groupItems.sort((a, b) => new Date(b.updateAt).getTime() - new Date(a.updateAt).getTime());

      let maxUpdateAt = groupItems[0].updateAt;

      let newGroupItem: GroupedItem = {
        nameGroupChat: groupName,
        updateAt: maxUpdateAt,
        items: groupItems,
      };

      result.push(newGroupItem);
    }

    if (consultantGroup.length > 0) {
      consultantGroup.sort(
        (a, b) => new Date(b.updateAt).getTime() - new Date(a.updateAt).getTime(),
      );

      let maxUpdateAt = consultantGroup[0].updateAt;

      let consultantGroupItem: GroupedItem = {
        nameGroupChat: '面談員',
        updateAt: maxUpdateAt,
        items: consultantGroup,
      };

      result.push(consultantGroupItem);
    }

    result.sort((a, b) => new Date(b.updateAt).getTime() - new Date(a.updateAt).getTime());

    return result;
  }

  const checkGroupWithCom = async () => {
    try {
      const groupUuid = `${currentUserLocal.consultant._id}-com`;
      const groupName = currentUserLocal.consultant.name;
      const q = query(collection(db, 'groupChats'), where('uid', '==', groupUuid));

      onSnapshot(q, async (querySnapshot) => {
        const group: any[] = [];
        querySnapshot.forEach((doc) => {
          group.push(doc.data());
        });

        if (!group || group.length === 0) {
          const q = query(collection(db, 'user'), where('role', '==', 'com'));
          onSnapshot(q, async (querySnapshot) => {
            const listMembers: any[] = [];
            querySnapshot.forEach((doc) => {
              listMembers.push(doc.data());
            });

            const membersInfo = listMembers.map((member) => ({
              avatar: member?.avatar,
              uid: member?.uid,
              displayName: member?.displayName,
              role: member?.role ?? 'unknown',
              email: member?.email,
            }));

            const idMess = uuid();
            if (currentUserChat?.uid) {
              await setDoc(doc(db, 'groupChats', groupUuid), {
                uid: groupUuid,
                groupName: groupName,
                members: [...listMembers.map((item) => item?.uid), currentUserChat?.uid],
                system: true,
                membersInfo: [
                  ...membersInfo,
                  {
                    avatar: currentUserChat?.avatar,
                    uid: currentUserChat?.uid,
                    displayName: currentUserChat?.displayName,
                    role: currentUserChat?.role ?? 'unknown',
                    email: currentUserChat?.email,
                  },
                ],
                messages: [
                  {
                    date: Timestamp.now().toMillis(),
                    id: idMess,
                    senderId: '',
                    system: true,
                    text: messDefaultConsultant,
                  },
                ],
                type: 'consultant',
                createAt: Timestamp.now().toMillis(),
                updateAt: Timestamp.now().toMillis(),
              });
            }
          });
        } else {
          setGroupChat(group[0]);
          setListMessage(group[0].messages);
        }
      });
    } catch (err) {}
  };

  useEffect(() => {
    const filteredGroups = listDataGroup.filter(
      (group) =>
        group.membersInfo.some((member) => member.role === 'employee'),
    );

    const emailEmployeesInGroups = filteredGroups.map((group) => {
      const employeeEmails = group.membersInfo
        .filter((member) => member.role === 'employee')
        .map((employee) => employee.email);
      const ids = employeeEmails.map((email) => {
        const id = email.split('@')[0];
        return id;
      });
      return ids[0];
    });

    axiosClient
      .post('/employee/chat/get-group-name', { employeeId: emailEmployeesInGroups })
      .then((res: any) => setListGroupChatFromBE(res.data.data))
      .catch((err) => console.log(err));
  }, [listDataGroup]);

  useEffect(() => {
    if (listGroupChatFromBE.length > 0) {
      const listSetGroupNameSortedFromBE = new Set(
        listGroupChatFromBE.map((item) => item.nameGroupChat),
      );
      const listGroupNameSortedFromBE = Array.from(listSetGroupNameSortedFromBE);
      setListGroupNameSorted(listGroupNameSortedFromBE);
      const updatedListDataGroup = listDataGroup.map((dataGroup) => {
        const matchedGroup = listGroupChatFromBE.find((group) => {
          const targetEmail = `${group.employeeId}@employee.careercon`;
          return dataGroup.membersInfo.some((member) => member.email === targetEmail);
        });

        if (matchedGroup) {
          return {
            ...dataGroup,
            nameGroupChat: matchedGroup.nameGroupChat,
          };
        }
        return dataGroup;
      });

      setListDataGroupSorted(processArray(updatedListDataGroup));
    } else setListDataGroupSorted(listDataGroup);
  }, [listGroupChatFromBE]);

  useEffect(() => {
    checkAccount();
  }, []);

  useEffect(() => {
    const q4 = query(collection(db, 'user'));

    onSnapshot(q4, (querySnapshot) => {
      const list: any = [];
      querySnapshot.forEach((doc) => {
        list.push(doc.data());
      });
      setListUserNew(list);
    });
  }, []);

  const registerAccount = async (email: string) => {
    try {
      const result = await createUserWithEmailAndPassword(auth, email, '123456');
      if (result) {
        const q = query(collection(db, 'user'), where('email', '==', email));
        const querySnapshot = await getDocs(q);
        if (querySnapshot.docs.length === 0) {
          await setDoc(doc(db, 'user', result.user.uid), {
            uid: result.user.uid,
            displayName: currentUser.name,
            email: currentUser.email,
            avatar: 'https://ps.w.org/user-avatar-reloaded/assets/icon-128x128.png?rev=2540745',
          });
        }
      }

      return result;
    } catch (err) {}
  };

  const login = async (email) => {
    try {
      return await signInWithEmailAndPassword(auth, email, '123456');
    } catch (err) {}
  };

  const checkAccount = async (): Promise<any> => {
    try {
      const account = await login(currentUser?.email);
      if (!account) {
        await registerAccount(currentUser?.email);
      }

      const q = query(collection(db, 'user'), where('email', '==', currentUser?.email));
      const resultUser = await getDocs(q);
      const userInfo = resultUser.docs[0].data();
      dispatch(setCurrentUserChat(userInfo));
      // get all member in app
      const listUser: any = await getListUser(userInfo);
      if (listUser) {
        setListUsers(
          listUser.docs.map((item) => ({
            ...item.data(),
            key: item?.uid,
          })),
        );
      }
    } catch (err) {}
  };

  useEffect(() => {
    if (currentUserChat.uid) {
      setIsLoading(true);
      // get all group chat
      const q = query(
        collection(db, 'groupChats'),
        where('members', 'array-contains', currentUserChat.uid),
      );

      const unSub = onSnapshot(q, (querySnapshot) => {
        const listGroup: any = [];
        querySnapshot.forEach((doc) => {
          listGroup.push(doc.data());
        });

        setListDataGroup(sortGroupChat(listGroup));
        setIsLoading(false);
      });
      // get all direct chat
      const q2 = query(
        collection(db, 'chats'),
        where('members', 'array-contains', currentUserChat.uid),
      );

      const unSubChat = onSnapshot(q2, (querySnapshot) => {
        const listDirect: any = [];
        querySnapshot.forEach((doc) => {
          listDirect.push(doc.data());
        });

        setListDirectChat(sortGroupChat(listDirect));
        setIsLoading(false);
      });

      // get unread
      const q3 = query(collection(db, 'unReads'), where('user', '==', currentUserChat.uid));

      const unReads = onSnapshot(q3, (querySnapshot) => {
        const listUnread: any = [];
        querySnapshot.forEach((doc) => {
          listUnread.push(doc.data());
        });

        setListUnread(listUnread);
        setIsLoading(false);
      });

      // get notifications
      const q4 = query(collection(db, 'notifications'));

      const notifications = onSnapshot(q4, (querySnapshot) => {
        const listNotifications: any = [];
        querySnapshot.forEach((doc) => {
          listNotifications.push(doc.data());
        });
        setListNotifications(listNotifications);
        setIsLoading(false);
      });

      const q5 = query(collection(db, 'notificationsGroups'));

      const notificationsGroups = onSnapshot(q5, (querySnapshot) => {
        const listNotificationsGroups: any = [];
        querySnapshot.forEach((doc) => {
          listNotificationsGroups.push(doc.data());
        });
        setListNotificationsGroups(listNotificationsGroups);
        setIsLoading(false);
      });

      return () => {
        unSub();
        unSubChat();
        unReads();
        notifications();
        notificationsGroups();
      };
    }
  }, [currentUserChat.uid]);

  const getListUser = async (userInfo) => {
    const q = query(collection(db, 'user'), where('email', '!=', userInfo.email));
    return await getDocs(q);
  };

  useEffect(() => {
    if (userChat) {
      const userId = userChat.members.find((item) => item !== currentUserChat.uid);
      const combinedId =
        currentUserChat.uid > userId ? currentUserChat.uid + userId : userId + currentUserChat.uid;
      const unSub = onSnapshot(doc(db, 'chats', combinedId), (doc) => {
        doc.exists() && setListMessage(doc.data().messages);
      });

      return () => {
        unSub();
      };
    } else {
      setListMessage([]);
    }
  }, [userChat?.uid]);

  useEffect(() => {
    if (groupChat) {
      const unSub = onSnapshot(doc(db, 'groupChats', groupChat?.uid), (doc) => {
        doc.exists() && setListMessage(doc.data().messages);
        if (doc.data()?.members.includes(currentUserChat.uid)) {
          setGroupChat(doc.data());
        } else {
          setGroupChat(undefined);
        }
      });

      return () => {
        unSub();
      };
    } else {
      setListMessage([]);
    }
  }, [groupChat?.uid]);

  const handlerChat = async (direct) => {
    setGroupChat(null);
    setUserChat(direct);
    setTextInput('');
    await Read(direct.uid);
  };

  const sendMessage = async () => {
    // send message
    if (!textInput) {
      return;
    }

    if (userChat) {
      const userId = userChat.members.find((item) => item !== currentUserChat.uid);
      const combinedId =
        currentUserChat.uid > userId ? currentUserChat.uid + userId : userId + currentUserChat.uid;

      try {
        setTextInput('');
        await updateDoc(doc(db, 'chats', combinedId), {
          messages: arrayUnion({
            id: uuid(),
            text: textInput.trim(),
            senderId: currentUserChat.uid,
            date: Timestamp.now().toMillis(),
          }),
          updateAt: Timestamp.now().toMillis(),
        });
        // push unread

        await setDoc(doc(db, 'notifications', userId), {
          message: textInput.trim(),
          createAt: Timestamp.now().toMillis(),
          sendTo: userId,
          sendToEmail: listUsers.find((x) => x.uid === userId)?.email,
          senderName: currentUserChat?.displayName,
          senderEmail: currentUserChat?.email,
          senderAvatar: currentUserChat?.avatar,
          hasSendNoti: true,
          role: currentUserChat?.role,
        });

        await pushUnread(combinedId);
        if (currentUserChat.role === 'com') {
          await sendEmailForConsultant(
            textInput.trim(),
            listUsers.find((x) => x.uid === userId)?.email ?? '',
          );
        }
      } catch (err) {}
    }

    if (groupChat) {
      try {
        if (groupChat.membersInfo.some((x) => x.role === 'employee')) {
          await axiosClient.post('/noti-msg/send', {
            msg: textInput,
            employeeId: groupChat.membersInfo
              .find((x) => x.role === 'employee')
              .email.split('@')[0],
            title: nameToHanas,
          });
        }

        await axiosClient.post('/noti-msg/sendNotiGroup', {
          msg: textInput,
          emails: groupChat.membersInfo
            .filter((item) => item.email !== currentUserChat.email)
            .map((item) => item.email),
          title: currentUserChat.displayName,
        });

        const isCom = currentUserChat.role === 'com';
        const membersIdInGroups = groupChat.members.filter(
          (member) => member !== currentUserChat.uid,
        );

        setTextInput('');
        await updateDoc(doc(db, 'groupChats', groupChat.uid), {
          messages: arrayUnion({
            id: uuid(),
            text: textInput.trim(),
            senderId: currentUserChat.uid,
            date: Timestamp.now().toMillis(),
          }),
          updateAt: Timestamp.now().toMillis(),
          createAt: Timestamp.now().toMillis(),
          membersSendNoti: groupChat.members.filter((member) => member !== currentUserChat.uid),
        });

        // push unread
        await pushUnread(groupChat.uid);

        if (isCom) {
          for (const memberId of membersIdInGroups) {
            const infoMember = groupChat.membersInfo.find(
              (info) => info.uid === memberId && info.role === 'consultant',
            );

            infoMember && (await sendEmailForConsultant(textInput.trim(), infoMember.email));
          }
        }
      } catch (err) {}
    }
  };

  const deleteMessage = async (id: string) => {
    if (userChat) {
      const userId = userChat.members.find((item) => item !== currentUserChat.uid);
      const combinedId =
        currentUserChat.uid > userId ? currentUserChat.uid + userId : userId + currentUserChat.uid;

      await updateDoc(doc(db, 'chats', combinedId), {
        messages: listMessage.filter((item: any) => item.id !== id),
        createAt: Timestamp.now().toMillis(),
      });
    }

    if (groupChat) {
      await updateDoc(doc(db, 'groupChats', groupChat.uid), {
        messages: listMessage.filter((item: any) => item.id !== id),
        createAt: Timestamp.now().toMillis(),
      });
    }
  };

  const editMessage = async (text: string, id: string) => {
    setIsEdit(true);
    setTextInput(text);
    setIdEdit(id);
  };

  const sendEditMessage = async () => {
    const newListMessage = listMessage.map((item: any) => {
      if (item.id === idEdit) {
        return {
          date: item.date,
          id: item.id,
          senderId: item.senderId,
          text: textInput.trim(),
          isEdit: true,
        };
      } else {
        return item;
      }
    });

    if (userChat) {
      const userId = userChat.members.find((item) => item !== currentUserChat.uid);
      const combinedId =
        currentUserChat.uid > userId ? currentUserChat.uid + userId : userId + currentUserChat.uid;

      await updateDoc(doc(db, 'chats', combinedId), {
        messages: newListMessage,
        updateAt: Timestamp.now().toMillis(),
      });
      await pushUnread(combinedId);
    }

    if (groupChat) {
      await updateDoc(doc(db, 'groupChats', groupChat.uid), {
        messages: newListMessage,
        createAt: Timestamp.now().toMillis(),
      });
      await pushUnread(groupChat.uid);
    }

    setIdEdit('');
    setIsEdit(false);
    setTextInput('');
  };

  const onCloseEditMessage = () => {
    setIsEdit(false);
    setTextInput('');
    setIdEdit('');
  };

  const pushUnread = async (groupId) => {
    try {
      const q = query(collection(db, 'unReads'), where('groupUid', '==', groupId));

      const querySnapshot = await getDocs(q);

      querySnapshot.forEach(async (doc) => {
        if (doc.data().user !== currentUserChat.uid) {
          const chatDocRef = doc.ref;
          await updateDoc(chatDocRef, {
            isUnread: true,
          });
        }
      });

      const docRef = doc(db, 'groupChats', groupChat.uid);
      updateDoc(docRef, {
        membersUnread: groupChat.members.filter((item) => item !== currentUserChat.uid),
      }).catch((e) => {});
    } catch (err) {}
  };

  const handleClose = () => {
    setShowCreateChat('');
    setIsShowCreateChat(false);
  };

  const Read = async (groupId) => {
    const result = listUnread.find((unread) => unread.groupUid === groupId);
    if (result) {
      try {
        const q = query(collection(db, 'unReads'), where('uid', '==', result.uid));

        const querySnapshot = await getDocs(q);
        await updateDoc(querySnapshot.docs[0].ref, {
          isUnread: false,
        });
      } catch (err) {}
    }
  };

  const selectGroupChat = async (group) => {
    setUserChat(null);
    setGroupChat(group);
    if (group?.membersUnread && group?.membersUnread?.length) {
      const docRef = doc(db, 'groupChats', group.uid);
      updateDoc(docRef, {
        membersUnread: listDataGroup
          .find((x) => x?.uid === group?.uid)
          ?.membersUnread?.filter((y) => y !== currentUserChat?.uid),
      });
      localStorage.setItem(
        group.uid,
        `${listDataGroup.find((x) => x?.uid === group?.uid)?.messages.length}` ?? '0',
      );
    }

    setTextInput('');
    await Read(group.uid);
  };

  const checkUnread = (groupId) => {
    const result = listUnread.find((unread) => unread.groupUid === groupId);
    return !!result?.isUnread;
  };

  const checkGroupUnread = (items) => {
    return items.some(
      (group) => checkUnread(group.uid) || group?.membersUnread?.includes(currentUserChat.uid),
    );
  };

  const seenMessage = async () => {
    if (groupChat?.membersUnread && groupChat?.membersUnread?.length) {
      const docRef = doc(db, 'groupChats', groupChat.uid);
      updateDoc(docRef, {
        membersUnread: listDataGroup
          .find((x) => x?.uid === groupChat?.uid)
          ?.membersUnread?.filter((y) => y !== currentUserChat?.uid),
      });
      localStorage.setItem(
        groupChat.uid,
        `${listDataGroup.find((x) => x?.uid === groupChat?.uid)?.messages.length}` ?? '0',
      );
    }

    await Read(groupChat.uid);
  };

  return {
    t,
    listUsers,
    handlerChat,
    userChat,
    sendMessage,
    setTextInput,
    textInput,
    listMessage,
    currentUserChat,
    isShowCreateChat,
    setIsShowCreateChat,
    showCreateChat,
    setShowCreateChat,
    handleClose,
    selectGroupChat,
    groupChat,
    listDataGroup,
    listDataGroupSorted,
    listDirectChat,
    isLoading,
    setIsLoading,
    listUnread,
    isShowBtn,
    setIsShowBtn,
    deleteMessage,
    editMessage,
    isEdit,
    setIsEdit,
    onCloseEditMessage,
    sendEditMessage,
    checkUnread,
    checkGroupUnread,
    setListDirectChat,
    listNotifications,
    listGroupNameSorted,
    setListUsers,
    setListDataGroup,
    setListDataGroupSorted,
    pushUnread,
    listUserNew,
    setListUserNew,
    listNotificationsGroups,
    checkGroupWithCom,
    seenMessage,
  };
};
export default useServices;
