import { axiosClient } from './axiosClient';
import { TCareerMemo } from '../types/careerMemoManagement/careerMemoManagement';

export const careerMemoApi = {
  getCareerMemo: (comId: string) => {
    const url = `/career-memo/${comId}`;
    return axiosClient.get(url);
  },
  updateCareerMemo: (data: TCareerMemo) => {
    const url = `/career-memo/${data.comId}`;
    return axiosClient.put(url, data);
  },
};
