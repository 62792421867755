import { axiosClient } from './axiosClient';
import { TClientManagement } from '../types/clientManagement/clientManagement';
import { TOptionsQuery } from '../types/common';
import queryString from 'querystring';

export const interviewerApi = {
  getListInterviewerManagement: (params: TOptionsQuery<TClientManagement>) => {
    const url = '/consultant';
    const query = queryString.stringify(params);
    return axiosClient.get(`${url}?${query}`);
  },
  registerInterviewer: (data: TClientManagement) => {
    const url = '/consultant';
    return axiosClient.post(url, data);
  },
  updateInterviewer: (params: { id: string; data: TClientManagement }) => {
    const url = `/consultant/${params.id}`;
    return axiosClient.put(url, params.data);
  },
  deleteInterviewer: (id: string) => {
    const url = `/consultant/${id}`;
    return axiosClient.delete(url);
  },
  inActiveInterviewer: (id: string) => {
    const url = `/admin/user/update/${id}/inactive`;
    return axiosClient.patch(url);
  },
  activeInterviewer: (id: string) => {
    const url = `/admin/user/update/${id}/active`;
    return axiosClient.patch(url);
  },
  inviteInterviewer: (id: string) => {
    const url = `/consultant/${id}/invitation`;
    return axiosClient.post(url);
  },
};
